import React, { useRef, useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { withConsumer } from "../../appContext";
import { Camera, Close } from "../Icon";

import s from "./ImageUpload.module.scss";
import { useTranslation } from "react-i18next";

const cameraWithColor = (name) => Camera(name);

const ImageUpload = ({
  id,
  labelSwiss,
  labelOther,
  size,
  handleChange,
  answers,
  highlightErrors
}) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState(answers[id].value);
  const [hasError, setHasError] = useState(false);
  let label = labelSwiss;
  const [controlledLabel, setControlledLabel] = useState(label);
  const fileRef = useRef(null);
  useEffect(() => {
    if (
      answers["s3q1"].value &&
      !["ch", "li"].includes(answers["s3q1"].value.toLowerCase())
    ) {
      label = labelOther;
      setControlledLabel(label);
    }
  }, []);
  const deleteImg = () => {
    fileRef.current.value = "";
    setImgSrc(null);
    handleChange(id, fileRef.current.value);
  };

  const handleImgDisplay = (uri) => {
    if (uri) {
      setImgSrc(uri);
      handleChange(id, uri);
      if (
        answers["s3q1"].value &&
        answers["s3q1"].value.toLowerCase() !== "ch"
      ) {
        label = labelOther;
      }
      setControlledLabel(label);
      setHasError(false);
    } else {
      setHasError(true);
      setControlledLabel("Image size too big!");
    }
  };

  const resizeImage = (file, maxWidth, quality) => {
    return new Promise((res, rej) => {
      if (!file) {
        res();
      }
      Resizer.imageFileResizer(
        file,
        maxWidth,
        9999,
        "JPEG",
        quality,
        0,
        (uri) => {
          try {
            res(uri);
          } catch (err) {
            res();
          }
        },
        "base64"
      );
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    let width = 1500;
    // anything above 80 doesn't have effect really but as per requirements kept it high
    let quality = 95;
    let isValid = false;
    const two_MB = 2 * 1024 * 1024;
    while (!isValid) {
      const uri = await resizeImage(file, width, quality);
      if (
        uri &&
        uri.includes("data") &&
        !uri.toLowerCase().includes("file not found")
      ) {
        if (uri.length < two_MB) {
          handleImgDisplay(uri);
          isValid = true;
        } else {
          if (quality > 70) {
            quality -= 5;
          } else {
            if (width > 900) {
              width -= 100;
            } else {
              handleImgDisplay(uri);
              isValid = true;
            }
          }
        }
      } else {
        return;
      }
    }
  };

  return (
    <>
      <div id={id} className={`${s.Container}`}>
        <input
          data-name={`upload-button-${id}`}
          data-recording-sensitive
          type='file'
          accept='.jpg, .jpeg, .png'
          className={
            hasError || (!imgSrc && highlightErrors)
              ? `${s.File} ${s.Errored} smartlook-hide`
              : `${s.File} smartlook-hide`
          }
          id={"input-" + id}
          ref={fileRef}
          onChange={handleFileUpload}
        />

        <label className={`${s.Label} smartlook-hide`} htmlFor={"input-" + id} data-recording-sensitive>
          {!imgSrc ? cameraWithColor(`${s.Camera}`) : null}
          {imgSrc ? (
            <div className={s.ThumbnailContainer}>
              <p className={s.UploadText}>{controlledLabel}</p>
              <img
                src={imgSrc ? imgSrc : null}
                alt='uploaded-img'
                className={`${s.Thumbnail} smartlook-hide`}
                data-recording-sensitive
              />
            </div>
          ) : (
            controlledLabel
          )}
        </label>

        {imgSrc ? <span onClick={deleteImg}>{Close(s.Close)}</span> : null}
      </div>

      {!imgSrc && highlightErrors && (
        <span className={s.ErrorMessage}>{t(`blurErrors.ID`)}</span>
      )}
    </>
  );
};

export default withConsumer(ImageUpload);
