import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withConsumer } from "../../appContext";
import s from "./FooterNavigation.module.scss";
import Button from "../Button";
import { logEvent, OK_SUBMITTED, sendApi } from "../../helpers/api";
import { isDesktop } from "../../helpers/render";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from "@sentry/react";
import { withTranslation } from "react-i18next";
import { getAge, insuranceRequired } from "../../helpers/validations";
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scroll: false,
      nextStep: 0,
      atBottom: false,
      yOffset: 0,
      scrollHeight: 0,
      sending: false,
      loggedUserGotToSubmitPage: false
    };
  }

  onScroll = () => {
    if (window.scrollY > window.innerHeight && !isDesktop(window.innerWidth))
      window.scrollTo({ top: window.innerHeight - 50, behaviour: "smooth" });

    if (window.scrollY > 0) {
      this.setState({ scroll: true });
    } else {
      this.setState({ scroll: false });
    }
    if (
      document.body.scrollHeight - window.pageYOffset - 1 - window.innerHeight <
      0
    ) {
      this.setState({ atBottom: true });
    } else {
      this.setState({ atBottom: false });
    }
  };

  componentDidUpdate() {
    if (document.body.scrollHeight !== this.state.scrollHeight) {
      this.setState({ scrollHeight: document.body.scrollHeight });
      if (
        document.body.scrollHeight -
          window.pageYOffset -
          1 -
          window.innerHeight <
        0
      ) {
        this.setState({ atBottom: true });
    } else {
        this.setState({ atBottom: false });
      }
    }


    if(this.props.currentStep === this.props.totalSteps && !this.state.loggedUserGotToSubmitPage){
      const { answers } = this.props;
      logEvent("User got to submit page", answers?.s4q1?.value, answers?.s2q4?.value)
      this.setState({loggedUserGotToSubmitPage: true})
    }
  }

  componentDidMount() {
    if (
      document.body.scrollHeight - window.pageYOffset - 1 - window.innerHeight <
      0
    ) {
      this.setState({ atBottom: true });
    }
    this.setState({ yOffset: window.pageYOffset });
    this.setState({ scrollHeight: document.body.scrollHeight });
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  hendlePreviousStep = (e) => {
    const { currentStep, updateStep, answers } = this.props;
    const previousStep = Number(currentStep) - 1;
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      // Added a timeout to prevent scroll jumps
      if (previousStep === 7) {
        if (
          ["employed", "apprentice", "Angestellt", "Lernende/r"].includes(
            answers.s6q2?.value
          )
        ) {
          updateStep(previousStep);
        } else {
          updateStep(previousStep - 1);
        }
      } else if (previousStep === 9) {
        if (!insuranceRequired(answers)) {
          updateStep(previousStep - 1);
        } else {
          updateStep(previousStep);
        }
      } else {
        updateStep(previousStep);
      }
    }, 200);
  };

  handleNextStep = (e) => {
    const { currentStep, updateStep, answers, checkErrors } = this.props;
    const nextStep = Number(currentStep) + 1;

    const errors = checkErrors({ ...answers }, 10, 21, true);

    if (Object.values(errors).some((error) => error)) {
      this.props.setHighlightErrors(true);
      return;
    }

    this.props.setHighlightErrors(false);
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }, 500);

    if (nextStep === 7) {
      if (
        ["employed", "apprentice", "Angestellt", "Lernende/r"].includes(
          answers.s6q2?.value
        )
      ) {
        updateStep(nextStep);
      } else {
        updateStep(nextStep + 1);
      }
    } else if (nextStep === 9) {
      if (!insuranceRequired(answers)) {
        updateStep(nextStep + 1);
      } else {
        updateStep(nextStep);
      }
    } else {
      updateStep(nextStep);
    }
  };

  handleEditNext = () => {
    const {
      checkErrors,
      editingSteps,
      setEditingSteps,
      windowWidth,
      answers,
      currentStep
    } = this.props;

    const errors = checkErrors(undefined, 10, 21, true);

    if (Object.values(errors).some((error) => error)) {
      this.props.setHighlightErrors(true);
      return;
    }
    let i =
      this.state.nextStep < editingSteps.length
        ? (this.state.nextStep += 1)
        : 0;
    this.setState({ nextStep: i }, checkErrors);
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (this.state.nextStep === 0) {
      if (insuranceRequired(answers)) {
        if (
          isDesktop(windowWidth) &&
          currentStep < 3 &&
          answers["s9q1"].value === undefined
        ) {
          setEditingSteps("currentStep", 3);
        } else if (
          !isDesktop(windowWidth) &&
          (currentStep === 5 || currentStep === 3) &&
          answers["s9q1"].value === undefined
        ) {
          setEditingSteps("currentStep", 9);
        }
      }
    }
    setEditingSteps("currentStep", editingSteps[this.state.nextStep]);
  };

  updateEditStep = () => {
    this.setState({
      nextStep: this.props.editingSteps[1]
    });
  };

  handleEditSave = () => {
    const {
      setEditingSteps,
      windowWidth,
      checkErrors,
      answers,
      currentStep
    } = this.props;

    const errors = checkErrors(undefined, 10, 21, true);

    if (Object.values(errors).some((error) => error)) {
      this.props.setHighlightErrors(true);
      return;
    }
    if (insuranceRequired(answers)) {
      if (
        isDesktop(windowWidth) &&
        currentStep < 3 &&
        answers["s9q1"].value === undefined
      ) {
        return setEditingSteps("currentStep", 3);
      } else if (
        !isDesktop(windowWidth) &&
        (currentStep === 5 || currentStep === 3) &&
        answers["s9q1"].value === undefined
      ) {
        return setEditingSteps("currentStep", 9);
      }
    }
    this.setState({ nextStep: 0 });
    setEditingSteps("editing", false);
    setEditingSteps("currentStep", !isDesktop(windowWidth) ? 12 : 4);

    if (isDesktop(windowWidth))
      window.scroll({
        behavior: "smooth",
        top: 0
      });
  };

  handleBackEdit = () => {
    const { editingSteps, setEditingSteps, answers, currentStep } = this.props;
    let i = 0;
    if (currentStep === 10 && !insuranceRequired(answers)) {
      i = 1;
    } else {
      if (this.state.nextStep > 0) {
        i = this.state.nextStep - 1;
      } else {
        i = 0;
      }
    }
    // let i =
    //   currentStep === 10 && !insuranceRequired(answers)
    //     ? 1
    //     : this.state.nextStep > 0
    //     ? (this.state.nextStep -= 1)
    //     : 0;
    this.setState({ nextStep: i }, () => {
      setEditingSteps("currentStep", editingSteps[this.state.nextStep]);
    });
  };

  // formatLanguage = () => {
  //   switch (this.props.answers['s4q4'].value) {
  //     case ''
  //   }
  // }

  handleDataSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.sending) return;
    if (
      this.nextStepDisabled(this.props.currentStep, this.props.nextStepDisabled)
    ) {
      return this.props.setHighlightErrors(true);
    }
    const { answers, history, setSubmitError,language } = this.props;
    this.setState({ sending: true });
    logEvent("User submitted to API", answers?.s4q1?.value, answers?.s2q4?.value);

    console.log("🚀 ~ file: FooterNavigation.jsx ~ line 282 ~ Footer ~ localStorage.getItem(OK_SUBMITTED)", localStorage.getItem(OK_SUBMITTED))
    if(localStorage.getItem(OK_SUBMITTED) && localStorage.getItem(OK_SUBMITTED) === answers?.s4q1?.value){
      logEvent("User Attempted to ReSubmit Already OK", answers?.s4q1?.value, answers?.s2q4?.value,null,  true);
    }

    sendApi(answers)
      .then((res) => {
        logEvent("API Response: " + res.statusCode, answers?.s4q1?.value, answers?.s2q4?.value)
        this.setState({ sending: false });
        localStorage.setItem(OK_SUBMITTED, btoa(answers?.s4q1?.value));
        history.push(`/${language?.toLowerCase()}`);
        history.push(`/${language?.toLowerCase()}`);
        history.push(`/${language?.toLowerCase()}`);
        history.push(`/${language?.toLowerCase()}/success`);
      })
      .catch((err) => {
        Sentry.captureException(err, {extra: {message: err.message}});
          this.setState({ sending: false });
          setSubmitError(err.message);
          history.push(`/${language?.toLowerCase()}/error`);
        });
  };

  buttonCondition = (currentStep, editingSteps) => {
    return (
      currentStep >= editingSteps[editingSteps.length - 1] ||
      (currentStep === 6 &&
        !["employed", "apprentice", "Angestellt", "Lernende/r"].includes(
          this.props.answers.s6q2?.value
        ))
    );
  };
  nextStepDisabled = (currentStep, nextStepDisabled) => {
    return nextStepDisabled && currentStep !== 12;
  };

  renderNextButton = (
    currentStep,
    totalSteps,
    nextStepDisabled,
    editing,
    editingSteps
  ) => {
    if (editing) {
      if (this.buttonCondition(currentStep, editingSteps)) {
        return (
          <Button
            transparent={this.nextStepDisabled(currentStep, nextStepDisabled)}
            footer
            onClick={this.handleEditSave}
          >
            {this.props.t("footer.save")}
          </Button>
        );
      } else {
        return (
          <Button
            transparent={this.nextStepDisabled(currentStep, nextStepDisabled)}
            footer
            onClick={this.handleEditNext}
          >
            {this.props.t("footer.next")}
          </Button>
        );
      }
    } else {
      return currentStep === totalSteps ? (
        <Button
          transparent={
            this.nextStepDisabled(currentStep, nextStepDisabled) ||
            this.state.sending
          }
          onClick={this.handleDataSubmit}
          finish
          footer
        >
          {!this.state.sending ? (
            this.props.t("footer.submit")
          ) : (
            <div
              style={{
                color: isDesktop(this.props.windowWidth) ? "initial" : "white"
              }}
            >
              <CircularProgress size={20} color='inherit' />
            </div>
          )}
        </Button>
      ) : (
        <Button
          transparent={this.nextStepDisabled(currentStep, nextStepDisabled)}
          footer
          onClick={this.handleNextStep}
        >
          {this.props.t("footer.next")}
        </Button>
      );
    }
  };

  render() {
    const {
      currentStep,
      questions,
      totalSteps,
      nextStepDisabled,
      editing,
      editingSteps
    } = this.props;
    const scrolled = this.state.scroll ? s.Scroll : "";
    if (
      currentStep === 0 ||
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/success" ||
      this.props.location.pathname === "/error"
    )
      return null;
    return (
      <footer
        id='footer'
        className={`${s.Container} ${scrolled} footer-navigation`}
      >
        <Button
          footer
          onClick={editing ? this.handleBackEdit : this.hendlePreviousStep}
          disabled={currentStep <= 1}
          transparent
        >
          {this.props.t("footer.back")}
        </Button>
        {questions &&
          this.renderNextButton(
            currentStep,
            totalSteps,
            nextStepDisabled,
            editing,
            editingSteps
          )}
      </footer>
    );
  }
}

export default withRouter(withConsumer(withTranslation()(Footer)));
