import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withConsumer } from "../../appContext";
import s from "./Error.module.scss";

import ProminentParagraph from "../../components/ProminentParagraph";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Image from "../../components/Image"; 
import { NETWORK_ERROR, sendApi, OK_SUBMITTED, logEvent } from "../../helpers/api";
import rocket from "../../media/images/rocket.jpg";
import { useTranslation } from "react-i18next";

const ErrorPage = ({ answers, history, submitError, setSubmitError,language }) => {
  const { t } = useTranslation();
  const [attempts, setAttempts] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(submitError === NETWORK_ERROR){
      logEvent("Network Error Page Hit", answers?.s4q1?.value, answers?.s2q4?.value, null,true);
    }
    else if(localStorage.getItem(OK_SUBMITTED) && localStorage.getItem(OK_SUBMITTED) === answers?.s4q1?.value){
      logEvent("Hit Error but Already Submitted", answers?.s4q1?.value, answers?.s2q4?.value, null, true);
    }
  }, [])

  const resubmit = () => {
    setLoading(true);

    logEvent("User attempts to retry on network page", answers?.s4q1?.value, answers?.s2q4?.value);
    console.log("🚀 ~ file: Error.jsx ~ line 26 ~ resubmit ~ localStorage.getItem(OK_SUBMITTED)", localStorage.getItem(OK_SUBMITTED))
    if(localStorage.getItem(OK_SUBMITTED) && localStorage.getItem(OK_SUBMITTED) === answers?.s4q1?.value){
      logEvent("User Attempted to ReSubmit Already OK", answers?.s4q1?.value, answers?.s2q4?.value, null, true);
    }
    sendApi(answers, true)
      .then((res) => {
        console.log("HTTP_SUCCESS", res);
        logEvent("API Response: " + res.statusCode, answers?.s4q1?.value, answers?.s2q4?.value)
        setAttempts(0);
        localStorage.setItem(OK_SUBMITTED, btoa(answers?.s4q1?.value));
        history.push(`/${language?.toLowerCase()}/success`);
      })
      .catch((err) => {
        setSubmitError(err.message);
        setAttempts(attempts + 1);
      }).finally(() => setLoading(false));
  };
  let header = "";
  let message = "";
  if (attempts >= 3) {
    header = t("error.attempts-error.header");
    message = t("error.attempts-error.message");
  } else if (submitError === NETWORK_ERROR) {
    header = t("error.network-error.header");
    message = t("error.network-error.message");
  } else {
    header = t("error.else-error.header");
    message = t("error.else-error.message");
  }
  return (
    <section className={`${s.Container}`}>
      <div className={s.Heading}>
        <Image src={rocket} />
        <Heading>
          <span style={{ fontWeight: "bold", color: "#DA291C" }}>
            {t("error.oops") + " "}
          </span>
          {header}
        </Heading>
      </div>
      <div className={`${s.Main}`}>
        <div className={s.Intro}>
          <ProminentParagraph desktopColor={false}>
            {message}
          </ProminentParagraph>
          <br />
          <br />
          {attempts < 3 && submitError === NETWORK_ERROR ? (
            <Button className={s.MTDesktop} onClick={resubmit} style={loading ? {padding:0} : {}}>
            {loading ? <CircularProgress size={20} color='inherit' /> : 
              t("error.try-again")}
            </Button>
          ) : submitError !== NETWORK_ERROR &&
            (window.location.href.includes("integ") ||
              window.location.href.includes("localhost")) ? (
            <p
              style={{
                color: "white",
                fontFamily: '"Inter", sans-serif',
                fontWeight: "200"
              }}
            >
              {submitError}
            </p>
          ) : null}
          <div className={s.ButtonsDiv}>
            <Button transparent className={s.PLDesktop}>
              <a href={"https://www.manor.ch/"}>{t("error.back-to-manor")}</a>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(withConsumer(ErrorPage));
