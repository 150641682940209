import React from "react";
import s from "./Logo.module.scss";
import logo from '../../media/images/logo.png';

const Logo = ({ onClick }) => (
  <div className={s.Logo} onClick={onClick}>
    <img src={logo} alt='Manor logo' />
  </div>
);

export default Logo;
