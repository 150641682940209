import React from "react";
import s from "./Link.module.scss";

const Link = ({
  href = "#",
  children,
  target = "_blank",
  disabled,
  onClick = null,
  noUnderline
}) => {
  const handleClick = (e) => {
    if (disabled) e.preventDefault();
    onClick();
  };
  return (
    <a
      className={`${s.Link} ${noUnderline ? s.NoUnderline : ""}`}
      href={href}
      onClick={handleClick}
      target={target}
    >
      {children}
    </a>
  );
};

export default Link;
