import countriesDE from "../helpers/countries/countries-de.json";
import countriesFullDE from "../helpers/countries/countries-full-de.json";

const questionsDE = [
  {
    question_id: "0",
    question: "Haben Sie einen Promo-Code?",
    question_step: [1, 1],
    question_form: [
      {
        type: "TextInput",
        id: "promo",
        label: "Promo-Code",
        maxLength: 30,
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "1",
    question: "Wie heissen Sie?",
    question_step: [1, 1],
    question_form: [
      {
        type: "Label",
        value: "Anrede",
        id: "s1q0"
      },
      {
        type: "Radio",
        id: "s1q1",
        label: "sex",
        options: [
          {
            label: "Frau",
            value: "mrs",
            size: "half",
            name: "s1q1",
            id: "s1q11"
          },
          {
            label: "Herr",
            value: "mr",
            size: "half",
            name: "s1q1",
            id: "s1q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "first_name",
        formatting: "name",
        label: "Vorname (entsprechend Ausweis / ID)",
        maxLength: 30,
        value: ""
      },
      {
        type: "TextInput",
        id: "last_name",
        formatting: "name",
        label: "Nachname (entsprechend Ausweis / ID)",
        maxLength: 30,
        value: ""
      }
    ]
  },
  {
    question_id: "2",
    question: "Wo wohnen Sie?",
    question_step: [2, 1],
    question_form: [
      {
        type: "Select",
        label: "Land auswählen...",
        id: "s2q1",
        options: countriesDE
      },
      {
        type: "Row",
        id: "s2r1",
        items: [
          {
            type: "TextInput",
            id: "s2q2",
            label: "Strasse",
            maxLength: 25,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s2q3",
            label: "Nr.",
            maxLength: 4,
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s2r2",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s2q4",
            label: "PLZ",
            formatting: "Number",
            maxLength: 6,
            errorMessage: "Bitte eine gültige PLZ eingeben.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s2q5",
            label: "Ort",
            validation: "city",
            maxLength: 25,
            value: "",
            size: "twoThirds",
            errorMessage: "Bitte geben Sie eine korrekte Ortschaft ein."
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Bitte geben Sie ein gültiges Datum an.",
        id: "s2q6",
        label: "An dieser Adresse wohnhaft seit:",
        labels: "TT/MM/JJJJ",
        required: true
      }
    ]
  },
  {
    question_id: "3",
    question: "Wie leben Sie?",
    question_step: [3, 1],
    question_form: [
      {
        type: "Select",
        label: "Nationalität auswählen...",
        id: "s3q1",
        options: countriesFullDE
      },
      {
        type: "Select",
        label: "Ausländerausweis Typ",
        id: "s3q4",
        options: [
          {
            label: "B - Jahresaufenthalter",
            value: "B - Jahresaufenthalter"
          },
          {
            label: "C - Niederlassungsbewilligung",
            value: "C - Niederlassungsbewilligung"
          },
          {
            label: "G - Grenzgänger",
            value: "G - Grenzgänger"
          },
          {
            label: "L - Kurzaufenthaltsbewilligung",
            value: "L - Kurzaufenthaltsbewilligung"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Ungültiges Datum",
        id: "s3q5",
        label: "CH-Aufenthaltsbewilligung seit",
        labels: "TT/MM/JJJJ"
      },
      {
        type: "Select",
        id: "s3q2",
        label: "Zivilstand auswählen...",
        options: [
          {
            label: "Ledig",
            value: "single"
          },
          {
            label: "Verheiratet",
            value: "married"
          },
          {
            label: "Geschieden",
            value: "divorced"
          },
          {
            label: "Verwitwet",
            value: "widowed"
          },
          {
            label: "Andere",
            value: "other"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Bitte geben Sie ein gültiges Datum an.",
        validation: "Birthdate",
        underAgeMessage: "Sie müssen mindestens 18 Jahre alt sein.",
        id: "s3q3",
        label: "Geburtsdatum",
        labels: "TT/MM/JJJJ",
        required: true
      }
    ]
  },
  {
    question_id: "4",
    question: "Wie können wir Sie erreichen?",
    question_step: [4, 1],
    question_form: [
      {
        type: "TextInput",
        id: "s4q1",
        label: "E-Mail",
        errorMessage:
          "Bitte geben Sie eine gültige E-Mail-Adresse in der Form name@gmail.com ein und verwenden Sie keine Sonderzeichen.",
        validation: "Email",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q2",
        label: "Telefon Mobil",
        errorMessage: "Bitte geben Sie eine gültige Telefonnummer an.",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q3",
        label: "Telefon Privat (optional)",
        errorMessage: "Bitte geben Sie eine gültige Telefonnummer an.",
        value: "",
        required: false,
        optional: true
      },
      {
        type: "Select",
        id: "s4q4",
        label: "Korrespondenzsprache auswählen...",
        options: [
          {
            label: "Deutsch",
            value: "de-CH"
          },
          {
            label: "Italienisch",
            value: "it-CH"
          },
          {
            label: "Französisch",
            value: "fr-CH"
          }
        ]
      }
    ]
  },
  {
    question_id: "5",
    question: "Wie wohnen Sie?",
    question_step: [5, 2],
    question_form: [
      {
        type: "ProminentParagraph",
        value:
          "Um Ihren Antrag umgehend bearbeiten zu können, benötigen wir noch weitere Angaben von Ihnen."
      },
      {
        type: "Radio",
        id: "s5q1",
        options: [
          {
            label: "Miete",
            value: "tenant",
            size: "half",
            name: "s5q1",
            id: "s5q11"
          },
          {
            label: "Eigentum",
            value: "owner",
            size: "half",
            name: "s5q1",
            id: "s5q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s5q2",
        formatting: "Money",
        label: "Monatl. Wohnkosten in CHF",
        value: "",
        maxLength: 6,
        isNumberField: true
      }
    ]
  },
  {
    question_id: "6",
    question: "Wie ist Ihre Einkommenssituation?",
    question_step: [6, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s6q1",
        label: "Bruttoeinkommen pro Jahr in CHF",
        value: "",
        formatting: "Money",
        required: true,
        isNumberField: true
      },
      {
        type: "Paragraph",
        value: "Wie verdienen Sie Ihr Haupteinkommen?"
      },
      {
        type: "Radio",
        id: "s6q2",
        options: [
          {
            label: "Angestellt",
            value: "employed",
            size: "half",
            name: "s6q2",
            id: "s6q21"
          },
          {
            label: "Selbstständig",
            value: "self_employed",
            size: "half",
            name: "s6q2",
            id: "s6q22"
          },
          {
            label: "Rentenbezüger/in",
            value: "retired",
            size: "half",
            name: "s6q2",
            id: "s6q23"
          },
          {
            label: "Student/in",
            value: "student",
            size: "half",
            name: "s6q2",
            id: "s6q24"
          },
          {
            label: "Lernende/r",
            value: "apprentice",
            size: "half",
            name: "s6q2",
            id: "s6q25"
          },
          {
            label: "Nicht erwerbstätig",
            value: "unemployed",
            size: "half",
            name: "s6q2",
            id: "s6q26"
          },
          {
            label: "Andere",
            value: "other",
            size: "half",
            name: "s6q2",
            id: "s6q27"
          }
        ]
      },
      {
        type: "Paragraph",
        value: "Bankverbindung"
      },
      {
        type: "Checkbox",
        id: "s6q3",
        label: "Ich habe ein Bankkonto in der Schweiz oder in Liechtenstein",
        size: "full",
        name: "s6q3",
        required: true,
        optional: false,
        value: false
      },
      {
        type: "TextInput",
        id: "s6q4",
        label: "IBAN (optional)",
        errorMessage: "Ungültige IBAN für die Schweiz",
        validation: "IBAN",
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "7",
    question: "Was arbeiten Sie?",
    question_step: [7, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s7q1",
        label: "Beruf",
        value: "",
        maxLength: 30
      },
      {
        type: "TextInput",
        id: "s7q2",
        label: "Arbeitgeber",
        value: "",
        maxLength: 30
      },
      {
        type: "Row",
        id: "s7r1",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s7q3",
            label: "PLZ",
            formatting: "Number",
            errorMessage: "Bitte eine gültige PLZ eingeben.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s7q4",
            label: "Ort",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Bitte geben Sie eine korrekte Ortschaft ein."
          }
        ]
      }
    ]
  },
  {
    question_id: "8",
    question: "Wer ist der wirtschaftlich Berechtigte?",
    question_step: [8, 3],
    question_form: [
      {
        type: "Paragraph",
        value:
          "Ich als Antragsteller für die Kreditkarte bzw. Karteninhaber erkläre, dass die Gelder, die zur Begleichung der Kreditkartenrechnung dienen und/oder über diesen Betrag hinaus bei der Kreditkartenherausgeberin eingebracht werden,"
      },
      {
        type: "Radio",
        id: "s8q1",
        label: "Wirtschaftlich Berechtigte/r",
        options: [
          {
            label: "... ausschliesslich mir gehören.",
            value: "ausschliesslich mir gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q11",
            code: "s8q11"
          },
          {
            label: "... der/den nachfolgend aufgeführten  Person/en gehören.",
            value: "der/den nachfolgend aufgeführten  Person/en gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q12",
            code: "s8q12"
          }
        ]
      },
      // Form to show if the second radio has been chosen
      {
        type: "TextInput",
        id: "s8q2",
        label: "Vorname",
        maxLength: 30,
        formatting: "name",
        value: ""
        // required: false
      },
      {
        type: "TextInput",
        id: "s8q3",
        label: "Nachname",
        formatting: "name",
        maxLength: 30,
        value: ""
        // required: false
      },
      {
        type: "Select",
        label: "Land auswählen...",
        id: "s8q4",
        // required: false,
        options: countriesDE
      },
      {
        type: "Row",
        id: "s8r1",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q5",
            label: "Strasse",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q6",
            label: "Nr.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q7",
            label: "PLZ",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Bitte eine gültige PLZ eingeben."
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q8",
            label: "Ort",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Bitte geben Sie eine korrekte Ortschaft ein."
          }
        ]
      },
      {
        type: "Select",
        label: "Nationalität auswählen...",
        id: "s8q9",
        // required: false,
        options: countriesFullDE
      },
      {
        type: "Calendar",
        id: "s8q10",
        // required: false,
        label: "Geburtsdatum",
        labels: "TT/MM/JJJJ",
        validation: "Birthdate",
        underAgeMessage: "Sie müssen mindestens 18 Jahre alt sein."
      },
      {
        type: "Checkbox",
        id: "s8q1b",
        label: "Eine weitere Person hinzufügen",
        size: "full",
        name: "s8q1b",
        value: false,
        required: false
      },
      // Form to show if the second radio has been chosen
      {
        type: "TextInput",
        id: "s8q2b",
        label: "Vorname",
        maxLength: 30,
        formatting: "name",
        value: ""
        // required: false
      },
      {
        type: "TextInput",
        id: "s8q3b",
        label: "Nachname",
        formatting: "name",
        maxLength: 30,
        value: ""
        // required: false
      },
      {
        type: "Select",
        label: "Land auswählen...",
        id: "s8q4b",
        // required: false,
        options: countriesDE
      },
      {
        type: "Row",
        id: "s8r1b",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q5b",
            label: "Strasse",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q6b",
            label: "Nr.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2b",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q7b",
            label: "PLZ",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Bitte eine gültige PLZ eingeben."
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q8b",
            label: "Ort",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Bitte geben Sie eine korrekte Ortschaft ein."
          }
        ]
      },
      {
        type: "Select",
        label: "Nationalität auswählen...",
        id: "s8q9b",
        // required: false,
        options: countriesFullDE
      },
      {
        type: "Calendar",
        id: "s8q10b",
        // required: false,
        label: "Geburtsdatum",
        labels: "TT/MM/JJJJ",
        validation: "Birthdate",
        underAgeMessage: "Sie müssen mindestens 18 Jahre alt sein."
      },
      // {
      //   type: "Checkbox",
      //   id: "s8q1c",
      //   label: "Ene weitere Person hinzufügen",
      //   size: "full",
      //   name: "s8q1c",
      //   value: false,
      //   required: false
      // },
      // Form to show if the second radio has been chosen
      {
        type: "TextInput",
        id: "s8q2c",
        label: "Vorname",
        maxLength: 30,
        formatting: "name",
        value: ""
        // required: false
      },
      {
        type: "TextInput",
        id: "s8q3c",
        label: "Nachname",
        formatting: "name",
        maxLength: 30,
        value: ""
        // required: false
      },
      {
        type: "Select",
        label: "Land auswählen...",
        id: "s8q4c",
        // required: false,
        options: countriesDE
      },
      {
        type: "Row",
        id: "s8r1c",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q5c",
            label: "Strasse",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q6c",
            label: "Nr.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2c",
        items: [
          {
            type: "TextInput",
            // required: false,
            id: "s8q7c",
            label: "PLZ",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Bitte eine gültige PLZ eingeben."
          },
          {
            type: "TextInput",
            // required: false,
            id: "s8q8c",
            label: "Ort",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Bitte geben Sie eine korrekte Ortschaft ein."
          }
        ]
      },
      {
        type: "Select",
        label: "Nationalität auswählen...",
        id: "s8q9c",
        // required: false,
        options: countriesFullDE
      },
      {
        type: "Calendar",
        id: "s8q10c",
        // required: false,
        label: "Geburtsdatum",
        labels: "TT/MM/JJJJ",
        validation: "Birthdate",
        underAgeMessage: "Sie müssen mindestens 18 Jahre alt sein."
      }
    ]
  },
  {
    question_id: "9",
    question: "Zahlungsverpflichtungen absichern?",
    question_step: [9, 3],
    question_form: [
      {
        type: "InfoParagraphWithList",
        value:
          "Für nur 0.5 % Ihres Rechnungsbetrags sind Sie abgesichert. Wir übernehmen das offene Saldo",
        list: [
          "bei unverschuldeter Arbeitslosigkeit",
          "bei Erwerbsunfähigkeit infolge Unfall oder Krankheit",
          "im Todesfall infolge Unfall oder Krankheit",
          "Maximal CHF 10’000 pro Schadenfall"
        ],
        info: "1",
        title1: "BALANCE PROTECT",
        title2: "Weil es jeden treffen kann",
        title3: "Vollständige Saldoübernahme*:",
        title4: "FAQ Balance PROTECT",
        listItem1:
          "Ihre Finanzen stets im Griff - dank der Saldoversicherung Balance PROTECT:",
        listItem2:
          "Die Gründe für eine finanzielle Notsituation sind vielfältig – oft tritt sie ganz unerwartet ein.",
        listItem3: "bei unverschuldeter Arbeitslosigkeit",
        listItem4: "bei Erwerbsunfähigkeit infolge Unfall oder Krankheit",
        listItem5: "im Todesfall infolge Unfall oder Krankheit",
        listItem6: "Bei Fragen stehen wir Ihnen gerne zur Verfügung",
        listItem7: "Telefon: +41 44 805 58 58",
        listItem8: "E-Mail: manor@viseca.ch",
        listItem9: "Was ist versichert?",
        listItem10:
          "Versichert ist der offene Gesamtsaldo des Kartenkontos. Im Schadenfall erfolgt eine Einmalzahlung auf Ihr Kartenkonto (Balance Cancellation).",
        listItem11: "Wer ist versichert?",
        listItem12: "Versichert ist der Hauptkarteninhaber.",
        listItem13: "Was sind die Voraussetzungen des Versicherungsschutzes?​",
        listItem14:
          "Die Erfüllung der Beitrittsbedingungen und der allgemeinen Versicherungsbedingungen.",
        listItem15: "Wie hoch sind die Versicherungssummen?",
        listItem16:
          "Maximal versichert sind CHF 10'000.- pro Fall und gesamthaft CHF 30'000 pro Kartenvertrag.​",
        listItem17: "Wie muss ich im Schadenfall vorgehen?",
        listItem18:
          "Melden Sie Ihren Schadenfall ohne Verzug dem von Helvetia beauftragten Service Provider:​",
        para1:
          "Unverschuldete Arbeitslosigkeit in wirtschaftlich schwierigen Zeiten oder längere Erwerbsunfähigkeit infolge Unfall oder Krankheit – das kann jeden treffen. Balance PROTECT hilft Ihnen, indem sie den ganzen offenen Saldo übernimmt. Und dies zu einer Prämie von nur 0.50% Ihres offenen Saldos.​",
        para2:
          "Ein Beispiel: Beträgt Ihre Manor Mastercard Rechnung 150 CHF, bezahlen Sie eine Prämie von lediglich 75 Rappen.​",
        para3: "Financial & Employee Benefits Services (febs) AG​",
        para4: "Postfach 1763, 8401 Winterthur​",
        para5: "Telefon: +41 (0)52 266 02 92, Fax: +41 (0)52 266 02 01​",
        para6: "E-Mail: viseca@febs.ch",
        para7:
          "* Der Deckungsumfang richtet sich nach den Allgemeinen Versicherungsbedingungen (AVB) der Saldoversicherung Balance PROTECT ​"
      },
      {
        type: "Table",
        value: "Beispiel einer Prämienberechnung",
        list: [
          ["Saldovortrag (letzte Rechnung)", "CHF 50.-"],
          ["Ihr neuer Einkauf", "CHF 100.-"],
          ["Offener Saldo", "CHF 150.-"],
          ["Prämie 0.5% von CHF 150.-", "= 75 Rappen"]
        ],
        info: "2"
      },
      {
        type: "Special"
      },
      {
        type: "Radio",
        id: "s9q1",
        label: "Absicherung Zahlungsverpflichtung",
        options: [
          {
            label: "Ja, ich möchte meine Zahlungsverpflichtung absichern",
            value: "ja",
            size: "full",
            name: "s9q1",
            id: "s9q11"
          },
          {
            label: "Nein, ich möchte mich nicht absichern",
            value: "nein",
            size: "full",
            name: "s9q1",
            id: "s9q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "10",
    question: "Verlust oder Defekt der Karte versichern?",
    question_step: [10, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Schützen Sie sich bei Verlust oder Defekt vor Ersatzkosten mit Card PROTECT von der Europäischen Reiseversicherung ERV.",
        info: "3",
        title1: "CARD PROTECT",
        title2: "FAQ Card PROTECT",
        listItem1: "Keine Kosten für Kartenersatz",
        listItem2: "Keine Kosten für PIN Ersatz",
        listItem3: "Preis: Nur 6.90 CHF pro Jahr",
        listItem4: "Was ist die Card PROTECT Versicherung?",
        listItem5:
          "Die Card PROTECT Versicherung deckt die Kosten für Kartenersatz im Fall von Diebstahl, Verlust oder Defekt Ihrer Manor World Mastercard. Ebenfalls gedeckt sind Kosten für den Ersatz-PIN.",
        listItem6: "Für wen gilt der Versicherungsschutz?",
        listItem7:
          "Versichert sind Haupt- sowie Zusatzkarten der Manor World Mastercard.",
        listItem8: "Wie hoch ist die Prämie?",
        listItem9:
          "Die Prämie beträgt 6.90 CHF und wird jährlich auf Ihre Kreditkarte belastet",
        listItem10: "Welche Leistungen sind versichert?",
        listItem11:
          "Versichert sind sämtliche Kosten für den Ersatz von verlorenen, gestohlenen oder defekten Manor World Mastercard Karten und/oder PIN’s.",
        listItem12: "Wie muss ich im Schadenfall vorgehen?",
        listItem13:
          "Bei Verlust oder Diebstahl melden Sie sich bitte umgehend bei der 24h Sperrzentrale von Viseca unter der Telefonnummer +41 (0)58 958 83 83.",
        listItem14:
          "Bei PIN Verlust oder einer defekten Karte melden Sie sich beim Kundendienst unter +41 (0) 44 805 58 58.",
        listItem15: "Wann endet die Versicherung?",
        listItem16:
          "Die Versicherung wird auf unbestimmte Zeit abgeschlossen und verlängert sich stillschweigend um ein weiteres Jahr wenn die Versicherung nicht spätestens einen Monat vor Ablauf des Versicherungsjahres schriftlich bei Viseca gekündigt wird.",
        para1: "Zuverlässiger Schutz bei Karten- und PIN Verlust",
        para2:
          "Schützen Sie sich bei Verlust oder Defekt vor den Ersatzkosten mit der Card PROTECT Versicherung von Viseca.",
        para3: "Ihre Vorteile:"
      },
      {
        type: "ParagraphWithList",
        value: "Ihre Vorteile:",
        list: [
          "keine zusätzlichen Kosten für Kartenersatz",
          "keine zusätzlichen Kosten für PIN-Ersatz",
          "versichert sind Haupt- sowie Zusatzkarten"
        ]
      },
      {
        type: "Paragraph",
        value: "Preis: Nur CHF 6.90 pro Jahr"
      },
      {
        type: "Radio",
        id: "s10q1",
        label: "Schutz von Karten- oder Pin-Verlust",
        options: [
          {
            label:
              "Ja, ich schütze mich gegen anfallende  Gebühren durch Karten- oder PIN-Verlust.",
            value: "ja",
            size: "full",
            name: "s10q1",
            id: "s10q11"
          },
          {
            label: "Nein, ich möchte mich nicht schützen",
            value: "nein",
            size: "full",
            name: "s10q1",
            id: "s10q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "11",
    question: "Ihre Ausweiskopie",
    question_step: [11, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Damit wir Ihren Vertrag erstellen können, benötigen wir eine Ausweiskopie. Bitte laden Sie ein Foto Ihres Ausweises hier hoch.",
        info: "4"
      },
      {
        type: "ImageUpload",
        id: "s11q1",
        name: "s11q1",
        labelSwiss: "Ausweis-Vorderseite",
        labelOther: "Ausländerausweis-Vorderseite",
        size: "full"
      },
      {
        type: "ImageUpload",
        id: "s11q2",
        name: "s11q2",
        labelSwiss: "Ausweis-Rückseite",
        labelOther: "Ausländerausweis-Rückseite",
        size: "full"
      }
    ]
  },
  {
    question_id: "12",
    question: "Zusammenfassung",
    question_step: [12, 4],
    question_form: [
      {
        type: "ProminentParagraph",
        value: "Bitte prüfen Sie Ihre Angaben sorgfältig."
      },
      {
        type: "Summary",
        value: "Ihre persönlichen Angaben",
        steps: [1, 3],
        desktop_steps: [1],
        heading: 1,
        list: [
          ["Anrede:", "s1q1"],
          ["Vorname:", "first_name"],
          ["Name:", "last_name"],
          ["Geburtsdatum:", "s3q3"],
          ["Zivilstand:", "s3q2"],
          ["Nationalität:", "s3q1"],
          ["Aufenthaltsbewilligung:", "s3q4"],
          ["CH - Bewilligung seit:", "s3q5"]
        ]
      },
      {
        type: "Summary",
        value: "Ihre Wohnsituation",
        steps: [2, 5],
        desktop_steps: [1, 2],
        heading: 2,
        list: [
          ["Land:", "s2q1"],
          ["Strasse:", "s2q2"],
          ["Nr:", "s2q3"],
          ["PLZ:", "s2q4"],
          ["Ort:", "s2q5"],
          ["Wohnart:", "s5q1"],
          ["Wohnkosten:", "s5q2"]
        ]
      },
      {
        type: "Summary",
        value: "Ihre Kontaktangaben",
        steps: [4],
        desktop_steps: [1],
        heading: 4,
        list: [
          ["E-Mailadresse:", "s4q1"],
          ["Telefon Mobil:", "s4q2"],
          ["Telefon Privat:", "s4q3"],
          ["Korrespondenzsprache:", "s4q4"]
        ]
      },
      {
        type: "Summary",
        value: "Ihre Einkommenssituation",
        steps: [6, 7],
        desktop_steps: [2],
        heading: 6,
        list: [
          ["Bruttoeinkommen/Jahr:", "s6q1"],
          ["Haupteinkommen:", "s6q2"],
          ["Beruf:", "s7q1"],
          ["Arbeitgeber:", "s7q2"],
          ["PLZ:", "s7q3"],
          ["Ort:", "s7q4"],
          ["CH-/LI-Bankkonto:", "s6q3"],
          ["IBAN:", "s6q4"]
        ]
      },
      {
        type: "Summary",
        value: "Wirtschaftliche Berechtigung",
        steps: [8],
        desktop_steps: [3],
        heading: 8,
        list: [
          ["Wirtschaftlich Berechtigte/r:", "s8q1"],
          ["Person 1", "person-title-1", "RowTitle"],
          ["Vorname:", "s8q2"],
          ["Nachname:", "s8q3"],
          ["Geburtsdatum:", "s8q10"],
          ["Nationalität:", "s8q9"],
          ["Land:", "s8q4"],
          ["Strasse:", "s8q5"],
          ["Nr:", "s8q6"],
          ["PLZ:", "s8q7"],
          ["Ort:", "s8q8"],
          ["Person 2", "person-title-2", "RowTitle"],
          ["Vorname:", "s8q2b"],
          ["Nachname:", "s8q3b"],
          ["Geburtsdatum:", "s8q10b"],
          ["Nationalität:", "s8q9b"],
          ["Land:", "s8q4b"],
          ["Strasse:", "s8q5b"],
          ["Nr:", "s8q6b"],
          ["PLZ:", "s8q7b"],
          ["Ort:", "s8q8b"],
          ["Vorname:", "s8q2c"],
          ["Nachname:", "s8q3c"],
          ["Geburtsdatum:", "s8q10c"],
          ["Nationalität:", "s8q9c"],
          ["Land:", "s8q4c"],
          ["Strasse:", "s8q5c"],
          ["Nr:", "s8q6c"],
          ["PLZ:", "s8q7c"],
          ["Ort:", "s8q8c"]
        ]
      },
      {
        type: "Summary",
        value: "Zusatzleistungen",
        steps: [9, 10],
        desktop_steps: [3],
        heading: 9,
        list: [
          ["Absicherung Zahlungsverpflichtung", "s9q1"],
          ["Schutz von Karten- oder Pin-Verlust", "s10q1"]
        ]
      },
      {
        type: "Summary",
        value: "Ausweiskopie",
        steps: [11],
        desktop_steps: [3],
        heading: 11,
        list: [
          ["Vorderseite", "s11q1"],
          ["Rückseite", "s11q2"]
        ]
      }
    ]
  },
  {
    question_id: "14",
    question: "Einverständniserklärung",
    question_step: [14, 4],
    question_form: [
      {
        type: "CollapsedParagraph",
        value:
          "Hiermit bestätigt der/die Antragsteller/in  die Richtigkeit der vorstehenden Angaben und ermächtigt die Viseca Card Services SA («Viseca» oder «wir») im Rahmen der Kartenantragsprüfung und für die Ausstellung der Karte die vorstehend gemachten Angaben zu prüfen, zu bearbeiten und die dafür erforderlichen Auskünfte bei Dritten einzuholen, z. B. bei der Zentralstelle für Kreditinformationen («ZEK»), bei Behörden (z. B. Betreibungs- und Steuerämtern, Einwohnerkontrollen), Wirtschaftsauskunfteien, Arbeitgebern und weiteren vom Gesetz vorgesehenen (z. B. Informationsstelle für Konsumkredit, IKO) oder geeigneten Informations- und Auskunftsstellen und Tatbestände wie z. B. Kartensperrung, Zahlungsrückstand oder missbräuchliche Kartenverwendung der ZEK zu melden sowie in den vom Gesetz vorgesehenen Fällen den zuständigen Stellen Meldung zu erstatten. Im Rahmen dieser Ermächtigungen entbindet der/die Antragsteller/in uns von der Wahrung des Bank-, Geschäfts- bzw. Amtsgeheimnisses und der Wahrung des Datenschutzes. Wir sind berechtigt, diesen Kartenantrag oder einzelne Anträge ohne Angabe von Gründen abzulehnen.",
        more: "mehr"
      },
      {
        type: "CollapsedParagraph",
        value:
          "Der/Die Antragsteller/in erklärt sich einverstanden, dass wir ihn per E-Mail oder Telefon (bzw. SMS / App) kontaktieren können. Dabei weisen wir darauf hin, dass insbesondere die Kommunikation per E-Mail aufgrund der offenen Konfiguration des Internets weder sicher noch vertraulich ist. Es besteht daher die Möglichkeit, trotz allen Sicherheitsmassnahmen der Viseca, dass sich Dritte unbefugten Zugang in die Kommunikation zwischen dem/der Antragsteller/in und uns verschaffen können.",
        more: "mehr"
      },
      {
        type: "Checkbox",
        required: true,
        id: "s14q1",
        label: "Ich stimme der Erklärung zu",
        size: "full",
        name: "s14q1",
        value: false
      },
      {
        type: "Paragraph",
        value:
          "Viseca Card Services SA, Hagenholzstrasse 56, 8050 Zürich, Telefon +41 44 805 58 58"
      }
    ]
  }
];

export default questionsDE;
