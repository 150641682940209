import React from "react";
import s from "./Icon.module.scss";

const noActionStyle={touchAction: 'none', msTouchAction: 'none', pointerEvents: 'none'};
export const Pencil = (propClass) => (
  <svg
    style={noActionStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={`${s.Pencil} ${propClass ? propClass : null}`}
  >
    <path d='M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828q0.281-0.281 0.703-0.281t0.703 0.281l2.344 2.344q0.281 0.281 0.281 0.703t-0.281 0.703zM3 17.25l11.063-11.063 3.75 3.75-11.063 11.063h-3.75v-3.75z'></path>
  </svg>
);
export const Camera = (propClass) => (
  <svg
    style={noActionStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={`${s.Camera} ${propClass ? propClass : null}`}
  >
    <path d='M12 17.016q2.063 0 3.539-1.477t1.477-3.539-1.477-3.539-3.539-1.477-3.539 1.477-1.477 3.539 1.477 3.539 3.539 1.477zM9 2.016h6l1.828 1.969h3.188q0.797 0 1.383 0.609t0.586 1.406v12q0 0.797-0.586 1.406t-1.383 0.609h-16.031q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.406t1.383-0.609h3.188zM8.813 12q0-1.313 0.938-2.25t2.25-0.938 2.25 0.938 0.938 2.25-0.938 2.25-2.25 0.938-2.25-0.938-0.938-2.25z'></path>
  </svg>
);
export const Help = (propClass) => (
  <svg
    style={noActionStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={`${s.Help} ${propClass ? propClass : null}`}
  >
    <path d='M15.047 11.25q0.938-0.938 0.938-2.25 0-1.641-1.172-2.813t-2.813-1.172-2.813 1.172-1.172 2.813h1.969q0-0.797 0.609-1.406t1.406-0.609 1.406 0.609 0.609 1.406-0.609 1.406l-1.219 1.266q-1.172 1.266-1.172 2.813v0.516h1.969q0-1.547 1.172-2.813zM12.984 18.984v-1.969h-1.969v1.969h1.969zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'></path>
  </svg>
);
export const Info = (propClass) => (
  <svg
    style={noActionStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={`${s.Info} ${propClass ? propClass : null}`}
  >
    <path d='M12.984 9v-2.016h-1.969v2.016h1.969zM12.984 17.016v-6h-1.969v6h1.969zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'></path>
  </svg>
);
export const Arrow = (propClass) => (
  <svg
    style={noActionStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={`${s.Arrow} ${propClass ? propClass : null}`}
  >
    <path d='M7.406 8.578l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path>
  </svg>
);

export const TheArrow = (propClass) => (
  <svg
    style={noActionStyle}
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${propClass ? propClass : null}`}
  >
    <path d="M1.645 0.0214844L7 5.36482L12.355 0.0214844L14 1.66648L7 8.66648L0 1.66648L1.645 0.0214844Z" fill="#DA291C"/>
  </svg>
);

export const TheArrowInverse = (propClass) => (
  <svg
    style={noActionStyle}
    width="14" 
    height="9" 
    viewBox="0 0 14 9" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={`${propClass ? propClass : null}`}
  >
    <path d="M12.355 8.97851L7 3.63518L1.645 8.97851L1.95078e-08 7.33351L7 0.333514L14 7.33352L12.355 8.97851Z" fill="#DA291C"/>
  </svg>
)

export const Close = (propClass) => (
  <svg
    style={noActionStyle}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={propClass ? `${propClass} feather feather-x` : 'feather feather-x'}>
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
);
