import React, { useState, useRef, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getSize, isDesktop } from "../../helpers/render";
import s from "./Cellphone.module.scss";
import { TheArrow, TheArrowInverse } from "../Icon";
import { withConsumer } from "../../appContext";
import { useTranslation } from "react-i18next";
import Label from "../Label";
import { prefixOptions } from "../../helpers/constants";

const Cellphone = ({
  label,
  id,
  size = "full",
  value = "0041",
  handleChange,
  headerInput = false,
  hideTargetSelectField,
  hideTargetSelectFieldTwo,
  hideTargetSelectFieldThree,
  optional,
  errorMessage,
  highlightErrors
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const selectRef = useRef(null);
  const [prefixIndex, setPrefixIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const prefix = value.split("-")[0];
    const index = prefixOptions.findIndex((o) => o.value === prefix);
    setPrefixIndex(index >= 0 ? index : 0);
  }, [value]);

  const onChange = (newValue) => {
    handleChange(id, newValue);
  };

  const onChangeInput = (e) => {
    const number = e.target.value;
    if (isNaN(number)) return;
    const prefix = value && value.includes("-") ? value.split("-")[0] : "";

    onChange(`${prefix}-${number}`);
  };

  const onChangeSelect = (e) => {
    const prefix = e.target.value;
    const number = value && value.includes("-") ? value.split("-")[1] : "";

    onChange(`${prefix}-${number}`);
  };

  const showArrowIcon = (name) => {
    if (!selectOpened) {
      return TheArrow(name);
    }
    return TheArrowInverse(name);
  };

  if (
    hideTargetSelectField &&
    (id === "s3q4" || id === "s8q4" || id === "s8q9")
  ) {
    return null;
  }
  if (hideTargetSelectFieldTwo && (id === "s8q4b" || id === "s8q9b")) {
    return null;
  }
  if (
    
    (hideTargetSelectFieldTwo || hideTargetSelectFieldThree) &&
   
    (id === "s8q4c" || id === "s8q9c")
  
  ) {
    return null;
  }

  function findPos(obj) {
    var curtop = 0;
    if (obj?.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
  }

  const scrollElementIntoView = () => {
    if (!isDesktop(window.innerWidth)) {
      setTimeout(() => {
        window.scroll({
          behavior: "smooth",
          top: findPos(selectRef.current) - 140
        });
      }, 200);
    }
  };

  const checkValue = () => {
    if (!value || !value.includes("-")) return false;
    const values = value.split("-");
    if (!values[0] || !values[1] || values[1].length < getMinLength())
      return false;
    return true;
  };

  const getMaxLength = () => {
    let maxLength = prefixOptions[prefixIndex].max;
    if (value && value.split("-")[1].startsWith("0")) maxLength++;
    return maxLength;
  };
  const getMinLength = () => {
    let minLength = prefixOptions[prefixIndex].min;
    if (value && value.split("-")[1].startsWith("0")) minLength++;
    return minLength;
  };

  return (
    <>
      <Label>{label}</Label>
      <div className={s.Container} id={id}>
        <FormControl
          className={`${s.SelectContainer} ${headerInput ? s.HeaderInput : ""}`}
          style={getSize(size)}
        >
          <InputLabel className={s.Label} margin='dense' id={`${id}-label`}>
            {t("home.prefix")}
          </InputLabel>
          <Select
            data-name={`cellphone-input-select-${id}`}
            data-recording-sensitive
            labelId={`${id}-label`}
            id={`${id}-select`}
            value={value ? value.split("-")[0] : "0041"}
            onChange={onChangeSelect}
            className={s.LanguageWrapper}
            MenuProps={{
              className: s.Popover,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              }
            }}
            ref={selectRef}
            disableUnderline={true}
            onOpen={() => setSelectOpened(true)}
            onClose={() => setSelectOpened(false)}
            onFocus={scrollElementIntoView}
            IconComponent={() =>
              showArrowIcon(
                `${s.DownArrow} ${id === "language" && s.LanguageArrow}`
              )
            }
          >
            {prefixOptions.map((option, i) => (
              <MenuItem
                data-recording-sensitive
                value={option.value}
                key={i}
                className={s.MenuItem}
                divider={false}
                disableGutters={i < prefixOptions.length - 1}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={s.InputContainer}>
          <input
            data-name={`cellphone-input-${id}`}
            data-recording-sensitive
            className={`${s.Input} ${
              !checkValue() && !optional && highlightErrors ? s.Errored : ""
            }`}
            id={`${id}-input`}
            onClick={scrollElementIntoView}
            onFocus={scrollElementIntoView}
            type={"tel"}
            inputMode={"numeric"}
            onChange={onChangeInput}
            value={value ? value.split("-")[1] : ""}
            placeholder={t("home.number")}
            maxLength={getMaxLength()}
          />
        </div>
      </div>
      {!checkValue() && !optional && highlightErrors ? (
        <span className={s.ErrorMessage}>{errorMessage}</span>
      ) : null}
    </>
  );
};
export default withConsumer(Cellphone);
