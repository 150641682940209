import React, { useState, useRef, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getSize, isDesktop } from "../../helpers/render";
import s from "./SelectInput.module.scss";
import { TheArrow, TheArrowInverse } from "../Icon";
import { withConsumer } from "../../appContext";
import { useTranslation } from "react-i18next";
// const useStyles = makeStyles((theme) => ({
//   margin: {
//     marginTop: theme.spacing(1),
//   },
// }));

const SelectInput = ({
  label,
  id,
  options,
  size = "full",
  value = "",
  handleChange,
  headerInput = false,
  hideTargetSelectField,
  hideTargetSelectFieldTwo,
  hideTargetSelectFieldThree,
  questions,
  language,
  highlightErrors
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const selectRef = useRef(null);
  const { t } = useTranslation();
  
  const fixSideBar = (opened) => {
    if (!document.getElementById("decorative")) return;
    const currentRight = document.getElementById("decorative").style.right
      ? parseFloat(
          document.getElementById("decorative").style.right.split("p")[0]
        )
      : 0;
    if (opened)
      document.getElementById("decorative").style.right =
        currentRight + 17 + "px";
    else
      document.getElementById("decorative").style.right =
        currentRight - 17 + "px";
  };

  const onChange = (e) => {
    if (["s2q1", "s8q4", "s8q4b"].includes(id)) {
      const stepQuestion = questions.find((question) =>
        question.question_form.find((field) => field.id === id)
      );
      const countrySelectFieldOptions = stepQuestion.question_form.find(
        (field) => field.id === id
      )?.options;
      const { alpha2, nationality_iso } = countrySelectFieldOptions.find(
        (option) => option.value === e.target.value
      );

      return handleChange(
        id,
        e.target.value,
        null,
        alpha2?.toUpperCase() || nationality_iso?.toUpperCase()
      );
    }
    handleChange(id, e.target.value);
  };

  const showArrowIcon = (name) => {
    if (!selectOpened) {
      return TheArrow(name);
    }
    return TheArrowInverse(name);
  };

  if (
    hideTargetSelectField &&
    (id === "s3q4" || id === "s8q4" || id === "s8q9")
  ) {
    return null;
  }
  if (hideTargetSelectFieldTwo && (id === "s8q4b" || id === "s8q9b")) {
    return null;
  }
  if (
    (hideTargetSelectFieldTwo || hideTargetSelectFieldThree) &&
    (id === "s8q4c" || id === "s8q9c")
  ) {
    return null;
  }

  function findPos(obj) {
    var curtop = 0;
    if (obj?.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [curtop];
    }
  }

  const scrollElementIntoView = () => {
    if (!isDesktop(window.innerWidth)) {
      setTimeout(() => {
        window.scroll({
          behavior: "smooth",
          top: findPos(selectRef.current) - 140
        });
      }, 200);
    }
  };

  const renderLanguageValue = (id, value) => {
    if (!value && language === "DE") {
      let lang = language.toLowerCase() + "-" + "CH";
      handleChange(id, lang);
      return lang;
    }
    return value;
  };

  const getValue = (option) => {
    if (["s3q1", "s8q9", "s8q9b"].includes(id)) return option.alpha2;
    return option.value;
  };

  return (
    <>
      <FormControl
        className={`${s.Container} ${headerInput ? s.HeaderInput : ""} ${
          !value && highlightErrors ? s.Errored : ""
        } ${id === "language" ? s.upABit : ""}`}
        style={getSize(size)}
      >
        <InputLabel className={s.Label} margin='dense' id={`${id}-label`}>
          {label}
        </InputLabel>
        <Select
          data-name={`select-input-${label}-${id}`}
          data-recording-sensitive
          labelId={`${id}-label`}
          id={id}
          value={id === "s4q4" ? renderLanguageValue(id, value) : value}
          onChange={onChange}
          className={s.LanguageWrapper}
          MenuProps={{
            className: s.Popover,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            }
          }}
          ref={selectRef}
          disableUnderline={true}
          onOpen={() => {
            setSelectOpened(true);
            fixSideBar(true);
          }}
          onClose={() => {
            setSelectOpened(false);
            fixSideBar(false);
          }}
          onFocus={scrollElementIntoView}
          IconComponent={() =>
            showArrowIcon(
              `${s.DownArrow} ${id === "language" && s.LanguageArrow}`
            )
          }
        >
          {/* <div>
          <MenuItem value='' disabled>
            {label}{" "}
            <div className={s.ArrowUp}>
              <TheArrowInverse />
            </div>
          </MenuItem>
        </div> */}

          {options.map((option, i) =>
            i < options.length - 1 ? (
              <MenuItem
                data-recording-sensitive
                value={getValue(option)}
                key={i}
                className={s.MenuItem}
                divider={false}
                disableGutters
              >
                {option.label || option.value}
              </MenuItem>
            ) : (
              <MenuItem
                data-recording-sensitive
                value={getValue(option)}
                key={i}
                className={s.MenuItem}
                divider={false}
                disableGutters={false}
              >
                {option.label || option.value}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {!value && highlightErrors ? (
        <span className={s.ErrorMessage}>{t(`blurErrors.OptionRequired`)}</span>
      ) : null}
    </>
  );
};
export default withConsumer(SelectInput);
