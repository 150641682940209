import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import smartlookClient from 'smartlook-client';
import AppRouter from "./routes/";
import { AppContextProvider } from "./appContext";
import {
  QUESTIONS_DE,
  QUESTIONS_EN,
  QUESTIONS_FR,
  QUESTIONS_IT
} from "./data/questions";
import {
  APP_DEFAULT_STATE,
  LOCAL_STORAGE_APP_STATE_KEY,
  LOCAL_STORAGE_APP_STATE_VERSION,
  LOCAL_STORAGE_APP_STATE_VERSION_KEY
} from "./data/config";
import Header from "./components/Header";
import FooterNavigation from "./components/FooterNavigation";
import { getFormInputs } from "./helpers/questions";
import Footer from "./components/Footer";
import "./App.scss";
import i18n from "./i18n/i18n";
import {
  getInvalidCharsRegex,
  insuranceRequired,
  validateEmail
} from "./helpers/validations";
import { iOSVersion13, isDesktop, elementInViewport } from "./helpers/render";
import TurnImage from "./media/images/turn.png";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cities from "./helpers/cities.json";
import { prefixOptions } from "./helpers/constants";
import { getBrowserLanguage } from "./helpers/utils";
import { OK_SUBMITTED } from "./helpers/api";

const IBANValidator = require("iban");

class App extends Component {
  state = {
    ...APP_DEFAULT_STATE,
    questions: QUESTIONS_DE,
    windowWidth: window.innerWidth,
    language: "DE",
    currentStep: 0,
    answers: getFormInputs(QUESTIONS_DE),
    stepQuestions: [],
    nextStepDisabled: true,
    hideTargetSelectField: true,
    hideTargetSelectFieldTwo: true,
    hideTargetSelectFieldThree: true,
    editing: false,
    editingSteps: [],
    firstStepNumber: 0,
    citiesSuggestionFromZip: [],
    submitError: "",
    highlightErrors: false,
    manual: false,
    sticky: true
  };

  emailValidationTimeout = null;

  // componentWillMount() {
  //   const splitUrl = document.location.pathname.split("/");
  //   if (splitUrl.length > 2 && splitUrl[2] === "") {
  //     return (window.location.href = document.location.href.slice(
  //       0,
  //       document.location.href.length - 1
  //     ));
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    localStorage.setItem(
      LOCAL_STORAGE_APP_STATE_KEY,
      JSON.stringify(this.state)
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth);
    const localStorageAppStateVersion = localStorage.getItem(
      LOCAL_STORAGE_APP_STATE_VERSION_KEY
    );
    const localStorageAppState =
      localStorageAppStateVersion === LOCAL_STORAGE_APP_STATE_VERSION
        ? localStorage.getItem(LOCAL_STORAGE_APP_STATE_KEY)
        : null;
    const params = new URLSearchParams(document.location.search);
    const manual = params.get("manual");
    const acquirer_code = params.get("acquirer_code");
    const splitUrl = document.location.pathname.split("/");
    const urlLang = splitUrl[1];
    if (!["de", "fr", "it"].includes(urlLang)) {
      return (window.location.href =
        document.location.origin +
        "/" +
        getBrowserLanguage() +
        document.location.pathname);
    }
    if (localStorageAppState) {
      const state = JSON.parse(localStorageAppState);
      const shouldCleanState = true;
      // const shouldCleanState = urlLang?.toUpperCase() !== state.language;
      state.language = urlLang?.toUpperCase();
      this.setState(state, () => {
        if (shouldCleanState) this.cleanState(false);
        i18n.changeLanguage(
          this.state.language && this.state.language.toLocaleLowerCase()
        );
      });
    } else {
      localStorage.removeItem(LOCAL_STORAGE_APP_STATE_KEY);
      localStorage.setItem(
        LOCAL_STORAGE_APP_STATE_VERSION_KEY,
        LOCAL_STORAGE_APP_STATE_VERSION
      );
      this.setState({ language: urlLang?.toUpperCase() }, () => {
        i18n.changeLanguage(
          this.state.language && this.state.language.toLocaleLowerCase()
        );
        this.cleanState(false);
      });
    }
    if (acquirer_code) {
      const ans = { ...this.state.answers };
      ans.promo.value = acquirer_code;
      this.setState({ answers: { ...ans } });
    }
    this.setState({ manual: manual === "1" });
    this.setState({
      stepQuestions: this.getQuestionsForSection()
    });

    if (!smartlookClient.initialized() && process.env.NODE_ENV !== 'development') {
      smartlookClient.init('63a0439f74fc23ef0016060c4e4f7d6e8bf6033c', { region: 'eu' });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  resetState = () => {
    this.setState(APP_DEFAULT_STATE);
    this.updateStep(0);
    localStorage.removeItem(LOCAL_STORAGE_APP_STATE_KEY);
  };
  cleanState = (cleanAnswers = true) => {
    let translations;
    switch (this.state.language) {
      case "EN":
        translations = QUESTIONS_EN;
        break;
      case "DE":
        translations = QUESTIONS_DE;
        break;
      case "FR":
        translations = QUESTIONS_FR;
        break;
      case "IT":
        translations = QUESTIONS_IT;
        break;
      default:
        translations = QUESTIONS_DE;
        break;
    }

    // deep clean values, otherwise address fields do not get cleaned
    if (cleanAnswers)
      for (let t of translations) {
        for (let qf of t.question_form) {
          if (qf.items) {
            for (let item of qf.items) {
              item.value = "";
            }
          }
        }
      }

    this.setState(({ answers }) => ({
      ...APP_DEFAULT_STATE,
      questions: translations,
      windowWidth: window.innerWidth,
      currentStep: 0,
      answers: cleanAnswers ? getFormInputs(translations) : answers,
      stepQuestions: [],
      nextStepDisabled: true,
      hideTargetSelectField: true,
      hideTargetSelectFieldTwo: true,
      hideTargetSelectFieldThree: true,
      editing: false,
      editingSteps: [],
      firstStepNumber: 0,
      citiesSuggestionFromZip: [],
      submitError: "",
      highlightErrors: false,
      manual: this.state.manual
    }));

    localStorage.removeItem(OK_SUBMITTED);
  };

  setFirstStepNumber = (number) => this.setState({ firstStepNumber: number });

  getQuestionsForSection = () => {
    const { questions, currentStep, windowWidth } = this.state;
    const forDesktops = isDesktop(windowWidth) ? 1 : 0;

    return questions.length > 0
      ? questions.filter((question) => {
          if (
            forDesktops &&
            ((question.question_step[0] === 7 &&
              !["employed", "apprentice", "Angestellt", "Lernende/r"].includes(
                this.state.answers.s6q2?.value
              )) ||
              (question.question_step[0] === 9 &&
                !insuranceRequired(this.state.answers)))
          )
            return false;
          return question.question_step[forDesktops] === Number(currentStep);
        })
      : null;
  };

  getTotalSteps = (width = null) => {
    const { questions, windowWidth } = this.state;
    const forDesktops = isDesktop(width ? width : windowWidth) ? 1 : 0;
    const lastQuestion = questions[questions.length - 1];

    return lastQuestion.question_step[forDesktops];
  };

  findPos = (obj) => {
    var curtop = 0;
    // if (obj?.offsetParent) {
    //   do {
    //     curtop += obj.offsetTop;
    //   } while ((obj === obj.offsetParent));
    // }
    return [curtop];
  };

  checkErrors = (
    newAnswers = { ...this.state.answers },
    dateLength = 10,
    IbanLength,
    fromNextButton = false
  ) => {
    let stepQuestions = this.getQuestionsForSection();

    let errors = {
      emptyFields: false,
      invalidZipcode: false,
      invalidBirthdate: false,
      invalidPhoneNumber: false,
      invalidPhoneNumberOptional: false,
      invalidEmail: false,
      invalidDateFields: false,
      invalidIban: false,
      invalidImageFront: false,
      invalidImageBack: false,
      invalidFirstName: false,
      invalidLastName: false,
      maxLength: false,
      invalidChars: false,
      invalidCity: false,
      invalidLength: false
    };

    const scrollIntoView = (el) => {
      this.setState({ scrolling: true });
      setTimeout(() => {
        this.setState({ scrolling: false });
      }, 1000);
      if (iOSVersion13()) return;
      if (isDesktop(window.innerWidth) && !elementInViewport(el))
        window.scroll({
          behavior: "smooth",
          top: this.findPos(el) - window.innerHeight / 3
        });
      else if (!isDesktop(window.innerWidth))
        document.getElementById("main").scroll({
          behavior: "smooth",
          top: this.findPos(el) - window.innerHeight / 3
        });
    };

    const validateField = (input) => {
      const fieldAnswer = newAnswers[input.id];

      if (input.id === "s8q1b") {
        this.setState({
          hideTargetSelectFieldTwo: !fieldAnswer.value
          // nextStepDisabled: false
        });
      }
      if (input.id === "s8q1c") {
        this.setState({
          hideTargetSelectFieldThree: !fieldAnswer.value
          // nextStepDisabled: false
        });
      }

      // match ex: "s8q2b"
      const q8_b_regex = /s8q[\d]*b/;
      const q8_c_regex = /s8q[\d]*c/;
      if (q8_b_regex.test(input.id) && this.state.hideTargetSelectFieldTwo) {
        delete input.error;
        // return null;
      }
      if (
        q8_c_regex.test(input.id) &&
        (this.state.hideTargetSelectFieldTwo ||
          this.state.hideTargetSelectFieldThree)
      ) {
        delete input.error;
        return null;
      }

      if (input.hasOwnProperty("required") && !input.required) {
        if (
          !fieldAnswer.value ||
          fieldAnswer.value === "//" ||
          (typeof fieldAnswer.value === "string" &&
            fieldAnswer.value.trim() === "")
        ) {
          delete input.error;
          return null;
        }
      }

      if (fieldAnswer) {
        // Validate for empty fields
        let hideTargetSelectField = this.state.hideTargetSelectField;
        if (input.id === "s3q1") {
          if (
            !fieldAnswer.value ||
            (fieldAnswer.value && /(ch)|(li)/i.test(fieldAnswer.value))
          ) {
            hideTargetSelectField = true;
          } else {
            hideTargetSelectField = false;
          }
          this.setState({ hideTargetSelectField });
        }
        if (
          ["s3q4", "s3q5"].includes(input.id) &&
          newAnswers["s3q1"].value &&
          ["ch", "li"].includes(newAnswers["s3q1"].value.toLowerCase())
        ) {
          delete input.error;
          return null;
        }

        if (
          !fieldAnswer.value ||
          fieldAnswer.value === "//" ||
          (typeof fieldAnswer.value === "string" &&
            fieldAnswer.value.trim() === "")
        ) {
          if (q8_b_regex.test(input.id) && !newAnswers["s8q1b"].value) {
          } else {
            errors.emptyFields = true;
          }
        }
        if (input.maxLength && fieldAnswer.value?.length > input.maxLength) {
          errors.maxLength = true;
          input.error = true;
        }

        if (
          fieldAnswer.value &&
          [
            "s2q2",
            "s2q5",
            "s8q5",
            "s8q8",
            "s7q1",
            "s7q2",
            "s7q4",
            "s8q2",
            "s8q3",
            "s8q2b",
            "s8q3b",
            "s8q5"
          ].includes(input.id)
        ) {
          if (!getInvalidCharsRegex(input.id).test(fieldAnswer.value)) {
            errors.invalidChars = true;
            input.error = true;
            // console.log("App -> validateField -> input", input);
          } else {
            delete input.error;
          }
        }

        // Validate birth date is empty
        if (fieldAnswer.type === "Calendar") {
          if (fieldAnswer.value && fieldAnswer.value.length < dateLength) {
            errors.invalidDateFields = true;
            input.error = true;
          }
        }
        if (fieldAnswer.formatting === "name" && fieldAnswer.value) {
          const fieldToChange =
            input.id === "first_name" ? "invalidFirstName" : "invalidLastName";
          // letter of all languages, but not numbers accepted
          errors[fieldToChange] = !getInvalidCharsRegex(input.id).test(
            fieldAnswer.value.trim()
          );

          if (errors[fieldToChange]) {
            input.error = true;
          } else {
            delete input.error;
          }
        }

        // Validate birthdate field
        if (
          fieldAnswer?.type === "Calendar" &&
          fieldAnswer?.value?.length >= dateLength
        ) {
          if (fieldAnswer.validation === "Birthdate") {
            const isValid = this.validateBirthdate(
              fieldAnswer.value.split("/")
            );
            errors.invalidBirthdate = errors.invalidBirthdate || !isValid;
            if (!isValid) {
              input.error = true;
            } else {
              delete input.error;
            }
          } else {
            const isValid = this.validateCalendar(fieldAnswer.value.split("/"));
            errors.invalidDateFields = errors.invalidDateFields || !isValid;
            if (!isValid) {
              input.error = true;
            } else {
              delete input.error;
            }
          }
        }
        if (input.id === "s8q1") {
          if (
            fieldAnswer.value === "ausschliesslich mir gehören." ||
            !fieldAnswer.value
          ) {
            this.setState({
              hideTargetSelectField: true
              // nextStepDisabled: false
            });
          } else {
            this.setState({
              hideTargetSelectField: false
              // nextStepDisabled: true
            });
          }
        }

        // Validate zip code
        if (fieldAnswer?.validation === "ZipCode" && fieldAnswer.value) {
          let basedOnAnswer;

          if (!fieldAnswer.value) return;

          if (fieldAnswer.id.includes("s2q")) basedOnAnswer = "s2q1";
          else if (fieldAnswer.id.includes("s8q")) {
            if (fieldAnswer.id.includes("b")) {
              basedOnAnswer = "s8q4b";
            } else {
              basedOnAnswer = "s8q4";
            }
          } else if (fieldAnswer.id.includes("s7q")) {
            errors.invalidZipcode = fieldAnswer.value.length !== 4;
            return;
          }

          let validateZipBasedOn = newAnswers[basedOnAnswer];
          errors.invalidZipcode = !this.validateZipCode(
            validateZipBasedOn,
            fieldAnswer.value
          );

          if (errors.invalidZipcode) input.error = true;
          else delete input.error;
        }

        // Validate phone number fields
        if (fieldAnswer?.type === "Cellphone") {
          const fieldToChange =
            fieldAnswer.id === "s4q2"
              ? "invalidPhoneNumber"
              : "invalidPhoneNumberOptional";

          let minLength = prefixOptions.find(
            (option) => option.value === fieldAnswer.value?.split("-")[0]
          )?.min;

          if (
            fieldAnswer.value &&
            fieldAnswer.value.split("-")[1].startsWith("0")
          )
            minLength++;
          errors[fieldToChange] =
            fieldAnswer.id === "s4q2"
              ? !fieldAnswer.value ||
                !fieldAnswer.value.includes("-") ||
                fieldAnswer.value.split("-")[1].length < minLength
              : fieldAnswer.value &&
                fieldAnswer.value.includes("-") &&
                fieldAnswer.value.split("-")[1].length > 0 &&
                fieldAnswer.value.split("-")[1].length < minLength;

          if (errors[fieldToChange]) {
            input.error = true;
          } else {
            delete input.error;
          }
        }

        // Validate IBAN field
        if (fieldAnswer?.validation === "IBAN") {
          if (fieldAnswer.value.length >= 2) {
            if (
              fieldAnswer.value.includes(" ") ||
              fieldAnswer.value.includes("-") ||
              (!fieldAnswer.value.startsWith("CH") &&
                !fieldAnswer.value.startsWith("LI"))
            )
              errors.invalidIban = true;
          }
          if (!IBANValidator.isValid(fieldAnswer.value)) {
            errors.invalidIban = true;
          }
          if (IbanLength && fieldAnswer.value.length < IbanLength) {
            errors.invalidIban = true;
          }
          if (errors.invalidIban) {
            input.error = true;
          } else {
            delete input.error;
          }
        }

        // Validate E-mail
        if (fieldAnswer?.validation === "Email" && fieldAnswer.value) {
          errors.invalidEmail = !validateEmail(fieldAnswer.value);

          if (errors.invalidEmail) {
            input.error = true;
          } else {
            delete input.error;
          }
        }

        if (fieldAnswer?.validation === "city" && fieldAnswer.value) {
          errors.invalidCity = fieldAnswer.value.length > 25;

          if (errors.invalidCity) {
            input.error = true;
          } else {
            delete input.error;
          }
        }

        // if (input.minLength) {
        //   if (fieldAnswer && fieldAnswer.value?.length < input.minLength) {
        //     input.error = true;
        //     errors.invalidLength = true;
        //   } else delete input.error;
        // }

        // Make images required
        if (fieldAnswer?.type === "ImageUpload") {
          if (fieldAnswer.id === "s11q1") {
            errors.invalidImageFront = !fieldAnswer?.value;
          } else {
            errors.invalidImageBack = !fieldAnswer?.value;
          }

          if (!fieldAnswer?.value) {
            input.error = true;
          } else {
            delete input.error;
          }
        }
        if (fieldAnswer?.type === "Checkbox" && fieldAnswer.required) {
          if (!fieldAnswer.value) {
            input.error = true;
          } else {
            delete input.error;
          }
        }
      }

      // console.log("App -> validateField -> input.error", input.error, input.id);
      if (
        !input.optional &&
        fieldAnswer &&
        (!fieldAnswer.value || input.error) &&
        fromNextButton &&
        !this.state.scrolling
      ) {
        // console.log("Scroll to id", input.id);
        scrollIntoView(document.getElementById(input.id));
      }
      return null;
    };

    stepQuestions.map(({ question_form }) =>
      question_form.map((input) => {
        if (input.type === "Row") {
          return input.items.map((item) => validateField(item));
        }
        return validateField(input);
      })
    );
    if (Object.values(errors).some((error) => error)) {
      this.setState({
        nextStepDisabled: true
      });
    } else {
      this.setState({
        nextStepDisabled: false
      });
    }

    return errors;
  };

  validateCity = (value, id) => {
    let zipCode;
    switch (id) {
      case "s2q5":
        zipCode = this.state.answers["s2q4"].value;
        break;
      case "s7q4":
        zipCode = this.state.answers["s7q3"].value;
        break;
      case "s8q8":
        zipCode = this.state.answers["s8q7"].value;
        break;
      case "s8q8b":
        zipCode = this.state.answers["s8q7b"].value;
        break;

      default:
        break;
    }
    for (const city of Cities) {
      if (city.City === value && city["Zip code"] === zipCode) return true;
    }
    return false;
  };

  updateStep = (step) => {
    this.setState(
      {
        currentStep: step,
        totalSteps: this.getTotalSteps()
      },
      () => {
        window.scrollTo(0, 0);
        this.checkErrors();
      }
    );
  };

  setSticky = (sticky) => this.setState({ sticky });

  handleLanguageChange = (id, language) => {
    let translations = {};
    i18n.changeLanguage(language.toLowerCase());
    const newPath = document.location.pathname.replace(
      `/${this.state.language.toLowerCase()}`,
      `/${language.toLowerCase()}`
    );
    window.history.pushState({}, document.title, newPath);
    switch (language) {
      case "EN":
        translations = QUESTIONS_EN;
        break;
      case "DE":
        translations = QUESTIONS_DE;
        break;
      case "FR":
        translations = QUESTIONS_FR;
        break;
      case "IT":
        translations = QUESTIONS_IT;
        break;
      default:
        translations = QUESTIONS_DE;
        break;
    }
    this.setState({
      language: language,
      questions: translations,
      answers: getFormInputs(translations)
    });
  };

  isLandscapeMode = () => {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      var mql = window.matchMedia("(orientation: landscape)");
      return mql.matches;
    }

    return false;
  };

  updateWindowWidth = () => {
    this.setState({
      windowWidth: this.isLandscapeMode()
        ? window.innerHeight
        : window.innerWidth,
      totalSteps: this.getTotalSteps(window.innerWidth)
    });
  };

  validateZipCode = (selectCountryField, zipCodeValue) => {
    if (!selectCountryField?.value) return true;
    if (!zipCodeValue) return true;
    const stepQuestion = this.state.questions.find((question) =>
      question.question_form.find((field) => field.id === selectCountryField.id)
    );
    const countrySelectFieldOptions = stepQuestion.question_form.find(
      (field) => field.id === selectCountryField.id
    )?.options;

    const selectedCountry = countrySelectFieldOptions.find(
      (option) => option.value === selectCountryField.value
    );

    if (!selectedCountry) return false;

    const { maxZipCodeLength, minZipCodeLength } = selectedCountry;

    if (!maxZipCodeLength || !minZipCodeLength) return true;

    return (
      zipCodeValue.length >= minZipCodeLength &&
      zipCodeValue.length <= maxZipCodeLength
    );
  };

  getAge = (birthdate) => {
    var today = new Date();
    var birthDate = new Date(birthdate);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;

    // const dateDiff = Date.now() - birthdate.getTime();
    // const age = new Date(dateDiff);
    // return Math.abs(age.getUTCFullYear() - 1970);
  };

  validateBirthdate = ([day, month, year]) => {
    const validDate = this.validateCalendar([day, month, year]);

    const birthday = new Date(`${year}/${month}/${day}`);
    const age = this.getAge(birthday);
    return validDate && age >= 18;
  };

  validateCalendar = ([day, month, year]) => {
    const now = new Date().getTime();
    const aYear = 31556952000;

    const validDay = Number(day) >= 1 && Number(day) <= 31;
    const validMonth = Number(month) >= 1 && Number(month) <= 12;
    const validYear =
      Number(year) <= new Date().getFullYear() &&
      Number(year) >= new Date().getFullYear() - 100;
    const dateParsed = new Date(`${year}/${month}/${day}`).getTime();
    const pastDate = dateParsed < now;
    const isNewerThan100Years = dateParsed > now - 100 * aYear;
    return (
      validDay && validMonth && validYear && pastDate && isNewerThan100Years
    );
  };

  setFormInputValue = (
    inputId,
    value = null,
    file = null,
    abbreviation = null
  ) => {
    let newAnswers = { ...this.state.answers };
    if (newAnswers[inputId]) {
      newAnswers[inputId].value = value;
    }

    if (abbreviation) {
      newAnswers[inputId].abbreviation = abbreviation;
    }

    if (inputId === "s2q1") {
      newAnswers["s2q4"].value = "";
      newAnswers["s2q5"].value = "";

      const prefix = this.getPrefixFromCountry(abbreviation);
      newAnswers["s4q2"].value = prefix;
      newAnswers["s4q3"].value = prefix;

      if (
        !isDesktop(window.innerWidth) &&
        this.state.editing &&
        this.state.editingSteps &&
        !this.state.editingSteps.includes(4)
      ) {
        this.setState({ editingSteps: [...this.state.editingSteps, 4].sort() });
      }
    }
    if (inputId === "s8q4") {
      newAnswers["s8q7"].value = "";
      newAnswers["s8q8"].value = "";
    }
    if (inputId === "s8q4b") {
      newAnswers["s8q7b"].value = "";
      newAnswers["s8q8b"].value = "";
    }
    if (newAnswers["s8q1"].value === "ausschliesslich mir gehören.") {
      newAnswers["s8q2"].value = newAnswers["first_name"].value;
      newAnswers["s8q3"].value = newAnswers["last_name"].value;
      newAnswers["s8q4"].value = newAnswers["s2q1"].value;
      newAnswers["s8q4"].abbreviation = newAnswers["s2q1"].abbreviation;
      newAnswers["s8q5"].value = newAnswers["s2q2"].value;
      newAnswers["s8q6"].value = newAnswers["s2q3"].value;
      newAnswers["s8q7"].value = newAnswers["s2q4"].value;
      newAnswers["s8q8"].value = newAnswers["s2q5"].value;
      newAnswers["s8q9"].value = newAnswers["s3q1"].value;
      newAnswers["s8q10"].value = newAnswers["s3q3"].value;
      newAnswers["s8q1b"].value = false;
      newAnswers["s8q2b"].value = undefined;
      newAnswers["s8q3b"].value = undefined;
      newAnswers["s8q4b"].value = undefined;
      newAnswers["s8q4b"].abbreviation = undefined;
      newAnswers["s8q5b"].value = undefined;
      newAnswers["s8q6b"].value = undefined;
      newAnswers["s8q7b"].value = undefined;
      newAnswers["s8q8b"].value = undefined;
      newAnswers["s8q9b"].value = undefined;
      newAnswers["s8q10b"].value = undefined;
    } else if (
      newAnswers["s8q1"].value ===
        "der/den nachfolgend aufgeführten  Person/en gehören." &&
      newAnswers["s8q2"].value === newAnswers["first_name"].value &&
      newAnswers["s8q3"].value === newAnswers["last_name"].value &&
      newAnswers["s8q4"].value === newAnswers["s2q1"].value &&
      newAnswers["s8q5"].value === newAnswers["s2q2"].value &&
      newAnswers["s8q6"].value === newAnswers["s2q3"].value &&
      newAnswers["s8q7"].value === newAnswers["s2q4"].value &&
      newAnswers["s8q8"].value === newAnswers["s2q5"].value &&
      newAnswers["s8q9"].value === newAnswers["s3q1"].value &&
      newAnswers["s8q10"].value === newAnswers["s3q3"].value
    ) {
      newAnswers["s8q2"].value = undefined;
      newAnswers["s8q3"].value = undefined;
      newAnswers["s8q4"].value = undefined;
      newAnswers["s8q4"].abbreviation = undefined;
      newAnswers["s8q5"].value = undefined;
      newAnswers["s8q6"].value = undefined;
      newAnswers["s8q7"].value = undefined;
      newAnswers["s8q8"].value = undefined;
      newAnswers["s8q9"].value = undefined;
      newAnswers["s8q10"].value = undefined;
    }

    if (!newAnswers["s8q1b"].value) {
      newAnswers["s8q2b"].value = undefined;
      newAnswers["s8q3b"].value = undefined;
      newAnswers["s8q4b"].value = undefined;
      newAnswers["s8q4b"].abbreviation = undefined;
      newAnswers["s8q5b"].value = undefined;
      newAnswers["s8q6b"].value = undefined;
      newAnswers["s8q7b"].value = undefined;
      newAnswers["s8q8b"].value = undefined;
      newAnswers["s8q9b"].value = undefined;
      newAnswers["s8q10b"].value = undefined;
    }

    // if (newAnswers[inputId].id === 's8q1' && newAnswers[inputId].value !== '... ausschliesslich mir gehören.') {
    this.checkErrors(newAnswers);
    // }

    this.setState({
      answers: newAnswers
    });
  };

  setEditingSteps = (state, value) => {
    this.setState(
      {
        [state]: value
      },
      () => this.checkErrors()
    );
  };

  setCitiesSuggestionFromZip = (value) => {
    this.setState({
      citiesSuggestionFromZip: value
    });
  };
  setSubmitError = (msg) => {
    this.setState({
      submitError: msg
    });
  };

  setHighlightErrors = (highlightErrors) => {
    this.setState({ highlightErrors });
  };

  getPrefixFromCountry = (country) => {
    switch (country) {
      case "CH":
        return "0041-";
      case "DE":
        return "0049-";
      case "FR":
        return "0033-";
      case "IT":
        return "0039-";
      case "AT":
        return "0043-";
      case "LI":
        return "00423-";
      default:
        return "0041-";
    }
  };

  render() {
    const {
      updateStep,
      resetState,
      cleanState,
      setSubmitError,
      handleLanguageChange,
      setFormInputValue,
      getQuestionsForSection,
      updateNextStepButton,
      validateZipCode,
      validateBirthdate,
      setEditingSteps,
      checkErrors,
      setFirstStepNumber,
      setCitiesSuggestionFromZip,
      setHighlightErrors,
      setSticky
    } = this;

    const { t } = this.props;


    if (window.location.href.includes("de/zusatzkartenantrag")) {
      window.location.href = `https://phoenix-webonboarding.viseca.ch/de/zusatzkartenantrag${window.location.search}`
      return <></>
    }

    if (window.location.href.includes("fr/demande-carte-supplementaire")) {
      window.location.href = `https://phoenix-webonboarding.viseca.ch/fr/demande-carte-supplementaire${window.location.search}`
      return <></>
    }

    if (window.location.href.includes("it/richiesta-carta-supplementare")) {
      window.location.href = `https://phoenix-webonboarding.viseca.ch/it/richiesta-carta-supplementare${window.location.search}`
      return <></>
    }



    return (
      <BrowserRouter>
        <AppContextProvider
          value={{
            ...this.state,
            updateStep,
            resetState,
            cleanState,
            handleLanguageChange,
            setFormInputValue,
            getQuestionsForSection,
            updateNextStepButton,
            validateZipCode,
            validateBirthdate,
            setEditingSteps,
            checkErrors,
            setFirstStepNumber,
            setCitiesSuggestionFromZip,
            setSubmitError,
            setHighlightErrors,
            setSticky
          }}
        >
          <Header firstStepNumber={this.state.firstStepNumber} />
          {/* <Layout> */}
          <AppRouter />
          {/* </Layout> */}
          {!isDesktop(this.state.windowWidth) && this.state.currentStep > 0 && (
            <FooterNavigation />
          )}
          {isDesktop(this.state.windowWidth) && <Footer />}
        </AppContextProvider>
        <div id='orientation' style={{ display: "none" }}>
          <div>
            <img alt={"turn phone"} src={TurnImage} />
            <h3>{this.props.t("rotate-phone")}</h3>
          </div>
        </div>
        <Helmet>
          <title>{t("home.doc-title")}</title>
          <meta name='description' content={t("home.doc-desc")} />
        </Helmet>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(App);
