import React from "react";
import ListItem from "../ListItem";
import s from "./List.module.scss";

const List = ({ list }) => (
  <ul className={`${s.Container}`}>
    {list.map((item, i) => (
      <ListItem key={i}>{item}</ListItem>
    ))}
  </ul>
);

export default List;
