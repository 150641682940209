import React, { useState, useEffect, useRef } from "react";
import { withConsumer } from "../../appContext";
import { Close } from "../Icon/Icon";
import { isDesktop } from "../../helpers/render";
import s from "./Overlay.module.scss";
import { useTranslation } from "react-i18next";

const Overlay = ({
  info,
  currentStep,
  imgSrc,
  text,
  show,
  setDisplayOverlay,
  khra,
  setKhra,
  title1,
  title2,
  title3,
  title4,
  listItem1,
  listItem2,
  listItem3,
  listItem4,
  listItem5,
  listItem6,
  listItem7,
  listItem8,
  listItem9,
  listItem10,
  listItem11,
  listItem12,
  listItem13,
  listItem14,
  listItem15,
  listItem16,
  listItem17,
  listItem18,
  para1,
  para2,
  para3,
  para4,
  para5,
  para6,
  para7,
  windowWidth,
  answers
}) => {
  const { t } = useTranslation();
  const overlayRef = useRef(null);
  const [overlayHeight, setOverlayHeight] = useState(null);
  const isForeigner =
    answers &&
    answers.s3q1.value &&
    !["ch", "li"].includes(answers.s3q1.value.toLowerCase());

  useEffect(() => {
    const getDetails = async () => {
      const coords = await overlayRef.current?.getBoundingClientRect();
      if (!coords) return;
      const viewerHeight = window.innerHeight;
      setOverlayHeight(calculateOverleyHeight(coords, viewerHeight));
    };

    setTimeout(() => {
      getDetails();
    }, 700);
  }, []);

  const calculateOverleyHeight = (coords, viewerHeight) => {
    return viewerHeight - (coords.top + 110);
  };

  const renderItem = (item) => (item ? item : null);

  const renderContent = () => {
    if (info === "4") {
      return (
        <div className={s.ImagesContainer + " " + s.Scroll}>
          <p className={s.PreviewLabel}>{t("overlay.front-id")}</p>
          <div className={`${s.Image1} ${isForeigner ? s.Foreigner : ""}`} />

          <p className={s.PreviewLabel}>{t("overlay.back-id")}</p>
          <div className={`${s.Image2} ${isForeigner ? s.Foreigner : ""}`} />
        </div>
      );
    } else if (text && info === "1") {
      return (
        <div className={s.Scroll}>
          <div className={s.LogoImageHelvetia} />

          <h4 className={s.Title}>{renderItem(title1)}</h4>

          <ul className={s.UList}>
            <li>{renderItem(listItem1)}</li>
            <li>{renderItem(listItem2)}</li>
          </ul>

          <h4 className={s.Title}>{renderItem(title2)}</h4>
          <p className={s.Para}>{renderItem(para1)}</p>
          <p className={s.Para}>{renderItem(para2)}</p>

          <h4 className={s.Title}>{renderItem(title3)}</h4>
          <ul className={s.UList}>
            <li>{renderItem(listItem3)}</li>
            <li>{renderItem(listItem4)}</li>
            <li>{renderItem(listItem5)}</li>
            <li>{renderItem(listItem6)}</li>
          </ul>
          <ul className={s.UList}>
            <li>{renderItem(listItem7)}</li>
            <li>{renderItem(listItem8)}</li>
          </ul>

          <h4 className={s.Title}>{renderItem(title4)}</h4>
          <ul className={s.UList}>
            <li>{renderItem(listItem9)}</li>
            <li>{renderItem(listItem10)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem11)}</li>
            <li>{renderItem(listItem12)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem13)}​</li>
            <li>{renderItem(listItem14)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem15)}</li>
            <li>{renderItem(listItem16)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem17)}</li>
            <li>{renderItem(listItem18)}​</li>
          </ul>

          <p className={s.Para}>{renderItem(para3)}</p>

          <p className={s.Para}>{renderItem(para4)}</p>

          <p className={s.Para}>{renderItem(para5)}</p>

          <p className={s.Para}>{renderItem(para6)}</p>

          <p className={s.Para}>{renderItem(para7)}</p>
        </div>
      );
    } else if (text && info === "3") {
      return (
        <div className={s.Scroll}>
          <div className={s.LogoImageBasler} />

          <h4 className={s.Title}>{renderItem(title1)}</h4>

          <p className={s.Para}>{renderItem(para1)}</p>

          <p className={s.Para}>{renderItem(para2)}</p>

          <p className={s.Para}>{renderItem(para3)}</p>

          <ul className={s.UList}>
            <li>{renderItem(listItem1)}</li>
            <li>{renderItem(listItem2)}</li>
            <li>{renderItem(listItem3)}</li>
          </ul>

          <h4 className={s.Title}>{renderItem(title2)}</h4>

          <ul className={s.UList}>
            <li>{renderItem(listItem4)}</li>
            <li>{renderItem(listItem5)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem6)}</li>
            <li>{renderItem(listItem7)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem8)}</li>
            <li>{renderItem(listItem9)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem10)}</li>
            <li>{renderItem(listItem11)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem12)}</li>
            <li>{renderItem(listItem13)}</li>
            <li>{renderItem(listItem14)}</li>
          </ul>

          <ul className={s.UList}>
            <li>{renderItem(listItem15)}</li>
            <li>{renderItem(listItem16)}</li>
          </ul>
        </div>
      );
    }
  };

  const setHeight = () => {
    if (overlayHeight) return { height: overlayHeight };
  };

  return (
    <div>
      <div className={s.OverlayContainer} ref={overlayRef}>
        <div
          className={show ? `${s.Container}` : `${s.Container} ${s.Hidden}`}
          style={setHeight()}
        >
          {renderContent()}
          <span className={s.Close} onClick={() => setDisplayOverlay(false)}>
            {Close(s.Icon)}
          </span>
        </div>
      </div>
      {show && isDesktop(windowWidth) && <div className={s.OverlayBackdrop} />}
    </div>
  );
};

export default withConsumer(Overlay);
