import React, { PureComponent, useEffect } from "react";
import { withConsumer } from "../../appContext";
import { withRouter, Prompt } from "react-router-dom";
import s from "./Content.module.scss";

import { isDesktop } from "../../helpers/render";
import { Redirect } from "react-router-dom";
import {
  LOCAL_STORAGE_APP_STATE_KEY,
  LOCAL_STORAGE_APP_STATE_VERSION,
  LOCAL_STORAGE_APP_STATE_VERSION_KEY
} from "../../data/config";

import Step from "../Step";
import StepProgress from "../../components/StepProgress";
import DecorativeSidebar from "../../components/DecorativeSidebar/DecorativeSidebar";

class Content extends PureComponent {
  state = { top: 0, isSticky: false };
  elTop = 8.6 * parseFloat(getComputedStyle(document.documentElement).fontSize);
  componentDidMount() {
    const localStorageAppStateVersion = localStorage.getItem(
      LOCAL_STORAGE_APP_STATE_VERSION_KEY
    );
    const localStorageAppState =
      localStorageAppStateVersion === LOCAL_STORAGE_APP_STATE_VERSION
        ? localStorage.getItem(LOCAL_STORAGE_APP_STATE_KEY)
        : null;

    if (!localStorageAppState) {
      this.props.history.push("/de");
    }
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep && this.main) {
      this.main.scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    return window.removeEventListener("scroll", this.handleScroll.bind(this));
  }
  handleScroll() {
    if (window.scrollY >= this.elTop) {
      this.setState({
        isSticky: true,
        top: window.scrollY - this.elTop
      });
    } else {
      this.setState({
        isSticky: false,
        top: 0
      });
    }
  }
  render() {
    const {
      currentStep,
      totalSteps,
      windowWidth,
      answers,
      updateStep
    } = this.props;
    return (
      <section className={s.Container} ref={(ref) => (this.cont = ref)}>
        <Prompt
          message={(location, action) => {
            if (action === "POP" && currentStep > 1) {
              updateStep(currentStep - 1);
              return false;
            }
            if (action === "POP" && currentStep === 1) {
              updateStep(0);
              return true;
            }
          }}
        />
        <div className={s.Main} id='main' ref={(ref) => (this.main = ref)}>
          {!isDesktop(windowWidth) ? (
            currentStep > 0 && (
              <StepProgress
                answers={answers}
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
            )
          ) : (
            <div
              className={`${s.ProgressContainer}`}
              style={
                this.state.isSticky
                  ? {
                      position: "fixed",
                      left: `${this.cont?.getBoundingClientRect().left || 0}px`,
                      top: document.getElementsByTagName("header")[0]
                        .offsetHeight,
                      width: `${
                        this.cont?.getBoundingClientRect().width / 1.55 || 0
                      }px`
                    }
                  : null
              }
            >
              {currentStep > 0 && (
                <StepProgress
                  answers={answers}
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                  isSticky={this.state.isSticky && isDesktop(windowWidth)}
                />
              )}
            </div>
          )}
          <Step />
        </div>
        <DecorativeSidebar top={this.elTop} right={this.cont} />
      </section>
    );
  }
}

export default withRouter(withConsumer(Content));
