import React, { useState, useRef, useEffect } from "react";
import {
  getSize,
  isIOS,
  iOSVersion13,
  isDesktop,
  elementInViewport
} from "../../helpers/render";
import { withConsumer } from "../../appContext";
import CITIES from "../../helpers/cities.json";
import s from "./TextInput.module.scss";
import { useTranslation } from "react-i18next";
import { validateEmail, getInvalidCharsRegex } from "../../helpers/validations";

const TextInput = ({
  id,
  name,
  size,
  type,
  isNumberField,
  label,
  error,
  errorMessage,
  value = "",
  handleChange,
  hideTargetSelectField,
  hideTargetSelectFieldTwo,
  hideTargetSelectFieldThree,
  highlightErrors,
  formatting,
  maxLength,
  citiesSuggestionFromZip,
  setCitiesSuggestionFromZip,
  answers,
  validation,
  optional,
  validateZipCode,
  manual,
  minLength
}) => {
  const { t } = useTranslation();
  const [controlledValue, setControlledValue] = useState(value);
  const [showSuggest, setShowSuggest] = useState(false);
  const [blurError, setBlurError] = useState("");
  const [hasValue, setHasValue] = useState(value.length ? s.HasValue : "");
  const textInput = useRef(null);

  const specialCharsRegex = getInvalidCharsRegex(id);

  useEffect(() => {
    getCities();
    onBlur();
  }, []);

  useEffect(() => {
    setHasValue(value.length ? s.HasValue : "");
    setControlledValue(value);
  }, [value]);
  useEffect(() => {
    function clickOutside(e) {
      if (textInput.current && textInput.current !== e.target) {
        setShowSuggest(false);
      }
    }

    window.addEventListener("click", clickOutside);
    return () => window.removeEventListener("click", clickOutside);
  }, []);

  // useEffect(() => {
  //   if (highlightErrors && !value) scrollElementIntoView();
  // }, [highlightErrors]);

  const onChange = (e) => {
    let { value } = e.target;

    if (value ?? false) {
      const onlyZeroRegex = /^0*$/;
      if (
        formatting === "Number" &&
        // !onlyZeroRegex.test(value) &&
        isNaN(value)
      ) {
        return;
      }
      if (formatting === "nonZero") {
        return handleChange(id, value.replace(/^0+/, ""));
      }
      if (validation != "city" && maxLength && value.length > maxLength) {
        return;
      }
      if (formatting === "Money") {
        value = value.replace(/'/g, "");
        value = value.replace(/\./g, "");
        value = value.replace(/\,/g, "");
        if (Number(value) < 1) return;
        if (!Number(value) || Number(value) > 999999) {
          return;
        } else if (value.length > 3) {
          let formattedValue = `${value.substring(
            0,
            value.length - 3
          )}'${value.substring(value.length - 3)}`;
          return handleChange(id, formattedValue);
        }
      }
      if (["s2q3", "s8q6"].includes(id) && !specialCharsRegex.test(value))
        return;
    }
    handleChange(id, value);
  };
  const onBlur = () => {
    if (!textInput.current) return;
    const value = textInput.current?.value;
    switch (validation) {
      case "Email":
        setBlurError(!validateEmail(value) ? validation : "");
        break;
      case "ZipCode":
        let basedOnAnswer = "";
        if (id.includes("s2q")) basedOnAnswer = "s2q1";
        else if (id.includes("s8q")) {
          if (id.includes("b")) {
            basedOnAnswer = "s8q4b";
          } else {
            basedOnAnswer = "s8q4";
          }
        } else if (id.includes("s7q"))
          return setBlurError(value.length !== 4 ? validation : "");

        let validateZipBasedOn = answers[basedOnAnswer];
        setBlurError(
          !validateZipCode(validateZipBasedOn, value) ? validation : ""
        );
        break;

      default:
        break;
    }
  };

  const onFocus = (e) => {
    scrollElementIntoView(e);
    setBlurError(false);
  };

  if (validation === "Email") error = false;

  const handleCityChange = (e) => {
    setShowSuggest(true);
    setControlledValue(e.target.value);
    handleChange(id, e.target.value);
  };

  const getCities = () => {
    if (
      id === "s2q4" ||
      id === "s7q3" ||
      id === "s8q7" ||
      id === "s8q7b" ||
      id === "s8q7c"
    ) {
      const inputLength = value.length;
      let validateCityBasedOn;
      if (id.includes("s2q")) validateCityBasedOn = answers["s2q1"];
      else if (id.includes("s8q")) {
        if (id.includes("b")) validateCityBasedOn = answers["s8q4b"];
        else {
          validateCityBasedOn = answers["s8q4"];
        }
      }
      // else if (id.includes("s8q")) validateCityBasedOn = answers["s8q4"];
      else
        validateCityBasedOn = {
          abbreviation: "CH"
        };

      if (validateCityBasedOn.abbreviation === "LI")
        validateCityBasedOn.abbreviation = "FL";

      let suggestions;
      if (validateCityBasedOn?.abbreviation) {
        suggestions =
          inputLength === 0
            ? []
            : CITIES.filter((city) => {
                return (
                  city["Country"] === validateCityBasedOn.abbreviation &&
                  city["Zip code"] &&
                  city["Zip code"].toString().includes(value)
                );
              });
      } else {
        suggestions =
          inputLength === 0
            ? []
            : CITIES.filter((city) => {
                return (
                  city["Zip code"] &&
                  city["Zip code"].toString().includes(value)
                );
              });
      }
      setCitiesSuggestionFromZip(suggestions);
    }
  };

  const handleClick = () => textInput.current.focus();

  function findPos(obj) {
    var curtop = 0;
    if (obj?.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
    }
    return [curtop];
  }

  if (
    hideTargetSelectField &&
    (id === "s8q2" ||
      id === "s8q3" ||
      id === "s8q5" ||
      id === "s8q6" ||
      id === "s8q7" ||
      id === "s8q8")
  ) {
    return null;
  }
  if (
    hideTargetSelectFieldTwo &&
    (id === "s8q2b" ||
      id === "s8q3b" ||
      id === "s8q5b" ||
      id === "s8q6b" ||
      id === "s8q7b" ||
      id === "s8q8b")
  ) {
    return null;
  }
  if (
    (hideTargetSelectFieldTwo || hideTargetSelectFieldThree) &&
    (id === "s8q2c" ||
      id === "s8q3c" ||
      id === "s8q5c" ||
      id === "s8q6c" ||
      id === "s8q7c" ||
      id === "s8q8c")
  ) {
    return null;
  }
  if (id === "promo" && !manual) return null;

  const scrollElementIntoView = () => {
    setTimeout(() => {
      if (iOSVersion13()) return;
      if (isDesktop(window.innerWidth) && !elementInViewport(textInput.current))
        window.scroll({
          behavior: "smooth",
          top: findPos(textInput.current) - window.innerHeight / 3
        });
      else if (!isDesktop(window.innerWidth))
        document.getElementById("main").scroll({
          behavior: "smooth",
          top: findPos(textInput.current) - window.innerHeight / 3
        });

      // else if (!isDesktop(window.innerWidth)) {
      //   window.scroll({
      //     behavior: "smooth",
      //     top: findPos(textInput.current) - 140
      //   });
      // }
    }, 200);
  };

  const suggest = (e) => {
    onFocus(e);
    if (citiesSuggestionFromZip.length > 0) {
      setShowSuggest(true);
    }
  };

  const selectSuggestion = (val) => {
    setControlledValue(val);
    textInput.current.value = val;
    handleChange(id, val);
    setShowSuggest(false);
  };

  function findPosition(element) {
    const elementTop = element.current?.getBoundingClientRect().top;
    if (elementTop < document.body.offsetHeight / 2) {
      return "top";
    }
    return "bottom";
  }

  const showCityInput = () => {
    if (!["s2q5", "s7q4", "s8q8", "s8q8b", "s8q8c"].includes(id)) return false;
    let validateCityBasedOn;
    if (id.includes("s2q")) validateCityBasedOn = answers["s2q1"];
    else if (id.includes("s8q")) {
      if (id.includes("b")) validateCityBasedOn = answers["s8q4b"];
      else {
        validateCityBasedOn = answers["s8q4"];
      }
    } else
      validateCityBasedOn = {
        abbreviation: "CH"
      };

    return ["CH", "LI", "FL"].includes(validateCityBasedOn.abbreviation);
  };
  return (
    <div style={getSize(size)}>
      <div className={`${s.Group} ${s[id]}`}>
        {showCityInput() ? (
          <>
            <input
              data-name={name}
              data-recording-sensitive
              className={`${s.Input} ${
                (error || blurError || (!value && !optional)) && highlightErrors
                  ? s.Errored
                  : ""
              } ${hasValue}`}
              id={id}
              name={name}
              onClick={scrollElementIntoView}
              // onFocus={scrollElementIntoView}
              autoComplete={"off"}
              ref={textInput}
              type={isNumberField ? "tel" : type}
              inputMode={isNumberField ? "numeric" : undefined}
              onFocus={suggest}
              // onBlur={() => setShowSuggest(false)}
              onChange={handleCityChange}
              value={controlledValue}
            />
            <label onClick={handleClick}>{label}</label>

            {
              // Added this condtion so that we only see the drop down if we have some matching values
              citiesSuggestionFromZip.length > 0 &&
                citiesSuggestionFromZip.some((entry) =>
                  entry.City.toLowerCase().includes(
                    controlledValue.toLowerCase()
                  )
                ) && (
                  <ul
                    className={`
                   ${
                     findPosition(textInput) === "top"
                       ? s.topSide
                       : s.bottomSide
                   }
                   ${
                     showSuggest
                       ? citiesSuggestionFromZip.length < 2
                         ? `${s.SuggestionsList} ${s.SuggestionsListShort}`
                         : s.SuggestionsList
                       : `${s.SuggestionsList} ${s.Hidden}`
                   }`}
                  >
                    {citiesSuggestionFromZip.length > 0
                      ? citiesSuggestionFromZip
                          .filter((entry) =>
                            controlledValue
                              ? entry.City.toLowerCase().includes(
                                  controlledValue.toLowerCase()
                                )
                              : true
                          )
                          .map((entry, index) => (
                            <li
                              key={index}
                              onClick={() => selectSuggestion(entry.City)}
                              // onBlur={() => setShowSuggest(false)}
                            >
                              {entry.City}
                            </li>
                          ))
                      : null}
                  </ul>
                )
            }
          </>
        ) : (
          <>
            <input
              data-name={name}
              data-recording-sensitive
              className={`${s.Input} ${
                (error || blurError || (!value && !optional)) && highlightErrors
                  ? s.Errored
                  : ""
              } ${hasValue}`}
              id={id}
              name={name}
              onClick={scrollElementIntoView}
              onFocus={onFocus}
              onBlur={() => {
                getCities();
                onBlur();
              }}
              ref={textInput}
              type={isNumberField ? "text" : type}
              inputMode={isNumberField ? "numeric" : undefined}
              // pattern={isNumberField ? "[0-9]*" : undefined} Removed this to fix errors in firefox
              onChange={onChange}
              value={value}
            />
            <label onClick={handleClick}>{label}</label>
          </>
        )}
      </div>
      {highlightErrors ? (
        (!value && !optional) ||
        (value && value.length > 0 && value.length < minLength) ? (
          <span className={s.ErrorMessage} style={{ padding: 5 }}>
            {t(`blurErrors.Required`)}
          </span>
        ) : error ? (
          <span className={s.ErrorMessage} style={{ padding: 5 }}>
            {errorMessage}
          </span>
        ) : blurError ? (
          <span className={s.ErrorMessage}>{t(`blurErrors.${blurError}`)}</span>
        ) : null
      ) : null}
    </div>
  );
};
export default withConsumer(TextInput);
