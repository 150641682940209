import countriesEN from "../helpers/countries/countries-en.json";
import countriesFullEN from "../helpers/countries/countries-full-en.json";

const questionsEN = [
  {
    question_id: "0",
    question: "Do you have a promo code?",
    question_step: [1, 1],
    question_form: [
      {
        type: "TextInput",
        id: "promo",
        label: "Promo code",
        maxLength: 30,
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "1",
    question: "What is your name?",
    question_step: [1, 1],
    question_form: [
      {
        type: "Label",
        value: "Title",
        id: "s1q0"
      },
      {
        type: "Radio",
        id: "s1q1",
        label: "sex",
        options: [
          {
            label: "Ms",
            value: "mrs",
            size: "half",
            name: "s1q1",
            id: "s1q11"
          },
          {
            label: "Mr",
            value: "mr",
            size: "half",
            name: "s1q1",
            id: "s1q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "first_name",
        formatting: "name",
        label: "First name (according to ID)",
        maxLength: 30,
        value: ""
      },
      {
        type: "TextInput",
        id: "last_name",
        formatting: "name",
        label: "Last name (according to ID)",
        maxLength: 30,
        value: ""
      }
    ]
  },
  {
    question_id: "2",
    question: "Where do you live?",
    question_step: [2, 1],
    question_form: [
      {
        type: "Select",
        label: "Select country ...",
        id: "s2q1",
        options: countriesEN
      },
      {
        type: "Row",
        id: "s2r1",
        items: [
          {
            type: "TextInput",
            id: "s2q2",
            label: "Street",
            maxLength: 30,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s2q3",
            formatting: "nonZero",
            label: "No.",
            maxLength: 4,
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s2r2",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s2q4",
            label: "Postcode",
            formatting: "Number",
            maxLength: 6,
            errorMessage: "Please enter a valid postal code.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s2q5",
            label: "City",
            validation: "city",
            maxLength: 25,
            value: "",
            size: "twoThirds"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Please enter a valid date.",
        id: "s2q6",
        label: "Resident at this address since:",
        labels: "DD/MM/YYYY",
        required: true
      }
    ]
  },
  {
    question_id: "3",
    question: "What are your circumstances?",
    question_step: [3, 1],
    question_form: [
      {
        type: "Select",
        label: "Select nationality ...",
        id: "s3q1",
        options: countriesFullEN
      },
      {
        type: "Select",
        label: "Type of residence permit",
        id: "s3q4",
        options: [
          {
            label: "B - Annual",
            value: "B - Jahresaufenthalter"
          },
          {
            label: "C - Permanent",
            value: "C - Niederlassungsbewilligung"
          },
          {
            label: "G - Cross-border commuter",
            value: "G - Grenzgänger"
          },
          {
            label: "L - Short-term",
            value: "L - Kurzaufenthaltsbewilligung"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Please enter a valid date.",
        id: "s3q5",
        label: "Swiss residence permit held since",
        labels: "DD/MM/YYYY"
      },
      {
        type: "Select",
        id: "s3q2",
        label: "Select your civil status ...",
        options: [
          {
            label: "Single",
            value: "single"
          },
          {
            label: "Married",
            value: "married"
          },
          {
            label: "Divorced",
            value: "divorced"
          },
          {
            label: "Widowed",
            value: "widowed"
          },
          {
            label: "Other",
            value: "other"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Please enter a valid date.",
        validation: "Birthdate",
        underAgeMessage: "You must be at least 18 years old",
        id: "s3q3",
        label: "Date of birth",
        labels: "DD/MM/YYYY",
        required: true
      }
    ]
  },
  {
    question_id: "4",
    question: "How can we contact you?",
    question_step: [4, 1],
    question_form: [
      {
        type: "TextInput",
        id: "s4q1",
        label: "E-Mail",
        errorMessage:
          "Please enter a valid e-mail address in the form name@gmail.com and do not use any special characters.",
        validation: "Email",
        value: ""
      },
      {
        inputType: "tel",
        type: "Cellphone",
        id: "s4q2",
        label: "Phone (mobile)",
        maxLength: 15,
        minLength: 6,
        formatting: "Number",
        errorMessage: "Please type a valid cellphone number.",
        validation: "Cellphone",
        value: ""
      },
      {
        inputType: "tel",
        type: "Cellphone",
        id: "s4q3",
        label: "Phone (home) (optional)",
        errorMessage: "Please type a valid cellphone number.",
        validation: "Cellphone",
        formatting: "Number",
        maxLength: 15,
        minLength: 6,
        value: "",
        required: false,
        optional: true
      },
      {
        type: "Select",
        id: "s4q4",
        label: "Select language of correspondence ...",
        options: [
          {
            label: "German",
            value: "de-CH"
          },
          {
            label: "Italian",
            value: "it-CH"
          },
          {
            label: "French",
            value: "fr-CH"
          }
        ]
      }
    ]
  },
  {
    question_id: "5",
    question: "What are your living arrangements?",
    question_step: [5, 2],
    question_form: [
      {
        type: "ProminentParagraph",
        value:
          "In order to process your application immediately, we need further information from you."
      },
      {
        type: "Radio",
        id: "s5q1",
        options: [
          {
            label: "Renting",
            value: "tenant",
            size: "half",
            name: "s5q1",
            id: "s5q11"
          },
          {
            label: "Own home",
            value: "owner",
            size: "half",
            name: "s5q1",
            id: "s5q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s5q2",
        formatting: "Money",
        label: "Monthly housing costs in CHF",
        value: "",
        maxLength: 6,
        isNumberField: true
      }
    ]
  },
  {
    question_id: "6",
    question: "Monthly housing costs in CHF?",
    question_step: [6, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s6q1",
        label: "Gross income per year in CHF",
        value: "",
        formatting: "Money",
        required: true,
        isNumberField: true
      },
      {
        type: "Paragraph",
        value: "How do you earn your main income?"
      },
      {
        type: "Radio",
        id: "s6q2",
        options: [
          {
            label: "Employed",
            value: "employed",
            size: "half",
            name: "s6q2",
            id: "s6q21"
          },
          {
            label: "Self-employed",
            value: "self_employed",
            size: "half",
            name: "s6q2",
            id: "s6q22"
          },
          {
            label: "Retired",
            value: "retired",
            size: "half",
            name: "s6q2",
            id: "s6q23"
          },
          {
            label: "Student",
            value: "student",
            size: "half",
            name: "s6q2",
            id: "s6q24"
          },
          {
            label: "Apprentice",
            value: "apprentice",
            size: "half",
            name: "s6q2",
            id: "s6q25"
          },
          {
            label: "Unemployed",
            value: "unemployed",
            size: "half",
            name: "s6q2",
            id: "s6q26"
          },
          {
            label: "Other",
            value: "other",
            size: "half",
            name: "s6q2",
            id: "s6q27"
          }
        ]
      },
      {
        type: "Label",
        value: "Bank account information"
      },
      {
        type: "Checkbox",
        id: "s6q3",
        label: "I have a bank account in Switzerland or Liechtenstein",
        size: "full",
        name: "s6q3",
        value: false
      },
      {
        type: "TextInput",
        id: "s6q4",
        label: "IBAN (optional)",
        errorMessage: "Invalid IBAN for Switzerland",
        validation: "IBAN",
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "7",
    question: "What work do you do?",
    question_step: [7, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s7q1",
        label: "Occupation",
        value: ""
      },
      {
        type: "TextInput",
        id: "s7q2",
        label: "Employer",
        value: "",
        maxLength: 30
      },
      {
        type: "Row",
        id: "s7r1",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s7q3",
            label: "Postcode",
            formatting: "Number",
            errorMessage: "Please enter a valid postal code.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s7q4",
            label: "City",
            value: "",
            size: "twoThirds",
            validation: "city"
          }
        ]
      }
    ]
  },
  {
    question_id: "8",
    question: "Who is the beneficial owner?",
    question_step: [8, 3],
    question_form: [
      {
        type: "Paragraph",
        value:
          "As the applicant for the credit card and/or as the cardholder, I declare that the money that will be used to settle the credit card bills and/or paid to the credit card issuer in excess of such amount"
      },
      {
        type: "Radio",
        id: "s8q1",
        label: "Who is the beneficial owner",
        options: [
          {
            label: "... belongs solely to me.",
            value: "ausschliesslich mir gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q11",
            code: "s8q11"
          },
          {
            label: "... belongs to the person(s) named below.",
            value: "der/den nachfolgend aufgeführten  Person/en gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q12",
            code: "s8q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s8q2",
        label: "First name:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3",
        label: "Last name:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Select country ...",
        id: "s8q4",
        options: countriesEN
      },
      {
        type: "Row",
        id: "s8r1",
        items: [
          {
            type: "TextInput",
            id: "s8q5",
            label: "Street",
            maxLength: 30,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s8q6",
            label: "No.",
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2",
        items: [
          {
            type: "TextInput",
            id: "s8q7",
            label: "Postcode",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Invalid Zip code"
          },
          {
            type: "TextInput",
            id: "s8q8",
            label: "City",
            value: "",
            size: "twoThirds",
            validation: "city"
          }
        ]
      },
      {
        type: "Select",
        label: "Select nationality ...",
        id: "s8q9",
        options: countriesFullEN
      },
      {
        type: "Calendar",
        id: "s8q10",
        label: "Date of birth",
        labels: "DD/MM/YYYY",
        validation: "Birthdate",
        underAgeMessage: "You must be at least 18 years old"
      },
      {
        type: "Checkbox",
        id: "s8q1b",
        label: "Add another person",
        size: "full",
        name: "s8q1b",
        value: false,
        required: false
      },
      {
        type: "TextInput",
        id: "s8q2b",
        label: "First name:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3b",
        label: "Last name:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Select country ...",
        id: "s8q4b",
        options: countriesEN
      },
      {
        type: "Row",
        id: "s8r1b",
        items: [
          {
            type: "TextInput",
            id: "s8q5b",
            label: "Street",
            maxLength: 30,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s8q6b",
            label: "No.",
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2b",
        items: [
          {
            type: "TextInput",
            id: "s8q7b",
            label: "Postcode",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Invalid Zip code"
          },
          {
            type: "TextInput",
            id: "s8q8b",
            label: "City",
            value: "",
            size: "twoThirds",
            validation: "city"
          }
        ]
      },
      {
        type: "Select",
        label: "Select nationality ...",
        id: "s8q9b",
        options: countriesFullEN
      },
      {
        type: "Calendar",
        id: "s8q10b",
        label: "Date of birth",
        labels: "DD/MM/YYYY",
        validation: "Birthdate",
        underAgeMessage: "You must be at least 18 years old"
      },
      // {
      //   type: "Checkbox",
      //   id: "s8q1c",
      //   label: "Add another person",
      //   size: "full",
      //   name: "s8q1c",
      //   value: false,
      //   required: false
      // },
      {
        type: "TextInput",
        id: "s8q2c",
        label: "First name:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3c",
        label: "Last name:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Select country ...",
        id: "s8q4c",
        options: countriesEN
      },
      {
        type: "Row",
        id: "s8r1c",
        items: [
          {
            type: "TextInput",
            id: "s8q5c",
            label: "Street",
            maxLength: 30,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s8q6c",
            label: "No.",
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2c",
        items: [
          {
            type: "TextInput",
            id: "s8q7c",
            label: "Postcode",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Invalid Zip code"
          },
          {
            type: "TextInput",
            id: "s8q8c",
            label: "City",
            value: "",
            size: "twoThirds",
            validation: "city"
          }
        ]
      },
      {
        type: "Select",
        label: "Select nationality ...",
        id: "s8q9c",
        options: countriesFullEN
      },
      {
        type: "Calendar",
        id: "s8q10c",
        label: "Date of birth",
        labels: "DD/MM/YYYY",
        validation: "Birthdate",
        underAgeMessage: "You must be at least 18 years old"
      }
    ]
  },
  {
    question_id: "9",
    question: "Cover for payment obligations?",
    question_step: [9, 3],
    question_form: [
      {
        type: "InfoParagraphWithList",
        value:
          "For just 0.5% of your bill amount, we've got you covered. We will take over the outstanding balance",
        list: [
          "In case of unemployment through no fault of one's own",
          "In case of incapacity to work due to accident"
        ],
        info: "1",
        title1: "BALANCE PROTECT",
        title2: "Because it can happen to anyone",
        title3: "Full balance takeover*:",
        title4: "FAQ Balance PROTECT",
        listItem1:
          "Keep your finances under control - with Balance PROTECT insurance:",
        listItem2:
          "The reasons for a financial emergency are varied ; and it often arises quite unexpectedly.",
        listItem3: "in case of involuntary unemployment",
        listItem4:
          "in case of incapacity for work due to an accident or illness",
        listItem5: "in case of death due to an accident or illness",
        listItem6:
          "Please do not hesitate to contact us if you have any questions",
        listItem7: "Phone: +41 44 805 58 58",
        listItem8: "E-mail: manor@viseca.ch",
        listItem9: "What is insured?",
        listItem10:
          "The total outstanding balance of the card account is insured. In the event of a claim, a one-time payment will be made to your card account (balance cancellation).",
        listItem11: "Who is insured?",
        listItem12: "The main cardholder is insured.",
        listItem13: "What are the prerequisites for insurance protection?",
        listItem14:
          "The membership conditions and general insurance conditions must be met.",
        listItem15: "How high are the sums insured?",
        listItem16:
          "The maximum insured amount is CHF 10,000 per claim and a total of CHF 30,000 per card contract.",
        listItem17: "What must I do in the event of a claim?",
        listItem18:
          "Report your claim without delay to the service provider commissioned by Helvetia",
        para1:
          "Involuntary unemployment in economically difficult times or prolonged incapacity for work due to an accident or illness ; this can happen to anyone. Balance PROTECT will help you by taking over your entire outstanding balance. And for a premium of just 0.5% of your outstanding balance.",
        para2:
          "Example: If your Manor Mastercard bill is CHF 150, you will pay a premium of just 75 cents.",
        para3: "Financial & Employee Benefits Services (febs) AG",
        para4: "P.O. Box 1763, 8401 Winterthur",
        para5: "Phone: +41 (0)52 266 02 92, Fax: +41 (0)52 266 02 01",
        para6: "E-mail: viseca@febs.ch",
        para7:
          "* Cover is based on the General Insurance Conditions (GIC) of the Balance PROTECT insurance "
      },
      {
        type: "Table",
        value: "Sample premium calculation",
        list: [
          ["Balance carried forward (last bill)", "CHF 50.-"],
          ["Your new purchases", "CHF 100.-"],
          ["Outstanding balance", "CHF 150.-"],
          ["Premium 0.5% of CHF 150.-", "= 75 cents"]
        ],
        info: "2"
      },
      {
        type: "Special"
      },
      {
        type: "Radio",
        id: "s9q1",
        label: "Cover for payment obligations",
        options: [
          {
            label: "Yes, I would like cover for my payment obligations",
            value: "ja",
            size: "full",
            name: "s9q1",
            id: "s9q11"
          },
          {
            label: "No, I do not want cover",
            value: "nein",
            size: "full",
            name: "s9q1",
            id: "s9q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "10",
    question: "Insurance for card loss or defect?",
    question_step: [10, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Protect yourself against replacement costs in case of loss or defect with Card PROTECT from Basler Versicherung.",
        info: "3",
        title1: "CARD PROTECT",
        title2: "FAQs for Card PROTECT",
        listItem1: "No card replacement costs",
        listItem2: "No PIN replacement costs",
        listItem3: "Price: Just CHF 6.90 per year",
        listItem4: "What is Card PROTECT insurance?",
        listItem5:
          "Card PROTECT insurance will cover the card replacement costs in the event that your Manor World Mastercard is stolen, lost or becomes defective. The costs for a replacement PIN are also covered.",
        listItem6: "Who is covered by the insurance protection?",
        listItem7:
          "VPrimary and any additional Manor World Mastercard cards are insured.",
        listItem8: "How high is the premium?",
        listItem9:
          "The premium is CHF 6.90 and will be charged to your credit card annually",
        listItem10: "What services are insured?",
        listItem11:
          "All the costs associated with replacing lost, stolen or defective Manor World Mastercard cards and/or PINs are insured.",
        listItem12: "What must I do in the event of a claim?",
        listItem13:
          "In the event of loss or theft, please report this immediately to Viseca's 24hr cancellation centre by calling +41 (0)58 958 83 83.",
        listItem14:
          "If you lose your PIN or your card becomes defective, report this to Customer Service on +41 (0) 44 805 58 58.",
        listItem15: "When will the insurance end?",
        listItem16:
          "The insurance will be taken out for an indefinite period and will be renewed automatically for another year if it is not cancelled by writing to Viseca no later than one month before the end of the insurance year.",
        para1: "Reliable protection for loss of card and PIN",
        para2:
          "Protect yourself against the replacement costs in case of loss or defect with Card PROTECT insurance from Viseca.",
        para3: "Your benefits:"
      },
      {
        type: "ParagraphWithList",
        value: "Your benefits:",
        list: [
          "no additional card replacement costs",
          "no additional PIN replacement costs",
          "primary card and any additional cards are insured"
        ]
      },
      {
        type: "Paragraph",
        value: "Price: Just CHF6.90 per year"
      },
      {
        type: "Radio",
        id: "s10q1",
        label: "Protection for loss of card or PIN",
        options: [
          {
            label:
              "Yes, I would like protection against any fees for the loss of my card or PIN",
            value: "ja",
            size: "full",
            name: "s10q1",
            id: "s10q11"
          },
          {
            label: "No, I do not want cover",
            value: "nein",
            size: "full",
            name: "s10q1",
            id: "s10q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "11",
    question: "Copy of your ID",
    question_step: [11, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "To enable us to draw up a contract for you, we need a copy of your ID. Please upload a photo of your ID here.",
        info: "4"
      },
      {
        type: "ImageUpload",
        id: "s11q1",
        name: "s11q1",
        labelSwiss: "ID card front side",
        labelOther: "Foreigner's ID card front side",
        size: "full"
      },
      {
        type: "ImageUpload",
        id: "s11q2",
        name: "s11q2",
        labelSwiss: "ID card back side",
        labelOther: "Foreigner's ID card back side",
        size: "full"
      }
    ]
  },
  {
    question_id: "12",
    question: "Summary",
    question_step: [12, 4],
    question_form: [
      {
        type: "ProminentParagraph",
        value: "Please check your information carefully."
      },
      {
        type: "Summary",
        value: "Your personal information",
        steps: [1, 3],
        desktop_steps: [1],
        heading: 1,
        list: [
          ["Title:", "s1q1"],
          ["First name:", "first_name"],
          ["Last name:", "last_name"],
          ["Date of birth:", "s3q3"],
          ["Civil status:", "s3q2"],
          ["Nationality:", "s3q1"],
          ["Residence permit:", "s3q4"],
          ["Swiss permit held since:", "s3q5"]
        ]
      },
      {
        type: "Summary",
        value: "Your living arrangements",
        steps: [2, 5],
        desktop_steps: [1],
        heading: 2,
        list: [
          ["Country", "s2q1"],
          ["Street", "s2q2"],
          ["No.:", "s2q3"],
          ["Postcode", "s2q4"],
          ["City:", "s2q5"],
          ["Type of residence", "s5q1"],
          ["Housing costs", "s5q2"]
        ]
      },
      {
        type: "Summary",
        value: "Your contact details",
        steps: [4],
        desktop_steps: [1],
        heading: 4,
        list: [
          ["E-mail address:", "s4q1"],
          ["Phone (mobile):", "s4q2"],
          ["Private phone (mobile):", "s4q3"],
          ["Language of correspondence:", "s4q4"]
        ]
      },
      {
        type: "Summary",
        value: "Your financial situation",
        desktop_steps: [2],
        heading: 6,
        steps: [6, 7],
        list: [
          ["Gross income/year:", "s6q1"],
          ["Gross income/year:", "s6q2"],
          ["Occupation:", "s7q1"],
          ["Occupation:", "s7q2"],
          ["Postcode:", "s7q3"],
          ["City:", "s7q4"],
          ["CH/LI bank account:", "s6q3"],
          ["IBAN:", "s6q4"]
        ]
      },
      {
        type: "Summary",
        value: "Beneficial ownership",
        steps: [8],
        desktop_steps: [3],
        heading: 8,
        list: [
          ["Beneficial owner:", "s8q1"],
          ["Person 1", "person-title-1", "RowTitle"],
          ["First name:", "s8q2"],
          ["Last name:", "s8q3"],
          ["Date of birth:", "s8q10"],
          ["Nationality:", "s8q9"],
          ["Country:", "s8q4"],
          ["Street:", "s8q5"],
          ["No:", "s8q6"],
          ["Postcode:", "s8q7"],
          ["City:", "s8q8"],
          ["Person 2", "person-title-2", "RowTitle"],
          ["First name:", "s8q2b"],
          ["Last name:", "s8q3b"],
          ["Date of birth:", "s8q10b"],
          ["Nationality:", "s8q9b"],
          ["Country:", "s8q4b"],
          ["Street:", "s8q5b"],
          ["No:", "s8q6b"],
          ["Postcode:", "s8q7b"],
          ["City:", "s8q8b"],
          ["First name:", "s8q2c"],
          ["Last name:", "s8q3c"],
          ["Date of birth:", "s8q10c"],
          ["Nationality:", "s8q9c"],
          ["Country:", "s8q4c"],
          ["Street:", "s8q5c"],
          ["No:", "s8q6c"],
          ["Postcode:", "s8q7c"],
          ["City:", "s8q8c"]
        ]
      },
      {
        type: "Summary",
        value: "Additional services",
        steps: [9, 10],
        desktop_steps: [3],
        heading: 9,
        list: [
          ["Cover for payment obligations", "s9q1"],
          ["Protection for loss of card or PIN", "s10q1"]
        ]
      },
      {
        type: "Summary",
        value: "Copy of ID",
        desktop_steps: [3],
        steps: [11],
        heading: 11,
        list: [
          ["Front side", "s11q1"],
          ["Back side", "s11q2"]
        ]
      }
    ]
  },
  {
    question_id: "14",
    question: "Declaration of consent",
    question_step: [14, 4],
    question_form: [
      {
        type: "CollapsedParagraph",
        value:
          "The applicant hereby confirms the accuracy of the above information and authorizes Viseca Card Services SA to obtain all the information required to check the above information, to process the electronically transmitted card application and to conclude the contract, to process the electronically transmitted card application and to conclude the contract from third parties, in particular the Central Office for Credit Information (ZEK), to the authorities (e.g. (e.g. debt enforcement and tax offices, residents' registration offices), credit agencies, the employer, other Aduno Group companies (www.aduno-gruppe.ch) or other information and disclosure offices provided for by law (e.g. Consumer Credit Information Office, IKO) or other suitable information and disclosure offices, and to report to the ZEK in the event of card blocking, serious payment arrears or misuse of the card and comparable circumstances, and to the competent offices in the cases provided for by law. In this respect the undersigned releases these offices from banking, business and official secrecy. Viseca Card Services SA is entitled to reject this card application without giving reasons.",
        more: "more"
      },
      {
        type: "CollapsedParagraph",
        value:
          "The applicant agrees that Viseca Card Services SA may contact him/her by e-mail or telephone (or SMS / App). Viseca Card Services SA points out that, in particular, communication by e-mail is neither secure nor confidential due to the open configuration of the internet. It is therefore possible, despite all the security measures taken by Viseca Card Services SA, that third parties may gain unauthorized access to communications between the applicant and Viseca Card Services SA.",
        more: "more"
      },
      {
        type: "Checkbox",
        required: true,
        id: "s14q1",
        label: "I agree with the declaration.",
        size: "full",
        name: "s14q1",
        value: false
      },
      {
        type: "Paragraph",
        value:
          "Viseca Card Services SA, Hagenholzstrasse 56, 8050 Zurich, Phone +41 44 805 58 58"
      }
    ]
  }
];

export default questionsEN;
