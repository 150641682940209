import React from 'react';
import ShowMoreText from 'react-show-more-text';

import s from './CollapsedParagraph.module.scss';

const CollapsedParagraph = ({ value, more }) => {

  return (
    <div className={s.Container}> 
    <ShowMoreText
    /* Default options */
    className={s.Container}
    lines={3}
    more={more}
    less=''
    anchorClass={s.More}
    expanded={false}
    width={0}
    >
      {value}
    </ShowMoreText>
    </div>
  )
};

export default CollapsedParagraph;
