import React, { useState } from "react";
import { withConsumer } from "../../appContext";
import { Info } from "../Icon";
import Overlay from "../Overlay/Overlay";
import { isDesktop } from "../../helpers/render";

import s from "./ProminentParagraph.module.scss";

const ProminentParagraph = ({
  id,
  children,
  currentStep,
  windowWidth,
  answers,
  desktopColor = true,
  smallerP
}) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setDisplayOverlay(true);
  };

  const checkImgValues = () => answers["s11q1"].value || answers["s11q2"].value;

  const checkImgUpload = () => {
    if (answers["s11q1"].value && answers["s11q2"].value) return 370;
    if (answers["s11q1"].value || answers["s11q2"].value) return 280;
    else return 195;
  };

  return (
    <div
      className={
        currentStep > 0 && desktopColor && isDesktop(windowWidth)
          ? `${s.Container} ${s.DesktopColors}`
          : s.Container
      }
      key={id}
    >
      <p className={`${s.Text} ${smallerP ? s.SmallerP : ""}`}>{children}</p>
      {currentStep === 11 ? (
        <span
          onClick={handleClick}
          className={s.Info}
          style={{ top: checkImgUpload() }}
        >
          <Info className={s.Info} />
        </span>
      ) : null}
    </div>
  );
};

export default withConsumer(ProminentParagraph);
