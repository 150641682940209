export const links = [
  {
    label: {
      DE: "Alle Detailinformationen zur Karte",
      IT: "Tutte le informazioni dettagliate sulla carta",
      FR: "Toutes les informations détaillées sur la carte de crédit"
    },
    link: {
      DE: "https://www.manor.ch/de/u/mastercard",
      FR: "https://www.manor.ch/fr/u/mastercard",
      IT: "https://www.manor.ch/it/u/mastercard"
    }
  },
  {
    label: {
      DE: "Nutzungsbestimmungen",
      IT: "Condizioni di utilizzo",
      FR: "Conditions d'utilisation"
    },
    link: {
      DE:
        "https://card-specials.ch/wp-content/uploads/2020/07/DS_Nutzung_Website_VIS_MC_Manor_web_DE_2001.pdf",
      FR:
        "https://card-specials.ch/wp-content/uploads/2020/07/DS_Nutzung_Website_VIS_MC_Manor_web_FR_2001.pdf",
      IT:
        "https://card-specials.ch/wp-content/uploads/2020/07/DS_Nutzung_Website_VIS_MC_Manor_web_IT_2001.pdf"
    }
  },
  {
    label: {
      DE: "Datenschutz",
      IT: "Protezione dei dati",
      FR: "Protection des données"
    },
    link: {
      DE:
        "https://card-specials.ch/wp-content/uploads/2020/05/DS_VIS_MC_Manor_web_DE_2005.pdf",
      FR:
        "https://card-specials.ch/wp-content/uploads/2020/05/DS_VIS_MC_Manor_web_FR_2005-1.pdf  ",
      IT:
        "https://card-specials.ch/wp-content/uploads/2020/05/DS_VIS_MC_Manor_web_IT_2005.pdf"
    }
  },
  {
    label: {
      DE: "AGB",
      IT: "CG",
      FR: "CG"
    },
    link: {
      DE:
        "https://card-specials.ch/wp-content/uploads/2020/07/AGB_VIS_MC_Manor_DE_2007.pdf",
      FR:
        "https://card-specials.ch/wp-content/uploads/2020/07/AGB_VIS_MC_Manor_FR_2007.pdf",
      IT:
        "https://card-specials.ch/wp-content/uploads/2020/07/AGB_VIS_MC_Manor_IT_2007.pdf"
    }
  }
];

export const prefixOptions = [
  {
    label: "0041",
    value: "0041",
    min: 7,
    max: 10
  },
  {
    label: "0049",
    value: "0049",
    min: 7,
    max: 10
  },
  {
    label: "0033",
    value: "0033",
    min: 7,
    max: 10
  },
  {
    label: "0039",
    value: "0039",
    min: 7,
    max: 10
  },
  {
    label: "0043",
    value: "0043",
    min: 7,
    max: 10
  },
  {
    label: "00423",
    value: "00423",
    min: 7,
    max: 9
  }
];
