import superagent from "superagent";
import * as Sentry from "@sentry/react";
import { getAge } from "./validations";
import md5 from "md5";

export const FIELDS_ERROR = "fields error";
export const NETWORK_ERROR = "network error";
export const OK_SUBMITTED = "OKSubmitted";

export const sendApi = async (answers, isRetry = false) => {
  const prodEnv = {
    url: "https://manor-onboarding.viseca.ch/api/public/users",
    apiKey:
      "5a606a4712819fd0b4faa95d6cec5028799cfefd3e4d8a7e4cc9a12500d57f317a4218706eb418d29d2e8785e251a054456ff4e85f2b179c19363f658231a674"
  };
  const devEnv = {
    url: "https://manor-onboarding.integ.viseca.ch/api/public/users",
    apiKey:
      "e5ee2b2d34526bb44a453a5ab967c7f03000a25baaee8c659c7cb441eb66d389311ca0e70e772de63caeceb214060ac798af01c99aa22480136ee150ace66be3"
  };
  const debugEnv = {
    url: "http://localhost:4000/api/public/users",
    apiKey: "API_KEY_HERE"
  };
  let env =
    window.location.href.includes("integ") ||
    window.location.href.includes("localhost") ||
    window.location.href.includes("blueglass")
      ? devEnv
      : prodEnv;

  const val = (str) => {
    return answers[str]?.value ?? "";
  };
  const mobile = (id) => {
    const fullNumber = val(id);
    if (!fullNumber || !fullNumber.includes("-")) return null;
    if (!fullNumber.split("-")[1] || !fullNumber.split("-")[1].length)
      return null;

    return fullNumber.includes("-0")
      ? fullNumber.replace("-0", "")
      : fullNumber.replace("-", "");
  };
  const abbr = (str) => answers[str].abbreviation;
  const date = (str) => {
    if (!val(str)) return null;
    const [day, month, year] = val(str).split("/");
    return `${year}-${month}-${day}`;
  };
  const money = (str) => {
    return +val(str).replace(/\D/g, "");
  };
  const insurance =
    (answers["s2q1"].abbreviation &&
      answers["s2q1"].abbreviation.toUpperCase() !== "CH") ||
    getAge(answers["s3q3"].value) > 64
      ? null
      : val("s9q1") === "ja";

  let beneficial_owners = [
    {
      first_name: val("s8q2"),
      last_name: val("s8q3"),
      address: val("s8q5"),
      street_number: val("s8q6"),
      zip_code: val("s8q7"),
      country_iso: ["fl", "ch", "li"].includes(abbr("s8q4")?.toLowerCase())
        ? "CH"
        : abbr("s8q4"),
      nationality_iso: val("s8q9").toUpperCase(),
      city: val("s8q8"),
      birthdate: date("s8q10")
    }
  ];
  if (val("s8q1b")) {
    beneficial_owners.push({
      first_name: val("s8q2b"),
      last_name: val("s8q3b"),
      address: val("s8q5b"),
      street_number: val("s8q6b"),
      zip_code: val("s8q7b"),
      country_iso: ["fl", "ch", "li"].includes(abbr("s8q4b")?.toLowerCase())
        ? "CH"
        : abbr("s8q4b"),
      nationality_iso: val("s8q9b").toUpperCase(),
      city: val("s8q8b"),
      birthdate: date("s8q10b")
    });
  }
  if (val("s8q1c")) {
    beneficial_owners.push({
      first_name: val("s8q2c"),
      last_name: val("s8q3c"),
      address: val("s8q5c"),
      street_number: val("s8q6c"),
      zip_code: val("s8q7c"),
      country_iso: ["fl", "ch", "li"].includes(abbr("s8q4c")?.toLowerCase())
        ? "CH"
        : abbr("s8q4c"),
      nationality_iso: val("s8q9c").toUpperCase(),
      city: val("s8q8c"),
      birthdate: date("s8q10c")
    });
  }

  const user = {
    email: val("s4q1"),
    accept_terms: true,
    first_name: answers.first_name.value,
    last_name: answers.last_name.value,
    birthdate: date("s3q3"),
    language: val("s4q4"),
    salutation: val("s1q1"),
    country_iso: ["fl", "ch", "li"].includes(abbr("s2q1")?.toLowerCase())
      ? "CH"
      : abbr("s2q1"),
    zip_code: val("s2q4"),
    city: val("s2q5"),
    address: val("s2q2"),
    street_number: val("s2q3"),
    address_since_at: date("s2q6"),
    marital_status: val("s3q2"),
    nationality_type:
      val("s3q1").toLowerCase() === "ch" || val("s3q1").toLowerCase() === "li"
        ? "swiss"
        : "other",
    nationality_iso:
      val("s3q1").toUpperCase() === "LI" ? "CH" : val("s3q1").toUpperCase(),
    // take first letter of input like 'C' or 'G'
    permit_type: val("s3q4") ? val("s3q4")[0] : null,
    employment_status: val("s6q2"),
    occupation: val("s7q1"),
    employer: val("s7q2"),
    employer_zip_code: val("s7q3"),
    in_switzerland_since: date("s3q5"),
    employer_city: val("s7q4"),
    mobile_number: mobile("s4q2"),
    phone_number: mobile("s4q3"),
    yearly_gross_income: money("s6q1"),
    living_costs: money("s5q2"),
    residential_status: val("s5q1"),
    swiss_bank_account: val("s6q3"),
    iban: val("s6q4") || null,
    acquirer_code: val("promo") || null,
    card_protect_insurance_accepted: val("s10q1") === "ja",
    saldo_insurance_accepted: insurance,
    beneficial_owner_type:
      val("s8q1") === "ausschliesslich mir gehören." ? "card_holder" : "other",
    beneficial_owners: beneficial_owners,
    checkout: "self",
    id_scan_front_base64: val("s11q1").replace(/^.*;base64,/, ""),
    id_scan_back_base64: val("s11q2").replace(/^.*;base64,/, ""),
    external_reference_code: md5(answers.first_name.value+val("s4q1")+val("s2q4")+(new Date()).getFullYear()) // first_name+email+plz+current_year
  };

  return superagent
    .post(env.url)
    .timeout(60000)
    .set("Api-Key", env.apiKey)
    .set("Content-Type", "application/json")
    .query(isRetry ? { retry: "1" } : {})
    .send({ user })
    .retry(1, (err, res) => {
      // if there is no error
      if(!err) return false;
      // If it's a validation error, don't retry
      if (res?.body?.errors) {
        return false;
      }
      logEvent(
        "API Response: " + err?.message,
        user.email,
        user.zip_code,
        JSON.stringify({ user }).length,
        true,
        {
          contentLength: JSON.stringify({ user }).length,
          userAgent:
            typeof window !== "undefined" ? window.navigator.userAgent : "",
          error: err
        }
      );
      logEvent(
        "Auto-Retry to Submit ",
        user.email,
        user.zip_code,
        JSON.stringify({ user }).length,
        false,
        err
      );
      return true;
    })
    .catch((err) => {
      console.log("🚀 ~ file: api.js ~ line 179 ~ sendApi ~ err", err);
      logEvent(
        "API Response: " + err.message,
        user.email,
        user.zip_code,
        JSON.stringify({ user }).length,
        true,
        {
          contentLength: JSON.stringify({ user }).length,
          userAgent:
            typeof window !== "undefined" ? window.navigator.userAgent : "",
          error: err
        }
      );
      Sentry.captureException(err, { extra: err && err.response });
      if (err?.status === 409) { // repeated request
        return true;
      } else if (err?.response?.body?.errors) {
        const { pointer, detail } = err.response.body.errors[0];
        throw new Error(`${pointer} ${detail}`);
      } else {
        throw new Error(NETWORK_ERROR);
      }
    });
};

export const logEvent = (
  eventName,
  email,
  zipcode,
  payloadSize = null,
  isError = false,
  payload = null
) => {
  console.log(
    "LOGGING EVENT ",
    `${new Date().getTime()} - ${btoa(email)} - ${zipcode} - ${eventName}`
  );
  const prefix =
    window.location.href.includes("integ") ||
    window.location.href.includes("localhost")
      ? "DEV - "
      : "";
  superagent
    .post("https://apppulse.cloud/log/")
    .query({
      property: "viseca",
      type: isError ? "error" : "info",
      id_1: btoa(email),
      id_2: zipcode,
      id_3: payloadSize ?? undefined,
      message: `${prefix}${eventName}`,
      payload,
      url: window?.location?.href
    })
    .end();
};
