export default class SwipeHandler {
  constructor() {
    this.startY = null;
  }
  register(onSwipeUp, onSwipeDown) {
    this.touchStart = window.addEventListener("touchstart", (e) => {
      this.startY = e.changedTouches[0].clientY;
    });
    this.touchMove = window.addEventListener("touchmove", (e) => {
      const y = e.changedTouches[0].clientY;
      if (
        this.startY &&
        y - this.startY > 15 &&
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight
      ) {
        this.startY = null;
        setTimeout(onSwipeUp);
      } else if (this.startY && this.startY - y > 15 && window.scrollY <= 0) {
        this.startY = null;
        setTimeout(onSwipeDown);
      }
    });
  }
  unregister() {
    window.removeEventListener("touchmove", this.touchMove);
    window.removeEventListener("touchstart", this.touchStart);
  }
}
