import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withConsumer } from "../../appContext";
import s from "./Header.module.scss";
import { LANGUAGES } from "../../data/config";

// import Button from "../Button";
import Logo from "../Logo";
import SelectInput from "../SelectInput";
import { withTranslation } from "react-i18next";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scroll: false,
      editorOpen: false
    };
  }

  onScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ scroll: true });
    } else {
      this.setState({ scroll: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  navigateHome = () => {
    const { updateStep, resetState, history,language } = this.props;
    resetState();
    history.push(`/${language?.toLowerCase()}`);
    updateStep(0);
  };

  render() {
    const { handleLanguageChange, currentStep, language, t } = this.props;
    const scrolled = this.state.scroll ? s.Scroll : "";
    const shadow = this.props.currentStep > 0 ? "" : s.Shadow;
    return (
      <>
        <header className={`${s.Container} ${scrolled} ${shadow}`}>
          <div className={s.Content}>
            <Logo onClick={this.navigateHome} />
            {this.props.currentStep > 0 ? (
              <button
                type='button'
                className={s.InfoButton}
                onClick={() => this.setState({ editorOpen: true })}
              >
                ?
              </button>
            ) : (
              <>
                <div
                  className={s.Impressum}
                  onClick={() => this.setState({ editorOpen: true })}
                >
                  <span>{t("legal")}</span>
                </div>
                {/* <section className={s.langCont}> */}
                <SelectInput
                  id='language'
                  options={LANGUAGES}
                  handleChange={handleLanguageChange}
                  value={language?.toUpperCase()}
                  size='auto'
                  headerInput
                />
                {/* </section> */}
              </>
            )}
          </div>
          <div className={`${s.Editor} ${this.state.editorOpen ? s.Open : ""}`}>
            <div className={s.Times}>
              <span onClick={() => this.setState({ editorOpen: false })}>
                &times;
              </span>
            </div>
            {currentStep > 0 ? (
              <>
                <h5>{t("home.contact.contact-us")}</h5>
                <table>
                  <tbody>
                    <tr>
                      <td>{t("home.contact.phone")}:</td>
                      <td>
                        <a href={"tel:+41448055858"}>+41 44 805 58 58</a>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("home.contact.email")}:</td>
                      <td>
                        <a href={"mailto:manor@viseca.ch"}>manor@viseca.ch</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <h5>{t("home.editor.title")}</h5>
                <p>{t("home.editor.line1")}</p>
                <p>{t("home.editor.line2")}</p>
                <p>{t("home.editor.line3")}</p>
              </>
            )}
          </div>
        </header>
        <div
          className={`${s.BackOverlay} ${
            this.state.editorOpen ? " " + s.Open : ""
          }`}
        />
      </>
    );
  }
}

export default withRouter(withConsumer(withTranslation()(Header)));
