import countriesIT from "../helpers/countries/countries-it.json";
import countriesFullIT from "../helpers/countries/countries-full-it.json";

const questionsIT = [
  {
    question_id: "0",
    question: "Avete un codice promozionale?",
    question_step: [1, 1],
    question_form: [
      {
        type: "TextInput",
        id: "promo",
        label: "Codice promozionale",
        maxLength: 30,
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "1",
    question: "Come si chiama?",
    question_step: [1, 1],
    question_form: [
      {
        type: "Label",
        value: "Appellativo",
        id: "s1q0"
      },
      {
        type: "Radio",
        id: "s1q1",
        label: "sex",
        options: [
          {
            label: "Signora",
            value: "mrs",
            size: "half",
            name: "s1q1",
            id: "s1q11"
          },
          {
            label: "Signor",
            value: "mr",
            size: "half",
            name: "s1q1",
            id: "s1q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "first_name",
        formatting: "name",
        label: "Nome (come da documento di legittimazione / carta d’identità)",
        maxLength: 30,
        value: ""
      },
      {
        type: "TextInput",
        id: "last_name",
        formatting: "name",
        label:
          "Cognome (come da documento di legittimazione / carta d’identità)",
        maxLength: 30,
        value: ""
      }
    ]
  },
  {
    question_id: "2",
    question: "Dove abita?",
    question_step: [2, 1],
    question_form: [
      {
        type: "Select",
        label: "Selezionare Paese...",
        id: "s2q1",
        options: countriesIT
      },
      {
        type: "Row",
        id: "s2r1",
        items: [
          {
            type: "TextInput",
            id: "s2q2",
            label: "Via",
            maxLength: 25,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s2q3",
            label: "N.",
            maxLength: 4,
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s2r2",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s2q4",
            label: "NPA",
            formatting: "Number",
            maxLength: 6,
            errorMessage: "Si prega di inserire un codice postale valido.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s2q5",
            label: "Località",
            validation: "city",
            maxLength: 25,
            value: "",
            size: "twoThirds",
            errorMessage: "Si prega di inserire una località corretta."
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Inserire una data valida.",
        id: "s2q6",
        label: "Residente a questo indirizzo dal:",
        labels: "GG/MM/AAAA",
        required: true
      }
    ]
  },
  {
    question_id: "3",
    question: "Informazioni personali?",
    question_step: [3, 1],
    question_form: [
      {
        type: "Select",
        label: "Selezionare nazionalità...",
        id: "s3q1",
        options: countriesFullIT
      },
      {
        type: "Select",
        label: "Carta di soggiorno tipo",
        id: "s3q4",
        options: [
          {
            label: "B - Dimorante annuale",
            value: "B - Dimorante annuale"
          },
          {
            label: "C - Permesso di domicilio",
            value: "C - Permesso di domicilio"
          },
          {
            label: "G - Frontaliere",
            value: "G - Frontaliere"
          },
          {
            label: "L - Dimorante temporaneo",
            value: "L - Dimorante temporaneo"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Inserire una data valida..",
        id: "s3q5",
        label: "Permesso di dimora CH dal",
        labels: "GG/MM/AAAA"
      },
      {
        type: "Select",
        id: "s3q2",
        label: "Selezionare stato civile...",
        options: [
          {
            label: "Celibe/nubile",
            value: "single"
          },
          {
            label: "Coniugato/a",
            value: "married"
          },
          {
            label: "Divorziato/a",
            value: "divorced"
          },
          {
            label: "Vedovo/a",
            value: "widowed"
          },
          {
            label: "Altro",
            value: "other"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Inserire una data valida..",
        validation: "Birthdate",
        underAgeMessage: "Devi avere almeno 18 anni",
        id: "s3q3",
        label: "Data di nascita",
        labels: "GG/MM/AAAA",
        required: true
      }
    ]
  },
  {
    question_id: "4",
    question: "Come possiamo contattarla?",
    question_step: [4, 1],
    question_form: [
      {
        type: "TextInput",
        id: "s4q1",
        label: "E-Mail",
        errorMessage:
          "Si prega di inserire un indirizzo e-mail valido nel modulo name@gmail.com e di non utilizzare caratteri speciali.",
        validation: "Email",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q2",
        label: "Cellulare",
        errorMessage: "Si prega di digitare un numero di cellulare valido.",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q3",
        label: "Telefono privato (facoltativo)",
        errorMessage: "Si prega di digitare un numero di cellulare valido.",
        value: "",
        required: false,
        optional: true
      },
      {
        type: "Select",
        id: "s4q4",
        label: "Selezionare lingua per la corrispondenza...",
        options: [
          {
            label: "Tedesco",
            value: "de-CH"
          },
          {
            label: "Italiano",
            value: "it-CH"
          },
          {
            label: "Francese",
            value: "fr-CH"
          }
        ]
      }
    ]
  },
  {
    question_id: "5",
    question: "Qual è la sua situazione abitativa?",
    question_step: [5, 2],
    question_form: [
      {
        type: "ProminentParagraph",
        value:
          "Per poter elaborare subito la sua richiesta, abbiamo bisogno di altre informazioni da parte sua."
      },
      {
        type: "Radio",
        id: "s5q1",
        options: [
          {
            label: "Locazione",
            value: "tenant",
            size: "half",
            name: "s5q1",
            id: "s5q11"
          },
          {
            label: "Proprietà abitativa",
            value: "owner",
            size: "half",
            name: "s5q1",
            id: "s5q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s5q2",
        formatting: "Money",
        label: "Spese mensili di abitazione in CHF",
        value: "",
        maxLength: 6,
        isNumberField: true
      }
    ]
  },
  {
    question_id: "6",
    question: "Com’è la sua situazione reddituale?",
    question_step: [6, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s6q1",
        label: "Reddito annuo lordo in CHF",
        value: "",
        formatting: "Money",
        required: true,
        isNumberField: true
      },
      {
        type: "Paragraph",
        value: "Come si guadagna il suo reddito primario?"
      },
      {
        type: "Radio",
        id: "s6q2",
        options: [
          {
            label: "Dipendente",
            value: "employed",
            size: "half",
            name: "s6q2",
            id: "s6q21"
          },
          {
            label: "Libero professionista",
            value: "self_employed",
            size: "half",
            name: "s6q2",
            id: "s6q22"
          },
          {
            label: "Beneficiario/a di rendita",
            value: "retired",
            size: "half",
            name: "s6q2",
            id: "s6q23"
          },
          {
            label: "Studente/ssa",
            value: "student",
            size: "half",
            name: "s6q2",
            id: "s6q24"
          },
          {
            label: "Apprendista",
            value: "apprentice",
            size: "half",
            name: "s6q2",
            id: "s6q25"
          },
          {
            label: "Senza attività lucrativa",
            value: "unemployed",
            size: "half",
            name: "s6q2",
            id: "s6q26"
          },
          {
            label: "Altro",
            value: "other",
            size: "half",
            name: "s6q2",
            id: "s6q27"
          }
        ]
      },
      {
        type: "Label",
        value: "Relazione bancaria"
      },
      {
        type: "Checkbox",
        id: "s6q3",
        label:
          "Ho un conto bancario in Svizzera o nel Principato del Liechtenstein",
        size: "full",
        name: "s6q3",
        value: false
      },
      {
        type: "TextInput",
        id: "s6q4",
        label: "IBAN (facoltativo)",
        errorMessage: "IBAN non valido per la Svizzera",
        validation: "IBAN",
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "7",
    question: "Che lavoro svolge?",
    question_step: [7, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s7q1",
        label: "Professione",
        value: "",
        maxLength: 30
      },
      {
        type: "TextInput",
        id: "s7q2",
        label: "Datore di lavoro",
        value: "",
        maxLength: 30
      },
      {
        type: "Row",
        id: "s7r1",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s7q3",
            label: "NPA",
            formatting: "Number",
            errorMessage: "Si prega di inserire un codice postale valido.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s7q4",
            label: "Località",
            value: "",
            size: "twoThirds",
            validation: "city",
            errorMessage: "Si prega di inserire una località corretta."
          }
        ]
      }
    ]
  },
  {
    question_id: "8",
    question: "Chi è l’avente diritto economico?",
    question_step: [8, 3],
    question_form: [
      {
        type: "Paragraph",
        value:
          "Il/la sottoscritto/a, in qualità di richiedente/titolare della carta di credito, dichiara che il denaro necessario per il saldo della fattura relativa alla carta e/o che sarà versato all’emittente della carta oltre questo importo,"
      },
      {
        type: "Radio",
        id: "s8q1",
        label: "Avente diritto economico:",
        options: [
          {
            label: "... è di sua esclusiva proprietà.",
            value: "ausschliesslich mir gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q11",
            code: "s8q11"
          },
          {
            label: "... appartiene alla/e seguente/i persona/e:",
            value: "der/den nachfolgend aufgeführten  Person/en gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q12",
            code: "s8q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s8q2",
        label: "Nome:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3",
        label: "Cognome:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Selezionare Paese...",
        id: "s8q4",
        options: countriesIT
      },
      {
        type: "Row",
        id: "s8r1",
        items: [
          {
            type: "TextInput",
            id: "s8q5",
            label: "Via",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6",
            label: "N.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2",
        items: [
          {
            type: "TextInput",
            id: "s8q7",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Si prega di inserire un codice postale valido."
          },
          {
            type: "TextInput",
            id: "s8q8",
            label: "Località",
            value: "",
            size: "twoThirds",
            validation: "city",
            errorMessage: "Si prega di inserire una località corretta."
          }
        ]
      },
      {
        type: "Select",
        label: "Selezionare nazionalità...",
        id: "s8q9",
        options: countriesFullIT
      },
      {
        type: "Calendar",
        id: "s8q10",
        label: "Data di nascita",
        labels: "GG/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Devi avere almeno 18 anni"
      },
      {
        type: "Checkbox",
        id: "s8q1b",
        label: "Aggiungere un'altra persona",
        size: "full",
        name: "s8q1b",
        value: false,
        required: false
      },
      {
        type: "TextInput",
        id: "s8q2b",
        label: "Nome:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3b",
        label: "Cognome:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Selezionare Paese...",
        id: "s8q4b",
        options: countriesIT
      },
      {
        type: "Row",
        id: "s8r1b",
        items: [
          {
            type: "TextInput",
            id: "s8q5b",
            label: "Via",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6b",
            label: "N.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2b",
        items: [
          {
            type: "TextInput",
            id: "s8q7b",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Si prega di inserire un codice postale valido."
          },
          {
            type: "TextInput",
            id: "s8q8b",
            label: "Località",
            value: "",
            size: "twoThirds",
            validation: "city",
            errorMessage: "Si prega di inserire una località corretta."
          }
        ]
      },
      {
        type: "Select",
        label: "Selezionare nazionalità...",
        id: "s8q9b",
        options: countriesFullIT
      },
      {
        type: "Calendar",
        id: "s8q10b",
        label: "Data di nascita",
        labels: "GG/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Devi avere almeno 18 anni"
      },
      // {
      //   type: "Checkbox",
      //   id: "s8q1c",
      //   label: "Aggiungere un'altra persona",
      //   size: "full",
      //   name: "s8q1c",
      //   value: false,
      //   required: false
      // },
      {
        type: "TextInput",
        id: "s8q2c",
        label: "Nome:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3c",
        label: "Cognome:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Selezionare Paese...",
        id: "s8q4c",
        options: countriesIT
      },
      {
        type: "Row",
        id: "s8r1c",
        items: [
          {
            type: "TextInput",
            id: "s8q5c",
            label: "Via",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6c",
            label: "N.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2c",
        items: [
          {
            type: "TextInput",
            id: "s8q7c",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Si prega di inserire un codice postale valido."
          },
          {
            type: "TextInput",
            id: "s8q8c",
            label: "Località",
            value: "",
            size: "twoThirds",
            validation: "city",
            errorMessage: "Si prega di inserire una località corretta."
          }
        ]
      },
      {
        type: "Select",
        label: "Selezionare nazionalità...",
        id: "s8q9c",
        options: countriesFullIT
      },
      {
        type: "Calendar",
        id: "s8q10c",
        label: "Data di nascita",
        labels: "GG/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Devi avere almeno 18 anni"
      }
    ]
  },
  {
    question_id: "9",
    question: "Copertura degli impegni di pagamento?",
    question_step: [9, 3],
    question_form: [
      {
        type: "InfoParagraphWithList",
        value:
          "La copertura le costa solo lo 0,5% dell'importo della sua fattura. Copriamo il saldo scoperto",
        list: [
          "in caso di disoccupazione involontaria",
          "in caso di incapacità al guadagno per infortunio o malattia"
        ],
        info: "1",
        title1: "BALANCE PROTECT",
        title2: "Puó capitare a chiunque.",
        title3: "Copertura totale del saldo*:",
        title4: "FAQ Balance PROTECT",
        listItem1:
          "La sue finanze sempre sotto controllo grazie all’assicurazione del saldo Balance PROTECT:",
        listItem2:
          "I motivi che possono portare a una situazione di difficoltà economica sono svariati; spesso succede tutto all’improvviso.",
        listItem3: "in caso di disoccupazione involontaria",
        listItem4:
          "in caso di incapacità al guadagno per infortunio o malattia",
        listItem5: "in caso di decesso per infortunio o malattia",
        listItem6:
          "Siamo a sua disposizione per rispondere a eventuali domande",
        listItem7: "Telefono: +41 44 805 58 58",
        listItem8: "E-mail: manor@viseca.ch",
        listItem9: "Cosa copre l’assicurazione?",
        listItem10:
          "L’assicurazione riguarda l’intero saldo scoperto del conto della carta. In caso di sinistro viene effettuato un pagamento unico sul conto della vostra carta (Balance Cancellation).",
        listItem11: "Chi è assicurato?",
        listItem12: "È assicurato il titolare della carta principale.",
        listItem13: "Quali sono i requisiti della copertura assicurativa?",
        listItem14:
          "L’adempimento delle condizioni di adesione e delle Condizioni Generali di Assicurazione",
        listItem15: "A quanto ammontano le somme assicurate?",
        listItem16:
          "L’assicurazione copre fino a CHF 10 000 per sinistro e complessivamente fino a CHF 30 000 per contratto.",
        listItem17: "Cosa devo fare in caso di sinistro?",
        listItem18:
          "Comunicate immediatamente il sinistro al service provider incaricato da Helvetia:",
        para1:
          "Disoccupazione involontaria in tempi economicamente difficili o incapacità al guadagno per un lungo periodo a causa di un infortunio o di una malattia: questi problemi possono riguardare chiunque. Balance PROTECT viene in suo soccorso coprendo l’intero saldo pendente. Con un premio pari solo allo 0,50% del saldo scoperto.",
        para2:
          "Ecco un esempio: se la fattura della sua Manor Mastercard ammonta a CHF 150, paga un premio di soli 75 centesimi.",
        para3: "Financial & Employee Benefits Services (febs) AG",
        para4: "Casella postale 1763, 8401 Winterthur",
        para5: "Telefono: +41 (0)52 266 02 92, fax: +41 (0)52 266 02 01",
        para6: "E-mail: viseca@febs.ch",
        para7:
          "* L’entità della copertura si basa sulle Condizioni Generali di Assicurazione (CGA) dell’assicurazione del saldo Balance PROTECT"
      },
      {
        type: "Table",
        value: "Esempio di calcolo di un premio",
        list: [
          ["Saldo riportato (ultima fattura)", "CHF 50.-"],
          ["Le sue nuove spese", "CHF 100.-"],
          ["Saldo scoperto", "CHF 150.-"],
          ["Premio 0,5% di CHF 150.-", "= 75 centesimi"]
        ],
        info: "2"
      },
      {
        type: "Special"
      },
      {
        type: "Radio",
        id: "s9q1",
        label: "Copertura impegni di pagamento",
        options: [
          {
            label: "Sì, vorrei coprire i miei impegni di pagamento",
            value: "ja",
            size: "full",
            name: "s9q1",
            id: "s9q11"
          },
          {
            label: "No, non desidero la copertura",
            value: "nein",
            size: "full",
            name: "s9q1",
            id: "s9q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "10",
    question: "Tutelarsi in caso di perdita o difetto della carta?",
    question_step: [10, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Risparmi i costi di sostituzione in caso di perdita o difetto con Card PROTECT dell'Assicurazione viaggi europea ERV.",
        info: "3",
        title1: "CARD PROTECT",
        title2: "FAQ Card PROTECT",
        listItem1: "Nessun costo per la sostituzione della carta",
        listItem2: "Nessun costo per la sostituzione del PIN",
        listItem3: "Prezzo: solo CHF 6.90 all’anno",
        listItem4: "In cosa consiste l’assicurazione Card PROTECT?",
        listItem5:
          "L’assicurazione Card PROTECT copre i costi per la sostituzione della carta in caso di furto, perdita o difetto della vostra Manor World Mastercard nonché i costi per la sostituzione del PIN.",
        listItem6: "Per chi vale la copertura assicurativa?",
        listItem7:
          "Sono assicurate le carte principali e supplementari della Manor World Mastercard.",
        listItem8: "A quanto ammonta il premio?",
        listItem9:
          "Il premio ammonta a CHF 6.90 e viene addebitato ogni anno sulla vostra carta di credito.",
        listItem10: "Quali servizi sono coperti dall’assicurazione?",
        listItem11:
          "Sono coperti tutti i costi per la sostituzione di carte Manor World Mastercard perse, rubate o difettose e/o dei relativi PIN.",
        listItem12: "Cosa devo fare in caso di sinistro?",
        listItem13:
          "In caso di perdita o furto rivolgetevi immediatamente alla centrale di blocco (24 ore) di Viseca al numero +41 (0)58 958 83 83.",
        listItem14:
          "In caso di perdita del PIN o carta difettosa contattate il servizio clienti al numero +41 (0) 44 805 58 58",
        listItem15: "Quando termina l’assicurazione?",
        listItem16:
          "L’assicurazione è stipulata a tempo indeterminato e si rinnova tacitamente di un anno se, al più tardi un mese prima del termine dell’anno assicurativo, non perviene alcuna disdetta scritta a Viseca.",
        para1: "Tutela affidabile in caso di perdita della carta e del PIN",
        para2:
          "Risparmi i costi di sostituzione in caso di perdita o difetto con l’assicurazione Card PROTECT di Viseca.",
        para3: "Ecco i vantaggi:"
      },
      {
        type: "ParagraphWithList",
        value: "Ecco i vantaggi:",
        list: [
          "nessun costo aggiuntivo per la sostituzione della carta",
          "nessun costo aggiuntivo per la sostituzione del PIN",
          "assicurazione valida per carte principali e carte supplementari"
        ]
      },
      {
        type: "Paragraph",
        value: "Prezzo: solo CHF 6.90 all’anno"
      },
      {
        type: "Radio",
        id: "s10q1",
        label: "Tutela in caso di perdita della carta o del PIN",
        options: [
          {
            label:
              "Sì, voglio evitare di sostenere le spese in caso di perdita della carta o del PIN.",
            value: "ja",
            size: "full",
            name: "s10q1",
            id: "s10q11"
          },
          {
            label: "No, non desidero tutelarmi",
            value: "nein",
            size: "full",
            name: "s10q1",
            id: "s10q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "11",
    question: "Copia del suo documento di legittimazione",
    question_step: [11, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Per poter redigere il suo contratto, abbiamo bisogno di una copia del suo documento di legittimazione. La preghiamo di caricare qui una foto del documento.",
        info: "4"
      },
      {
        type: "ImageUpload",
        id: "s11q1",
        name: "s11q1",
        labelSwiss: "Fronte del documento di legittimazione ",
        labelOther: "Fronte del documento di legittimazione per stranieri",
        size: "full"
      },
      {
        type: "ImageUpload",
        id: "s11q2",
        name: "s11q2",
        labelSwiss: "Retro del documento di legittimazione ",
        labelOther: "Retro del documento di legittimazione per stranieri",
        size: "full"
      }
    ]
  },
  {
    question_id: "12",
    question: "Riepilogo",
    question_step: [12, 4],
    question_form: [
      {
        type: "ProminentParagraph",
        value: "La preghiamo di controllare attentamente i suoi dati."
      },
      {
        type: "Summary",
        value: "I suoi dati personali",
        steps: [1, 3],
        desktop_steps: [1],
        heading: 1,
        list: [
          ["Appellativo:", "s1q1"],
          ["Nome:", "first_name"],
          ["Cognome:", "last_name"],
          ["Data di nascita:", "s3q3"],
          ["Stato civile:", "s3q2"],
          ["Nazionalità:", "s3q1"],
          ["Permesso di soggiorno:", "s3q4"],
          ["Permesso di dimora CH dal:", "s3q5"]
        ]
      },
      {
        type: "Summary",
        value: "La sua situazione abitativa",
        steps: [2, 5],
        desktop_steps: [1, 2],
        heading: 12,
        list: [
          ["Paese:", "s2q1"],
          ["Via:", "s2q2"],
          ["Numero:", "s2q3"],
          ["NPA:", "s2q4"],
          ["Località:", "s2q5"],
          ["Tipo di abitazione:", "s5q1"],
          ["Spese di abitazione:", "s5q2"]
        ]
      },
      {
        type: "Summary",
        value: "I suoi dati di contatto",
        steps: [4],
        heading: 4,
        desktop_steps: [1],
        list: [
          ["Indirizzo e-mail:", "s4q1"],
          ["Indirizzo e-mail:", "s4q2"],
          ["Telefono privato:", "s4q3"],
          ["Lingua per la corrispondenza:", "s4q4"]
        ]
      },
      {
        type: "Summary",
        value: "La sua situazione reddituale",
        desktop_steps: [2],
        heading: 6,
        steps: [6, 7],
        list: [
          ["Reddito annuo lordo:", "s6q1"],
          ["Reddito primario:", "s6q2"],
          ["Professione:", "s7q1"],
          ["Datore di lavoro:", "s7q2"],
          ["NPA:", "s7q3"],
          ["località:", "s7q4"],
          ["Conto bancario CH/LI:", "s6q3"],
          ["IBAN:", "s6q4"]
        ]
      },
      {
        type: "Summary",
        value: "Avente diritto economico",
        steps: [8],
        desktop_steps: [3],
        heading: 8,
        list: [
          ["Avente diritto economico:", "s8q1"],
          ["Persona 1", "person-title-1", "RowTitle"],
          ["Nome:", "s8q2"],
          ["Cognome:", "s8q3"],
          ["Data di nascita:", "s8q10"],
          ["Nazionalità:", "s8q9"],
          ["Paese:", "s8q4"],
          ["Via:", "s8q5"],
          ["numero:", "s8q6"],
          ["NPA:", "s8q7"],
          ["località:", "s8q8"],
          ["Persona 2", "person-title-2", "RowTitle"],
          ["Nome:", "s8q2b"],
          ["Cognome:", "s8q3b"],
          ["Data di nascita:", "s8q10b"],
          ["Nazionalità:", "s8q9b"],
          ["Paese:", "s8q4b"],
          ["Via:", "s8q5b"],
          ["numero:", "s8q6b"],
          ["NPA:", "s8q7b"],
          ["località:", "s8q8b"],
          ["Nome:", "s8q2c"],
          ["Cognome:", "s8q3c"],
          ["Data di nascita:", "s8q10c"],
          ["Nazionalità:", "s8q9c"],
          ["Paese:", "s8q4c"],
          ["Via:", "s8q5c"],
          ["numero:", "s8q6c"],
          ["NPA:", "s8q7c"],
          ["località:", "s8q8c"]
        ]
      },
      {
        type: "Summary",
        value: "Servizi supplementari",
        steps: [9, 10],
        desktop_steps: [3],
        heading: 9,
        list: [
          ["Copertura impegni di pagamento", "s9q1"],
          ["Tutela in caso di perdita della carta o del PIN", "s10q1"]
        ]
      },
      {
        type: "Summary",
        value: "Copia del documento di legittimazione",
        desktop_steps: [3],
        steps: [11],
        heading: 11,
        list: [
          ["Fronte", "s11q1"],
          ["Retro", "s11q2"]
        ]
      }
    ]
  },
  {
    question_id: "14",
    question: "Dichiarazione di assenso",
    question_step: [14, 4],
    question_form: [
      {
        type: "CollapsedParagraph",
        value:
          "Con la propria firma, il/la sottoscritto/a conferma la correttezza delle informazioni di cui sopra e autorizza Viseca Card Services SA («Viseca» o «noi»):\n •	nell’ambito dell’esame della richiesta della carta e per la sua emissione, a verificare e trattare i dati sopra forniti, nonché a richiedere le informazioni necessarie a tal fine a terzi, ad es. alla Centrale per informazioni sul credito («ZEK»), alle autorità (ad es. uffici di esecuzione e uffici delle contribuzioni, controllo abitanti), ad agenzie d’informazione che operano nel settore economico, a datori di lavoro e a ulteriori enti d'informazione previsti dalla legge (ad es. alla Centrale d'informazione per il credito al consumo, IKO) oppure opportuni; e\n •	a comunicare alla ZEK fattispecie, come il blocco della carta, pagamenti arretrati o l'utilizzo abusivo della carta, nonché, nei casi previsti dalla legge, a informare gli enti competenti.",
        more: "di più"
      },
      {
        type: "CollapsedParagraph",
        value:
          "Nei limiti delle presenti autorizzazioni, il/la sottoscritto/a ci svincola dal segreto bancario, commerciale e d’ufficio e dalla protezione dei dati. Siamo autorizzati a rifiutare la presente richiesta della carta o singole richieste senza indicarne i motivi. Il/la richiedente accetta che noi possiamo lo/la contattare per e-mail o per telefono (tramite SMS / app). In tale contesto noi facciamo presente che in particolare la comunicazione per e-mail, a causa della configurazione aperta di Internet, non garantisce la riservatezza né la sicurezza delle informazioni trasmesse. Vi è pertanto la possibilità che, mal-grado tutte le misure di sicurezza adottate da Viseca, terzi possano accedere in modo illecito alla comunicazione tra il/la richiedente e noi.",
        more: "di più"
      },
      {
        type: "Checkbox",
        required: true,
        id: "s14q1",
        label: "Accetto la dichiarazione.",
        size: "full",
        name: "s14q1",
        value: false
      },
      {
        type: "Paragraph",
        value:
          "Viseca Card Services SA, Hagenholzstrasse 56, 8050 Zurigo, Telefono +41 44 805 58 58"
      }
    ]
  }
];

export default questionsIT;
