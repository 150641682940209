import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withConsumer } from "../../appContext";
import s from "./Success.module.scss";

import ProminentParagraph from "../../components/ProminentParagraph";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import Image from "../../components/Image";
import card from "../../media/images/card.jpg";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../helpers/api";

const Success = ({ answers, cleanState }) => {
  const { t } = useTranslation();
  useEffect(() => {
    logEvent(
      "Success Page Displayed",
      answers?.s4q1?.value,
      answers?.s2q4?.value
    );
    cleanState();
  }, []);

  return (
    <section className={`${s.Container}`}>
      <div className={s.Heading}>
        <Image src={card} />
        <Heading>
          {t("success.done-2.1")}
          <span style={{ fontWeight: "bold", color: "#00C4AE" }}>
            {t("success.done-2.2")}
          </span>
          {t("success.done-2.3")}
        </Heading>
      </div>
      <div className={`${s.Main}`}>
        <div className={s.Intro}>
          <ProminentParagraph>{t("success.info-home")}</ProminentParagraph>
          <div className={s.ButtonsDiv}>
            <Button>
              <a href={"https://www.manor.ch/"}>{t("error.back-to-manor")}</a>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(withConsumer(Success));
