import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import "./i18n/i18n";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn:
    "https://1b4822da7fee4d178cb44ed1a2ee5737@o490772.ingest.sentry.io/5743336",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
document.addEventListener("ontouchstart", prevent, false);
document.addEventListener("ontouchmove", prevent, false);
window.addEventListener("ontouchstart", prevent, false);
window.addEventListener("ontouchmove", prevent, false);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
