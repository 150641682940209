import React from "react";
import Paragraph from "../components/Paragraph";
import Subheading from "../components/Subheading";
import TextInput from "../components/TextInput";
import RadioButtons from "../components/RadioButtons";
import SelectInput from "../components/SelectInput";
import CheckboxInput from "../components/CheckboxInput";
import InfoParagraphWithList from "../components/InfoParagraphWithList";
import InfoParagraph from "../components/InfoParagraph";
import ParagraphWithList from "../components/ParagraphWithList";
import ParagraphWithNoBulletList from "../components/ParagraphWithNoBulletList";
import ProminentParagraph from "../components/ProminentParagraph";
import ImageUpload from "../components/ImageUpload";
import CollapsedParagraph from "../components/CollapsedParagraph";
import Summary from "../components/Summary";
import Overlay from "../components/Overlay";
import List from "../components/List";
import Label from "../components/Label";
import Row from "../components/Row";
import Calendar from "../components/Calendar";
import Table from "../components/Table";
import Cellphone from "../components/Cellphone";

const isDesktop = (width) => width >= 896;

const isIOS = () => {
  return [
    "iPhone",
    "iPad",
    "iPod",
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator"
  ].includes(navigator.platform);
};
const iOSVersion13 = () => {
  if (!isIOS()) return false;
  var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return parseInt(v[1], 10) >= 13;
};

const getSize = (size) => {
  switch (size) {
    case "single":
      return { width: "3rem" };
    case "half":
      return { width: "49%" };
    case "third":
      return { width: "32%" };
    case "twoThirds":
      return { width: "65%" };
    case "full":
      return { width: "100%" };
    default:
      return { width: "100%" };
  }
};

const getStyledSize = (size) => {
  switch (size) {
    case "single":
      return "3rem";
    case "half":
      return "49%";
    case "third":
      return "32%";
    case "twoThirds":
      return "65%";
    case "full":
      return "100%";
    default:
      return "100%";
  }
};

function elementInViewport(el) {
  if (!el) return false;
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
}

const renderComponent = (component) => {
  const { type, isNumberField, id } = component;
  if (component?.formatting === "Number") {
    return renderNumberInput(component);
  }
  if (isNumberField) {
    return renderNumberInput(component);
  }

  switch (type) {
    /** START Editable components */
    case "TextInput":
      return renderTextInput(component);
    case "Calendar":
      return renderCalendar(component);
    case "Radio":
      return renderRadioButtons(component);
    case "RadioWithText":
      return renderRadioButtons(component);
    case "Select":
      return renderSelect(component);
    case "Checkbox":
      return renderCheckbox(component);
    /** END Editable components */
    case "Row":
      return renderRow(component);
    case "Heading":
      return renderHeading(component);
    case "Label":
      return renderLabel(component);
    case "Paragraph":
      return renderParagraph(component);
    case "List":
      return renderList(component);
    case "InfoParagraphWithList":
      return renderInfoParagraphWithList(component);
    case "InfoParagraph":
      return renderInfoParagraph(component);
    case "ParagraphWithList":
      return renderParagraphWithList(component);
    case "ParagraphWithNoBulletList":
      return renderParagraphWithNoBulletList(component);
    case "ProminentParagraph":
      return renderProminentParagraph(component);
    case "ImageUpload":
      return renderImageUpload(component);
    case "CollapsedParagraph":
      return renderCollapsedParagraph(component);
    case "Summary":
      return renderSummary(component);
    case "Overlay":
      return renderOverlay(component);
    case "Table":
      return renderTable(component);
    case "Cellphone":
      return renderCellphone(component);
    default:
      break;
  }
};

/** START Editable components */
const renderTextInput = (component) => (
  <TextInput {...component} key={component.id} />
);
const renderNumberInput = (component) => (
  <TextInput {...component} key={component.id} isNumberField={true} />
);
const renderCalendar = (component) => (
  <Calendar {...component} key={component.id} />
);
const renderRadioButtons = (component) => (
  <RadioButtons {...component} key={component.id} />
);
const renderSelect = (component) => (
  <SelectInput {...component} key={component.id} />
);
const renderCheckbox = (component) => (
  <CheckboxInput {...component} key={component.id} />
);

/** END Editable components */
const renderRow = (component) => <Row {...component} key={component.id} />;

const renderHeading = ({ value, id, marginBottom }) => (
  <Subheading marginBottom={marginBottom} id={id} key={id}>
    {value}
  </Subheading>
);
const renderParagraph = ({ value }) => <Paragraph>{value}</Paragraph>;
const renderProminentParagraph = ({ value, id }) => (
  <ProminentParagraph key={id}>{value}</ProminentParagraph>
);
const renderList = (component) => <List {...component} />;
const renderInfoParagraphWithList = (component) => (
  <InfoParagraphWithList {...component} />
);

const renderInfoParagraph = (component) => <InfoParagraph {...component} />;
const renderParagraphWithList = (component) => (
  <ParagraphWithList {...component} />
);
const renderParagraphWithNoBulletList = (component) => (
  <ParagraphWithNoBulletList {...component} />
);
const renderCollapsedParagraph = (component) => (
  <CollapsedParagraph {...component} />
);
const renderLabel = ({ value, id }) => <Label key={id}>{value}</Label>;

const renderImageUpload = (component) => <ImageUpload {...component} />;

const renderSummary = (component) => <Summary {...component} />;

const renderOverlay = (component) => <Overlay {...component} />;

const renderTable = (component) => <Table {...component} />;

const renderCellphone = (component) => <Cellphone {...component} />;

export {
  isDesktop,
  isIOS,
  iOSVersion13,
  getSize,
  getStyledSize,
  renderComponent,
  renderParagraphWithList,
  renderParagraphWithNoBulletList,
  renderInfoParagraphWithList,
  renderInfoParagraph,
  renderParagraph,
  renderLabel,
  renderHeading,
  renderTextInput,
  renderCalendar,
  renderRadioButtons,
  renderRow,
  renderSelect,
  renderCheckbox,
  renderImageUpload,
  renderCollapsedParagraph,
  renderSummary,
  renderOverlay,
  renderCellphone,
  elementInViewport
};
