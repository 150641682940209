import React from "react";
import s from "./Switch.module.scss";

const Switch = ({
  checked,
  id,
  label,
  name,
  type = "checkbox",
  value,
  onChange,
  minHeight
}) => {
  return (
    <div
      data-recording-sensitive
      className={`${s.Switch} ${type}`}
      style={{ height: !minHeight ? "100%" : "initial" }}
    >
      <input
        data-name={name}
        data-recording-sensitive
        checked={checked}
        className={`${s.Input} smartlook-hide`}
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
      />
      <label className={`${s.Label} smartlook-hide`} htmlFor={id} data-recording-sensitive>
        <div className={s.Radio} />
        {/* <img src={radioBtn} className={s.Radio} /> */}
        {label}
      </label>
    </div>
  );
};

export default Switch;
