import React from "react";
import Paragraph from "../Paragraph";
import s from "./Table.module.scss";

const Table = (component) => {
  const { value, list } = component;
  return (
    <div className={`${s.Container}`}>
      <Paragraph className={s.Container}>
        <b>{value}</b>
      </Paragraph>
      <table className={s.Table}>
        <tbody>
        {list.map((row, i) => (
          <tr key={`tr_${i}`}>
            {row.map((col, j) => (
              <td key={`td_${i}_${j}`}>{col}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
