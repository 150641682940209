import React, { useState, useEffect } from "react";
import s from "./InteractiveCard.module.scss";
import { withConsumer } from "../../appContext";
import coverLeaf from "../../media/images/card-cloverleaf.png";
import icon from "../../media/images/mastercard-icon.png";
import chip from "../../media/images/card-chip.png";
import valid from "../../media/images/valid-date.png";
import manor from "../../media/images/card-manor.png";
import { validateField } from "../../helpers/validations";

const formatNumber = (number) => number.match(/.{1,4}/g).join(" ");
const defaultValue = (value) => (value.length > 0 ? value : "****");

const InteractiveCard = ({
  number,
  date,
  firstName,
  lastName,
  answers,
  questions
}) => {
  if (!date) {
    const d = new Date();
    d.setFullYear(d.getFullYear() + 3);
    date = `${String(d.getMonth() + 1).padStart(2, "0")}/${String(
      d.getFullYear()
    ).substring(2)}`;
  }
  const [step, setStep] = useState(0);
  const filledTill = (id) => {
    const qs = questions.reduce(
      (arr, q) => [
        ...arr,
        ...q.question_form.reduce(
          (arr, q) => [...arr, ...(q.type === "Row" ? q.items : [q])],
          []
        )
      ],
      []
    );
    for (const q of qs) {
      if (!validateField(q, questions, answers)) {
        return false;
      }
      if (q.id === id) break;
    }
    return true;
  };
  useEffect(() => {
    let step = 0;
    if (answers.first_name.value && answers.last_name.value) {
      step++;
    }
    if (filledTill("s2q6")) {
      step++;
    } // 2
    if (filledTill("s3q3")) {
      step++;
    } // 3
    if (filledTill("s4q4")) {
      step++;
    } // 4
    if (filledTill("s5q2")) {
      step++;
    } // 5
    if (filledTill("s6q3")) {
      step++;
    } // 6
    if (filledTill("s8q1")) {
      step++;
    } // 7
    if (filledTill("s10q1")) {
      step++;
    } // 8
    if (filledTill("s11q2")) {
      step++;
    } // 9
    if (filledTill("s14q1")) {
      step++;
    } // 10
    setStep(step);
  }, [answers]);
  return (
    <div className={s.Card}>
      <img
        alt={"coverleaf"}
        src={coverLeaf}
        className={`${s.CoverLeaf} ${step >= 3 ? s.Show : ""}`}
      />
      <img
        alt={"mastercard"}
        src={icon}
        className={`${s.Mastercard} ${step >= 4 ? s.Show : ""}`}
      />
      <img
        alt={"chip"}
        src={chip}
        className={`${s.Chip} ${step >= 9 ? s.Show : ""}`}
      />
      <img
        alt={"valid"}
        src={valid}
        className={`${s.ValidLabel} ${step >= 10 ? s.Show : ""}`}
      />
      <img
        alt={"manor"}
        src={manor}
        className={`${s.Manor} ${step >= 2 ? s.Show : ""}`}
      />
      <div className={s.Number}>
        <span className={`${s.NumberPart} ${step >= 5 ? s.Show : ""}`}>
          {number.substring(0, 4)}&nbsp;
        </span>
        <span className={`${s.NumberPart} ${step >= 6 ? s.Show : ""}`}>
          {number.substring(4, 8)}&nbsp;
        </span>
        <span className={`${s.NumberPart} ${step >= 7 ? s.Show : ""}`}>
          {number.substring(8, 12)}&nbsp;
        </span>
        <span className={`${s.NumberPart} ${step >= 8 ? s.Show : ""}`}>
          {number.substring(12, 16)}
        </span>
      </div>
      <div className={`${s.Valid} ${step >= 10 ? s.Show : ""}`}><span>Valid thru</span>{date}</div>
      <div className={s.Name}>
        <div 
          data-name="card-last-name"
          data-recording-sensitive  
          className={`${s.FirstName} smartlook-hide`}
        >
          {step >= 1 ? defaultValue(firstName) : ""}
        </div>
        <div 
          data-name="card-last-name"
          data-recording-sensitive 
          className={`${s.LastName} smartlook-hide`}
        >
          {step >= 1 ? defaultValue(lastName) : ""}
        </div>
      </div>
    </div>
  );
};

export default withConsumer(InteractiveCard);
