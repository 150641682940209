import questionsDE from "./questions-de";
import questionsEN from "./questions-en";
import questionsFR from "./questions-fr";
import questionsIT from "./questions-it";

const QUESTIONS_DE = questionsDE;

const QUESTIONS_EN = questionsEN;

const QUESTIONS_FR = questionsFR;

const QUESTIONS_IT = questionsIT;

export { QUESTIONS_DE, QUESTIONS_EN, QUESTIONS_FR, QUESTIONS_IT };
