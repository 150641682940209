import React, { useState } from "react";
import s from "./InfoParagraph.module.scss";
import { Info } from "../Icon";
import Overlay from "../Overlay/Overlay";
import { withConsumer } from "../../appContext";
import { isDesktop } from "../../helpers/render";

const InfoParagraph = (component, windowWidth) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setDisplayOverlay(true);
  };

  const { value, info } = component;

  return (
    <div className={s.Container}>
      {value}
      
      <span onClick={handleClick} className={s.Info}>
        <Info />
      </span>
      {/* <span>{info}</span> */}

      <Overlay {...component} text={true} show={displayOverlay} setDisplayOverlay={setDisplayOverlay} />
      
    </div>
  );
};

export default withConsumer(InfoParagraph);
