import React, { useEffect } from "react";
import Switch from "../Switch";
import { getSize } from "../../helpers/render";

import s from "./Switches.module.scss";
import { useTranslation } from "react-i18next";
import { withConsumer } from "../../appContext";

const Switches = ({
  id,
  options,
  type,
  name,
  handleChange,
  value,
  highlightErrors,
  setSticky
}) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    handleChange(id, e.target.value);

    if (!id === "s6q2") return;
    setTimeout(() => {
      const top =
        8.6 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      setSticky(
        !(
          window.scrollY >
          document.body.scrollHeight - top - window.innerHeight
        )
      );
    }, 50);
  };

  const inputType = () => {
    switch (type) {
      case "Radio":
      case "RadioWithText":
        return "radio";

      default:
        break;
    }
  };

  return (
    <>
      <div className={`${s.Items}`}>
        {options.map((option, index) => {
          return (
            <div
              data-recording-sensitive
              className={`${s.Item} ${
                type === "RadioWithText" ? s.RadioWithText : ""
              } smartlook-hide`}
              key={index}
              style={getSize(option.size)}
            >
              {option.text_header && (
                <div data-recording-sensitive className={`${s.Label} smartlook-hide`}>{option.text_header}</div>
              )}
              {option.text && <p data-recording-sensitive className={`${s.Paragraph} smartlook-hide`}>{option.text}</p>}
              <Switch
                name={name}
                type={inputType()}
                {...option}
                onChange={onChange}
                checked={option.value === value}
                minHeight={id === "s13q1"}
              />
            </div>
          );
        })}
      </div>
      {!value && highlightErrors ? (
        <span className={s.Error}>{t("blurErrors.OptionRequired")}</span>
      ) : null}
    </>
  );
};

export default withConsumer(Switches);
