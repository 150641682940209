import React, { useEffect, useState } from "react";
import { withConsumer } from "../../appContext";
import { isDesktop } from "../../helpers/render";
import s from "./DecorativeSidebar.module.scss";
import InteractiveCard from "../InteractiveCard/InteractiveCard";
import { validateField } from "../../helpers/validations";
import FooterNavigation from "../FooterNavigation";

const DecorativeSidebar = ({
  windowWidth,
  answers,
  questions,
  sticky,
  setSticky,
  top,
  right
}) => {
  const AUTO_VALID = 28;
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setSticky(
        !(
          window.scrollY >
          document.body.scrollHeight - top - window.innerHeight
        )
      );
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    const qs = questions.reduce(
      (arr, q) => [
        ...arr,
        ...q.question_form.reduce(
          (arr, q) => [...arr, ...(q.type === "Row" ? q.items : [q])],
          []
        )
      ],
      []
    );
    const total = qs.length - AUTO_VALID;
    const valid =
      qs.reduce((sum, q) => sum + validateField(q, questions, answers), 0) -
      AUTO_VALID;
    setPercent(Math.floor((100 * valid) / total));
  }, [answers]);

  if (!isDesktop(windowWidth)) return null;
  return (
    <>
      <div className={s.Placeholder} />
      <div
        id={sticky ? "decorative" : "deco"}
        className={`${s.Decor} ${sticky ? s.Fix : s.Abs}`}
        style={{
          top: sticky ? `${top}px` : "auto",
          right: sticky ? `${right?.getBoundingClientRect().left || 0}px` : 0
        }}
      >
        <InteractiveCard
          number='5498940000000000'
          firstName={answers.first_name.value}
          lastName={answers.last_name.value}
        />
        {/* <div className={s.ProgressLine}>
          <div className={s.Done} style={{width: `${percent}%`}}>
            <div className={s.Label}>{percent}%</div>
          </div>
          <div className={s.Remind} style={{width: `${100 - percent}%`}}/>
        </div> */}
        {
          // <img
          //   className={s.Image}
          //   alt={"rocket"}
          //   src={[rocket1, rocket2, rocket3, rocket4][currentStep - 1]}
          // />
        }
        <FooterNavigation />
      </div>
    </>
  );
};

export default withConsumer(DecorativeSidebar);
