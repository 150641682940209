import React from "react";
import s from "./Button.module.scss";

const Button = ({
  full,
  children,
  onClick,
  disabled,
  inverted = false,
  transparent = false,
  finish = false,
  color = false,
  big = false,
  footer = false,
  className = "",
  style
}) => (
  <button
    className={`${inverted ? s.Inverted : ""} ${
      transparent ? s.Transparent : ""
    } ${finish ? s.Finish : ""} ${footer ? s.Footer : ""} ${
      big ? s.Big : ""
    } ${className} ${s.Button} ${full ? s.Bigger : ""} `}
    onClick={onClick}
    disabled={disabled}
    style={{...style, ...(color ? { color: color } : {})}}
  >
    {children}
  </button>
);

export default Button;
