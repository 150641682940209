import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withConsumer } from "../appContext";
import {getBrowserLanguage} from '../helpers/utils'
import Home from "../pages/Home";
import Content from "../pages/Content";
import Success from "../pages/Success";
import ErrorPage from "../pages/Error";

import s from "./AppRouter.module.scss";

class AppRouter extends Component {
  render() {
    const { location } = this.props;

    return (
      <main className={s.Container}>
        <Switch location={location}>
          <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)+location.search} />
          <Route exact path='/:lang/manor' component={Content} />
          <Route exact path='/:lang/success' component={Success} />
          <Route exact path='/:lang/error' component={ErrorPage} />
          <Route exact path='/:lang' component={Home} />
          <Redirect to={'/' + getBrowserLanguage() +location.search}/>
        </Switch>
      </main>
    );
  }
}

export default withConsumer(withRouter(AppRouter));
