import React from "react";
import List from "../List";
import Paragraph from "../Paragraph";
import s from "./ParagraphWithNoBulletList.module.scss";

const ParagraphWithNoBulletList = (component) => {
  const { value } = component;
  return (
    <div className={`${s.Container}`}>
      <Paragraph className={s.Container}><b>{value}</b></Paragraph>
      <List {...component} />
    </div>
  );
};

export default ParagraphWithNoBulletList;
