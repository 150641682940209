import React from "react";
import { withConsumer } from "../../appContext";
import s from "./Calendar.module.scss";
import {
  iOSVersion13,
  isDesktop,
  elementInViewport
} from "../../helpers/render";
import { useTranslation } from "react-i18next";

const Calendar = ({
  labels,
  label,
  value = "//",
  error,
  id,
  handleChange,
  hideTargetSelectField,
  hideTargetSelectFieldTwo,
  hideTargetSelectFieldThree,
  errorMessage,
  underAgeMessage,
  validation,
  optional,
  highlightErrors
}) => {
  const getDayValue = () => value.split("/")[0];
  const getMonthValue = () => value.split("/")[1];
  const getYearValue = () => value.split("/")[2];
  const isEmpty = () => !getDayValue() && !optional && highlightErrors;
  const { t } = useTranslation();

  const onChange = (e) => {
    if (e.target.value && isNaN(e.target.value)) return;
    //auto tab days
    if (
      e.target.value.length === 2 &&
      e.target.nextElementSibling?.tagName === "INPUT" &&
      e.target.min !== "1900"
    ) {
      e.target.nextElementSibling.focus();
    }
    //auto tab month
    if (
      e.target.value.length === 2 &&
      e.target?.nextElementSibling?.nextElementSibling.tagName === "INPUT" &&
      e.target.min !== "1900"
    ) {
      e.target.nextElementSibling.nextElementSibling.focus();
    }
    const { target } = e;
    const newValue = value.split("/");
    const today = new Date();
    const isCurrentYear = () => +newValue[2] === today.getFullYear();
    const isCurrentMonth = () =>
      isCurrentYear() && +newValue[1] === today.getMonth() + 1;
    const DAY = 0,
      MONTH = 1,
      YEAR = 2;

    switch (target.name) {
      case `${id}-day`:
        if (
          target.value > 31 ||
          target.value < 0 ||
          (isCurrentMonth() && today.getDate() < target.value) ||
          target.value.length > 2
        )
          return;
        newValue[DAY] = target.value;
        break;
      case `${id}-month`:
        if (
          target.value > 12 ||
          target.value < 0 ||
          (isCurrentYear() && today.getMonth() + 1 < target.value) ||
          target.value.length > 2
        )
          return;
        newValue[MONTH] = target.value;
        break;
      case `${id}-year`:
        if (
          target.value > today.getFullYear() ||
          target.value < 0 ||
          target.value.length > 4
        )
          return;
        newValue[YEAR] = target.value;
        break;

      default:
        break;
    }

    handleChange(id, newValue.join("/"));
  };

  const hasDay = getDayValue().length > 0 ? s.HasValue : "";
  const hasMonth = getMonthValue().length > 0 ? s.HasValue : "";
  const hasYear = getYearValue().length > 0 ? s.HasValue : "";
  const inputLabel = labels.split("/");

  let currentError = errorMessage;

  const getAge = (birthdate) => {
    var today = new Date();
    var birthDate = new Date(birthdate);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;

    // const dateDiff = Date.now() - birthdate.getTime();
    // const age = new Date(dateDiff);
    // return Math.abs(age.getUTCFullYear() - 1970);
  };

  const birthday = new Date(
    `${getYearValue()}/${getMonthValue()}/${getDayValue()}`
  );
  const age = getAge(birthday);

  if (validation === "Birthdate" && age < 18) {
    currentError = underAgeMessage;
  }
  const aYear = 31556952000;
  if (
    birthday.getTime() > new Date().getTime() ||
    birthday.getTime() < new Date().getTime() - 100 * aYear
  ) {
    currentError = t("blurErrors.future");
  }
  if (isEmpty()) {
    currentError = t("blurErrors.Required");
  }

  function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
    }
    return [curtop];
  }

  if (hideTargetSelectField && (id === "s3q5" || id === "s8q10")) {
    return null;
  }
  if (hideTargetSelectFieldTwo && id === "s8q10b") {
    return null;
  }
  if (
    (hideTargetSelectFieldTwo || hideTargetSelectFieldThree) &&
    id === "s8q10c"
  ) {
    return null;
  }

  const scrollElementIntoView = (id) => {
    setTimeout(() => {
      if (iOSVersion13()) return;
      if (
        isDesktop(window.innerWidth) &&
        elementInViewport(document.getElementsByName(`${id}-day`)[0])
      )
        window.scroll({
          behavior: "smooth",
          top:
            findPos(document.getElementsByName(`${id}-day`)[0]) -
            window.innerHeight / 3
        });
      else if (!isDesktop(window.innerWidth))
        document.getElementById("main").scroll({
          behavior: "smooth",
          top:
            findPos(document.getElementsByName(`${id}-day`)[0]) -
            window.innerHeight / 3
        });
    }, 200);
  };

  return (
    <>
      <div className={s.Group} id={id}>
        <input
          data-recording-sensitive
          className={`${s.Input} ${
            error || (!getDayValue() && !optional && highlightErrors)
              ? s.Errored
              : ""
          } ${hasDay}`}
          name={`${id}-day`}
          placeholder={inputLabel[0]}
          autoComplete={"off"}
          onChange={onChange}
          onBlur={(e) => {
            e.persist();
            const checkDigits = () => {
              const value = parseInt(e.target.value, 10);
              if (e.target?.value.length === 1 && value < 10 && value >= 0) {
                e.target.value = "0" + e.target.value;
                onChange(e);
              }
            };

            checkDigits();
            setTimeout(checkDigits, 200);
          }}
          type='tel'
          inputMode='numeric'
          onClick={() => scrollElementIntoView(id)}
          onFocus={() => scrollElementIntoView(id)}
          min='1'
          max='31'
          value={getDayValue()}
        />
        <input
          data-recording-sensitive
          className={`${s.Input} ${
            error || (!getMonthValue() && !optional && highlightErrors)
              ? s.Errored
              : ""
          } ${hasMonth}`}
          autoComplete={"off"}
          name={`${id}-month`}
          placeholder={inputLabel[1]}
          onChange={onChange}
          onBlur={(e) => {
            e.persist();
            const checkDigits = () => {
              if (e.target?.value.length === 1 && 9 >= e.target.value >= 0) {
                e.target.value = "0" + e.target.value;
                onChange(e);
              }
            };

            checkDigits();
            setTimeout(checkDigits, 200);
          }}
          type='tel'
          inputMode='numeric'
          min='1'
          max='12'
          value={getMonthValue()}
          onClick={() => scrollElementIntoView(id)}
          onFocus={() => scrollElementIntoView(id)}
        />
        <label>{label}</label>
        <input
          data-recording-sensitive
          className={`${s.Input} ${
            error || (!getYearValue() && !optional && highlightErrors)
              ? s.Errored
              : ""
          } ${hasYear}`}
          autoComplete={"off"}
          name={`${id}-year`}
          placeholder={inputLabel[2]}
          onChange={onChange}
          type='tel'
          inputMode='numeric'
          min='1900'
          max={new Date().getFullYear().toString()}
          value={getYearValue()}
          onClick={() => scrollElementIntoView(id)}
          onFocus={() => scrollElementIntoView(id)}
        />
      </div>
      {highlightErrors && (error || isEmpty()) && (
        <span className={s.ErrorMessage}>{currentError}</span>
      )}
    </>
  );
};

export default withConsumer(Calendar);
