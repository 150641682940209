import i18next from "i18next";
import de from "./de";
import fr from "./fr";
import it from "./it";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  detection: {
    caches: []
  },
  lng: "de",
  interpolation: {
    escapeValue: false
  },
  resources: {
    de: {
      translation: de
    },
    fr: {
      translation: fr
    },
    it: {
      translation: it
    }
  }
});

export default i18next;
