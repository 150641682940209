import countriesFR from "../helpers/countries/countries-fr.json";
import countriesFullFR from "../helpers/countries/countries-full-fr.json";

const questionsFR = [
  {
    question_id: "0",
    question: "Avez-vous un code de promotion ?",
    question_step: [1, 1],
    question_form: [
      {
        type: "TextInput",
        id: "promo",
        label: "Code promotionnel",
        maxLength: 30,
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "1",
    question: "Comment vous appelez-vous?",
    question_step: [1, 1],
    question_form: [
      {
        type: "Label",
        value: "Civilité",
        id: "s1q0"
      },
      {
        type: "Radio",
        id: "s1q1",
        label: "sex",
        options: [
          {
            label: "Madame",
            value: "mrs",
            size: "half",
            name: "s1q1",
            id: "s1q11"
          },
          {
            label: "Monsieur",
            value: "mr",
            size: "half",
            name: "s1q1",
            id: "s1q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "first_name",
        formatting: "name",
        label: "Prénom (conformément à votre pièce/carte d'identité)",
        maxLength: 30,
        value: ""
      },
      {
        type: "TextInput",
        id: "last_name",
        formatting: "name",
        label: "Nom (conformément à votre pièce/carte d'identité)",
        maxLength: 30,
        value: ""
      }
    ]
  },
  {
    question_id: "2",
    question: "Où habitez-vous?",
    question_step: [2, 1],
    question_form: [
      {
        type: "Select",
        label: "Sélectionner un pays...",
        id: "s2q1",
        options: countriesFR
      },
      {
        type: "Row",
        id: "s2r1",
        items: [
          {
            type: "TextInput",
            id: "s2q2",
            label: "Rue",
            maxLength: 25,
            minLength: 1,
            value: "",
            size: "twoThirds"
          },
          {
            type: "TextInput",
            id: "s2q3",
            label: "No.",
            maxLength: 4,
            value: "",
            size: "third",
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s2r2",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s2q4",
            label: "NPA",
            formatting: "Number",
            maxLength: 6,
            errorMessage: "Veuillez entrer un code postal valide.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s2q5",
            label: "Localité",
            validation: "city",
            maxLength: 25,
            value: "",
            size: "twoThirds",
            errorMessage: "Veuillez entrer une localité correcte."
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Veuillez entrer une date valide.",
        id: "s2q6",
        label: "Depuis quand résidez-vous à  cette adresse",
        labels: "JJ/MM/AAAA",
        required: true
      }
    ]
  },
  {
    question_id: "3",
    question: "Quelles sont votre nationalité et votre état civil?",
    question_step: [3, 1],
    question_form: [
      {
        type: "Select",
        label: "Sélectionner une nationalité...",
        id: "s3q1",
        options: countriesFullFR
      },
      {
        type: "Select",
        label: "Type de livret pour étrangers",
        id: "s3q4",
        options: [
          {
            label: "B - Autorisation de séjour à l’année",
            value: "B - Autorisation de séjour à l’année"
          },
          {
            label: "C - Autorisation d’établissement",
            value: "C - Autorisation d’établissement"
          },
          {
            label: "G - Frontalier",
            value: "G - Frontalier"
          },
          {
            label: "L - Autorisation de courte durée",
            value: "L - Autorisation de courte durée"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Veuillez entrer une date valide.",
        id: "s3q5",
        label: "Titulaire d'un permis de séjour en Suisse depuis le:",
        labels: "JJ/MM/AAAA"
      },
      {
        type: "Select",
        id: "s3q2",
        label: "Sélectionner un état civil...",
        options: [
          {
            label: "Célibataire",
            value: "single"
          },
          {
            label: "Marié(e)",
            value: "married"
          },
          {
            label: "Divorcé(e)",
            value: "divorced"
          },
          {
            label: "Veuf/veuve",
            value: "widowed"
          },
          {
            label: "Autre",
            value: "other"
          }
        ]
      },
      {
        type: "Calendar",
        errorMessage: "Veuillez entrer une date valide.",
        validation: "Birthdate",
        underAgeMessage: "Vous devez être âgé d'au moins 18 ans",
        id: "s3q3",
        label: "Date de naissance",
        labels: "JJ/MM/AAAA",
        required: true
      }
    ]
  },
  {
    question_id: "4",
    question: "Comment pouvons-nous vous joindre?",
    question_step: [4, 1],
    question_form: [
      {
        type: "TextInput",
        id: "s4q1",
        label: "E-Mail",
        errorMessage:
          "Veuillez saisir une adresse e-mail valide du type nom@gmail.com et n’utilisez pas de caractères spéciaux.",
        validation: "Email",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q2",
        label: "Téléphone portable",
        errorMessage: "Veuillez entrer un numéro de téléphone valide.",
        value: ""
      },
      {
        type: "Cellphone",
        id: "s4q3",
        label: "Téléphone privé (facultatif)",
        errorMessage: "Veuillez entrer un numéro de téléphone valide.",
        value: "",
        required: false,
        optional: true
      },
      {
        type: "Select",
        id: "s4q4",
        label: "Sélectionner une langue de correspondance...",
        options: [
          {
            label: "Allemand",
            value: "de-CH"
          },
          {
            label: "Italien",
            value: "it-CH"
          },
          {
            label: "Français",
            value: "fr-CH"
          }
        ]
      }
    ]
  },
  {
    question_id: "5",
    question: "Situation en matière de logement?",
    question_step: [5, 2],
    question_form: [
      {
        type: "ProminentParagraph",
        value:
          "Pour traiter votre demande dans les plus brefs délais, nous avons besoin d'informations complémentaires de votre part."
      },
      {
        type: "Radio",
        id: "s5q1",
        options: [
          {
            label: "Locataire",
            value: "tenant",
            size: "half",
            name: "s5q1",
            id: "s5q11"
          },
          {
            label: "Propriétaire",
            value: "owner",
            size: "half",
            name: "s5q1",
            id: "s5q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s5q2",
        formatting: "Money",
        label: "Frais mensuels liés au logement en CHF",
        value: "",
        maxLength: 6,
        isNumberField: true
      }
    ]
  },
  {
    question_id: "6",
    question: "Quelle est votre situation en matière de revenu?",
    question_step: [6, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s6q1",
        label: "Revenu annuel brut en CHF",
        value: "",
        formatting: "Money",
        required: true,
        isNumberField: true
      },
      {
        type: "Label",
        value: "Quelle est votre principale source de revenu?"
      },
      {
        type: "Radio",
        id: "s6q2",
        options: [
          {
            label: "Emploi salarié",
            value: "employed",
            size: "half",
            name: "s6q2",
            id: "s6q21"
          },
          {
            label: "Activité indépendante",
            value: "self_employed",
            size: "half",
            name: "s6q2",
            id: "s6q22"
          },
          {
            label: "Rente",
            value: "retired",
            size: "half",
            name: "s6q2",
            id: "s6q23"
          },
          {
            label: "Études",
            value: "student",
            size: "half",
            name: "s6q2",
            id: "s6q24"
          },
          {
            label: "Apprentissage",
            value: "apprentice",
            size: "half",
            name: "s6q2",
            id: "s6q25"
          },
          {
            label: "Aucune activité lucrative",
            value: "unemployed",
            size: "half",
            name: "s6q2",
            id: "s6q26"
          },
          {
            label: "Autre",
            value: "other",
            size: "half",
            name: "s6q2",
            id: "s6q27"
          }
        ]
      },
      {
        type: "Paragraph",
        value: "Coordonnées bancaires"
      },
      {
        type: "Checkbox",
        id: "s6q3",
        label: "Je dispose d'un compte bancaire en Suisse ou au Liechtenstein.",
        size: "full",
        name: "s6q3",
        value: false
      },
      {
        type: "TextInput",
        id: "s6q4",
        label: "IBAN (facultatif)",
        errorMessage: "IBAN invalide pour la Suisse",
        validation: "IBAN",
        value: "",
        required: false,
        optional: true
      }
    ]
  },
  {
    question_id: "7",
    question: "Quelle est votre profession?",
    question_step: [7, 2],
    question_form: [
      {
        type: "TextInput",
        id: "s7q1",
        label: "Profession",
        maxLength: 30,
        value: ""
      },
      {
        type: "TextInput",
        id: "s7q2",
        label: "Employeur",
        value: "",
        maxLength: 30
      },
      {
        type: "Row",
        id: "s7r1",
        items: [
          {
            inputType: "number",
            type: "TextInput",
            validation: "ZipCode",
            id: "s7q3",
            label: "Code postal",
            formatting: "Number",
            errorMessage: "Veuillez entrer un code postal valide.",
            value: "",
            size: "third"
          },
          {
            type: "TextInput",
            id: "s7q4",
            label: "Localité",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Veuillez entrer une localité correcte."
          }
        ]
      }
    ]
  },
  {
    question_id: "8",
    question: "Qui est l'ayant droit économique?",
    question_step: [8, 3],
    question_form: [
      {
        type: "Paragraph",
        value:
          "En tant que titulaire de la carte de crédit/qu'auteur de la demande de carte, je déclare que les fonds versés à  l'émettrice de cartes, servant au règlement des factures de la carte de crédit et/ou dépassant leur montant,"
      },
      {
        type: "Radio",
        id: "s8q1",
        label: "Ayant droit économique:",
        options: [
          {
            label: "... m'appartiennent exclusivement.",
            value: "ausschliesslich mir gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q11",
            code: "s8q11"
          },
          {
            label: "... appartiennent à  la/aux personne-s suivante-s:",
            value: "der/den nachfolgend aufgeführten  Person/en gehören.",
            size: "full",
            name: "s8q1",
            id: "s8q12",
            code: "s8q12"
          }
        ]
      },
      {
        type: "TextInput",
        id: "s8q2",
        label: "Prénom:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3",
        label: "Nom:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Sélectionner un pays...",
        id: "s8q4",
        options: countriesFR
      },
      {
        type: "Row",
        id: "s8r1",
        items: [
          {
            type: "TextInput",
            id: "s8q5",
            label: "Rue",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6",
            label: "No.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2",
        items: [
          {
            type: "TextInput",
            id: "s8q7",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Veuillez entrer un code postal valide."
          },
          {
            type: "TextInput",
            id: "s8q8",
            label: "Localité",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Veuillez entrer une localité correcte."
          }
        ]
      },
      {
        type: "Select",
        label: "Sélectionner une nationalité...",
        id: "s8q9",
        options: countriesFullFR
      },
      {
        type: "Calendar",
        id: "s8q10",
        label: "Date de naissance",
        labels: "JJ/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Vous devez être âgé d'au moins 18 ans."
      },
      {
        type: "Checkbox",
        id: "s8q1b",
        label: "Ajouter une autre personne",
        size: "full",
        name: "s8q1b",
        value: false,
        required: false
      },
      {
        type: "TextInput",
        id: "s8q2b",
        label: "Prénom:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3b",
        label: "Nom:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Sélectionner un pays...",
        id: "s8q4b",
        options: countriesFR
      },
      {
        type: "Row",
        id: "s8r1b",
        items: [
          {
            type: "TextInput",
            id: "s8q5b",
            label: "Rue",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6b",
            label: "No.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2b",
        items: [
          {
            type: "TextInput",
            id: "s8q7b",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Veuillez entrer un code postal valide."
          },
          {
            type: "TextInput",
            id: "s8q8b",
            label: "Localité",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Veuillez entrer une localité correcte."
          }
        ]
      },
      {
        type: "Select",
        label: "Sélectionner une nationalité...",
        id: "s8q9b",
        options: countriesFullFR
      },
      {
        type: "Calendar",
        id: "s8q10b",
        label: "Date de naissance",
        labels: "JJ/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Vous devez être âgé d'au moins 18 ans."
      },
      // {
      //   type: "Checkbox",
      //   id: "s8q1c",
      //   label: "Ajouter une autre personne",
      //   size: "full",
      //   name: "s8q1c",
      //   value: false,
      //   required: false
      // },
      {
        type: "TextInput",
        id: "s8q2c",
        label: "Prénom:",
        maxLength: 30,
        formatting: "name",
        value: ""
      },
      {
        type: "TextInput",
        id: "s8q3c",
        label: "Nom:",
        formatting: "name",
        maxLength: 30,
        value: ""
      },
      {
        type: "Select",
        label: "Sélectionner un pays...",
        id: "s8q4c",
        options: countriesFR
      },
      {
        type: "Row",
        id: "s8r1c",
        items: [
          {
            type: "TextInput",
            id: "s8q5c",
            label: "Rue",
            value: "",
            size: "twoThirds",
            maxLength: 25,
            minLength: 1
          },
          {
            type: "TextInput",
            id: "s8q6c",
            label: "No.",
            value: "",
            size: "third",
            maxLength: 4,
            required: true
          }
        ]
      },
      {
        type: "Row",
        id: "s8r2c",
        items: [
          {
            type: "TextInput",
            id: "s8q7c",
            label: "NPA",
            maxLength: 6,
            formatting: "Number",
            validation: "ZipCode",
            value: "",
            size: "third",
            errorMessage: "Veuillez entrer un code postal valide."
          },
          {
            type: "TextInput",
            id: "s8q8c",
            label: "Localité",
            validation: "city",
            value: "",
            size: "twoThirds",
            errorMessage: "Veuillez entrer une localité correcte."
          }
        ]
      },
      {
        type: "Select",
        label: "Sélectionner une nationalité...",
        id: "s8q9c",
        options: countriesFullFR
      },
      {
        type: "Calendar",
        id: "s8q10c",
        label: "Date de naissance",
        labels: "JJ/MM/AAAA",
        validation: "Birthdate",
        underAgeMessage: "Vous devez être âgé d'au moins 18 ans."
      }
    ]
  },
  {
    question_id: "9",
    question: "Souhaitez-vous garantir vos obligations de paiement?",
    question_step: [9, 3],
    question_form: [
      {
        type: "InfoParagraphWithList",
        value:
          "Vous pouvez le faire pour seulement 0,5% du montant de votre facture. Nous prenons en charge le solde impayé",
        list: [
          "en cas de chômage involontaire,",
          "en cas d'incapacité de travail causée par un accident ou une maladie",
          "en cas de décès dû à un accident ou à une maladie",
          "Maximum 10'000 CHF par demande"
        ],
        info: "1",
        title1: "BALANCE PROTECT",
        title2: "Cela peut arriver à  n'importe qui",
        title3: "Prise en charge de l'intégralité du solde à  payer*:",
        title4: "FAQ relative à  Balance PROTECT",
        listItem1:
          "Gardez en permanence le contrôle de vos finances grâce à  l'assurance solde Balance PROTECT:",
        listItem2:
          "Les difficultés financières ont des origines multiples et souvent inattendues.",
        listItem3: "en cas de chômage involontaire",
        listItem4:
          "en cas d'incapacité de travail causée par un accident ou une maladie",
        listItem5:
          "en cas de décès consécutif à  un accident ou à  une maladie",
        listItem6: "Nous nous tenons à  votre disposition pour toute question.",
        listItem7: "Téléphone: +41 44 805 58 58",
        listItem8: "E-mail: manor@viseca.ch",
        listItem9: "Que comprend la couverture d'assurance?",
        listItem10:
          "Elle couvre l'intégralité du solde impayé du compte-carte. En cas de sinistre, un versement unique est effectué sur votre compte-carte («Balance Cancellation»).",
        listItem11: "Qui est assuré?",
        listItem12: "Le titulaire de la carte principale. ",
        listItem13:
          "Quelles sont les conditions requises pour bénéficier de la protection d'assurance?",
        listItem14:
          "Les conditions d'adhésion ainsi que les Conditions Générales d'Assurance doivent être satisfaites.",
        listItem15: "A combien s'élèvent les sommes d'assurance?",
        listItem16:
          "La couverture d'assurance se limite à  CHF 10 000. par cas et à  CHF 30 000. au total par contrat de carte.",
        listItem17: "Comment procéder en cas de sinistre?",
        listItem18:
          "Vous communiquez immédiatement le sinistre au Service Provider d'Helvetia:",
        para1:
          "Tout le monde peut devoir un jour faire face à  un chômage involontaire dà» à  des difficultés économiques ou à  une longue incapacité de travail causée par un accident ou une maladie. Balance PROTECT vous aide en prenant en charge l'intégralité du solde à  payer, contre une prime de 0,50% seulement du solde dà».",
        para2:
          "Exemple: si la facture de votre Manor Mastercard s'élève à  CHF 150, vous payez une prime de 75 centimes seulement.",
        para3: "Financial & Employee Benefits Services (febs) AG",
        para4: "Case postale 1763, 8401 Winterthour",
        para5: "Téléphone: +41 (0)52 266 02 92, fax: +41 (0)52 266 02 01",
        para6: "E-mail: viseca@febs.ch",
        para7:
          "* L'étendue de la couverture est définie dans les Conditions Générales d'Assurance (CGA) de l'assurance solde Balance PROTECT."
      },
      {
        type: "Table",
        value: "Exemple de calcul de la prime",
        list: [
          ["Report du solde (dernière facture)", "CHF 50.-"],
          ["Votre nouvel achat", "CHF 100.-"],
          ["Solde impayé", "CHF 150.-"],
          ["Prime: 0,5% deCHF 150.-", "= 75 centimes"]
        ],
        info: "2"
      },
      {
        type: "Special"
      },
      {
        type: "Radio",
        id: "s9q1",
        label: "Garantie des obligations de paiement",
        options: [
          {
            label: "Oui, je souhaite garantir mes obligations de paiement.",
            value: "ja",
            size: "full",
            name: "s9q1",
            id: "s9q11"
          },
          {
            label: "Non, je ne le souhaite pas.",
            value: "nein",
            size: "full",
            name: "s9q1",
            id: "s9q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "10",
    question:
      "Voulez-vous être assuré(e) en cas de perte ou de défaut de votre carte?",
    question_step: [10, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Grâce à  l'assurance Card PROTECT proposée par l'Européenne Assurances Voyages ERV, vous ne payez pas de frais de remplacement en cas de perte ou de défaut de votre carte.",
        info: "3",
        title1: "CARD PROTECT",
        title2: "FAQ relative à  Card PROTECT",
        listItem1: "Pas de frais liés au remplacement de la carte",
        listItem2: "Pas de frais liés au remplacement du NIP",
        listItem3: "Prix: seulement CHF 6.90 par an",
        listItem4: "Qu'est-ce que l'assurance Card PROTECT?",
        listItem5:
          "Card PROTECT couvre les frais de remplacement de la carte en cas de vol, de perte ou de défaut de votre Manor World Mastercard. Les frais de remplacement du NIP sont eux aussi couverts.",
        listItem6: "Qui bénéficie de la couverture d'assurance?",
        listItem7:
          "L'assurance englobe aussi bien la carte principale que les cartes supplémentaires Manor World Mastercard.",
        listItem8: "A combien se monte la prime?",
        listItem9:
          "La prime s'élève à  CHF 6.90. Elle est débitée une fois par an sur votre carte de crédit.",
        listItem10: "Quels sont les coà»ts assurés?",
        listItem11:
          "L'assurance couvre l'ensemble des frais de remplacement de la Manor World Mastercard et/ou du NIP en cas de perte, de vol ou de défaut.",
        listItem12: "Comment procéder en cas de sinistre?",
        listItem13:
          "En cas de perte ou de vol, veuillez contacter immédiatement la centrale de blocage (24 h/24) de Viseca au +41 (0)58 958 83 83.",
        listItem14:
          "Si vous ne vous souvenez plus de votre NIP ou si votre carte est défectueuse, veuillez appeler le service clientèle au +41 (0) 44 805 58 58.",
        listItem15: "Quand l'assurance prend-elle fin?",
        listItem16:
          "L'assurance est conclue pour une durée indéterminée et prolongée tacitement d'un an si elle n'est pas résiliée par écrit auprès de Viseca au plus tard un mois avant la fin de l'année d'assurance.",
        para1: "Une protection fiable en cas de perte de la carte ou du NIP",
        para2:
          "Grâce à  l'assurance Card PROTECT de Viseca, vous ne payez pas de frais de remplacement en cas de perte ou de défaut de votre carte.",
        para3: "Vos avantages:"
      },
      {
        type: "ParagraphWithList",
        value: "Vos avantages:",
        list: [
          "Pas de frais supplémentaires liés au remplacement de la carte",
          "Pas de frais supplémentaires liés au remplacement du NIP",
          "Couverture de la carte principale et des cartes supplémentaires"
        ]
      },
      {
        type: "Paragraph",
        value: "Prix: seulement CHF 6.90 par an"
      },
      {
        type: "Radio",
        id: "s10q1",
        label: "Protection en cas de perte de la carte ou du NIP",
        options: [
          {
            label:
              "Oui, je souhaite être couvert(e) contre d'éventuels frais en cas de perte de la carte ou du NIP.",
            value: "ja",
            size: "full",
            name: "s10q1",
            id: "s10q11"
          },
          {
            label: "Non, je ne souhaite pas être couvert(e).",
            value: "nein",
            size: "full",
            name: "s10q1",
            id: "s10q12"
          }
        ]
      }
    ]
  },
  {
    question_id: "11",
    question: "Copie de votre pièce d'identité",
    question_step: [11, 3],
    question_form: [
      {
        type: "InfoParagraph",
        value:
          "Pour établir votre contrat, nous avons besoin d'une copie de votre pièce d'identité. Veuillez charger à  présent une photo de votre pièce d'identité.",
        info: "4"
      },
      {
        type: "ImageUpload",
        id: "s11q1",
        name: "s11q1",
        labelSwiss: "Recto de la pièce d'identité",
        labelOther: "Recto de la pièce d'identité d'étranger",
        size: "full"
      },
      {
        type: "ImageUpload",
        id: "s11q2",
        name: "s11q2",
        labelSwiss: "Verso de la pièce d'identité",
        labelOther: "Verso de la pièce d'identité d'étranger",
        size: "full"
      }
    ]
  },
  {
    question_id: "12",
    question: "Résumé",
    question_step: [12, 4],
    question_form: [
      {
        type: "ProminentParagraph",
        value: "Veuillez vérifier minutieusement les informations saisies."
      },
      {
        type: "Summary",
        value: "Vos données personnelles",
        steps: [1, 3],
        desktop_steps: [1],
        heading: 1,
        list: [
          ["Civilité:", "s1q1"],
          ["Prénom:", "first_name"],
          ["Nom:", "last_name"],
          ["Date de naissance:", "s3q3"],
          ["Etat civil:", "s3q2"],
          ["Nationalité:", "s3q1"],
          ["Permis de séjour:", "s3q4"],
          ["Titulaire d'un permis de séjour en Suisse depuis le:", "s3q5"]
        ]
      },
      {
        type: "Summary",
        value: "Situation en matière de logement",
        steps: [2, 5],
        desktop_steps: [1, 2],
        heading: 2,
        list: [
          ["Pays:", "s2q1"],
          ["Rue:", "s2q2"],
          ["No:", "s2q3"],
          ["NPA:", "s2q4"],
          ["localité:", "s2q5"],
          ["Type de logement:", "s5q1"],
          ["Frais de logement:", "s5q2"]
        ]
      },
      {
        type: "Summary",
        value: "Vos coordonnées",
        steps: [4],
        desktop_steps: [1],
        heading: 4,
        list: [
          ["Adresse e-mail:", "s4q1"],
          ["Téléphone portable:", "s4q2"],
          ["Téléphone privé:", "s4q3"],
          ["Langue de correspondance:", "s4q4"]
        ]
      },
      {
        type: "Summary",
        value: "Situation en matière de revenu",
        steps: [6, 7],
        desktop_steps: [2],
        heading: 6,
        list: [
          ["Revenu annuel brut::", "s6q1"],
          ["Principale source de revenu:", "s6q2"],
          ["Profession:", "s7q1"],
          ["Employeur:", "s7q2"],
          ["NPA:", "s7q3"],
          ["localité:", "s7q4"],
          ["Compte bancaire en Suisse ou au Liechtenstein:", "s6q3"],
          ["IBAN:", "s6q4"]
        ]
      },
      {
        type: "Summary",
        value: "Identification de l'ayant droit économique",
        steps: [8],
        desktop_steps: [3],
        heading: 8,
        list: [
          ["Ayant droit économique:", "s8q1"],
          ["Personne 1", "person-title-1", "RowTitle"],
          ["Prénom:", "s8q2"],
          ["Nom:", "s8q3"],
          ["Date de naissance:", "s8q10"],
          ["Nationalité:", "s8q9"],
          ["Pays:", "s8q4"],
          ["Rue:", "s8q5"],
          ["No:", "s8q6"],
          ["NPA:", "s8q7"],
          ["localité:", "s8q8"],
          ["Personne 2", "person-title-2", "RowTitle"],
          ["Prénom:", "s8q2b"],
          ["Nom:", "s8q3b"],
          ["Date de naissance:", "s8q10b"],
          ["Nationalité:", "s8q9b"],
          ["Pays:", "s8q4b"],
          ["Rue:", "s8q5b"],
          ["No:", "s8q6b"],
          ["NPA:", "s8q7b"],
          ["localité:", "s8q8b"],
          ["Prénom:", "s8q2c"],
          ["Nom:", "s8q3c"],
          ["Date de naissance:", "s8q10c"],
          ["Nationalité:", "s8q9c"],
          ["Pays:", "s8q4c"],
          ["Rue:", "s8q5c"],
          ["No:", "s8q6c"],
          ["NPA:", "s8q7c"],
          ["localité:", "s8q8c"]
        ]
      },
      {
        type: "Summary",
        value: "Prestations supplémentaires",
        steps: [9, 10],
        desktop_steps: [3],
        heading: 9,
        list: [
          ["Garantie des obligations de paiement", "s9q1"],
          ["Protection en cas de perte de la carte ou du NIP", "s10q1"]
        ]
      },
      {
        type: "Summary",
        value: "Copie d'une pièce d'identité",
        steps: [11],
        desktop_steps: [3],
        heading: 11,
        list: [
          ["Recto", "s11q1"],
          ["Verso", "s11q2"]
        ]
      }
    ]
  },
  {
    question_id: "14",
    question: "Déclaration de consentement",
    question_step: [14, 4],
    question_form: [
      {
        type: "CollapsedParagraph",
        value:
          "Par sa signature, le/la soussigné-e confirme l’exactitude des informations ci-dessus et autorise Vise-ca Card Services SA («Viseca» ou «nous») à:\n • vérifier et traiter les indications fournies ci-dessus et obtenir les renseignements nécessaires auprès de tiers, p. ex. auprès de la Centrale d’information de crédit («ZEK»), des autorités (p. ex. office des poursuites, administration fiscale, contrôle des habitants), des organismes four-nissant des renseignements économiques, des employeurs et d’autres organismes d’information et de renseignement appropriés ou prévus par la loi (p. ex. centre de rensei-gnements sur le crédit à la consommation, CRCC), lors de l’examen de la demande de carte et de l’émission de la carte; et\n • signaler à la ZEK des faits tels que le blocage de la carte, des arriérés de paiement ou une utilisation abusive de la carte et, dans les cas prévus par la loi, aux services compétents.",
        more: "plus"
      },
      {
        type: "CollapsedParagraph",
        value:
          "Dans le cadre de ces autorisations, le/la soussigné-e nous libère de l’obligation de préserver le secret bancaire, le secret des affaires ou le secret de fonction ainsi que du respect de la protection des don-nées. Nous pouvons refuser cette demande de carte ou certaines demandes sans donner de motifs. L’auteur de la demande consent à ce que nous pouvons le contacter par e-mail ou par téléphone (ou via SMS/app). A ce sujet, nous tenons à préciser qu’en raison de la configuration ouverte d’Internet, la communication par e-mail, notamment, n’est ni sûre ni confidentielle. Il est donc possible que, mal-gré toutes les mesures de sécurité prises par Viseca, des tiers se ménagent un accès illicite à la communication entre celle-ci et l’auteur de la demande.",
        more: "plus"
      },
      {
        type: "Checkbox",
        required: true,
        id: "s14q1",
        label: "J'approuve la déclaration.",
        size: "full",
        name: "s14q1",
        value: false
      },
      {
        type: "Paragraph",
        value:
          "Viseca Card Services SA, Hagenholzstrasse 56, 8050 Zürich, Telephone +41 44 805 58 58"
      }
    ]
  }
];

export default questionsFR;
