import React from "react";
import s from "./StartList.module.scss";

const StartList = ({ list = [] }) => (
  <ul className={s.Container}>
    {list.map((item, i) => (
      <li key={i}>{item}</li>
    ))}
  </ul>
);

export default StartList;
