import React, { useState } from "react";
import List from "../List";
import Paragraph from "../Paragraph";
import { Info } from "../Icon";
import s from "./InfoParagraphWithList.module.scss";
import Overlay from "../Overlay/Overlay";
import { withConsumer } from "../../appContext";
import { isDesktop } from "../../helpers/render";

const InfoParagraphWithList = (component, windowWidth) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setDisplayOverlay(true);
  };

  const { value } = component;
  return (
    <div className={`${s.Container}`}>
      <div className={s.InfoContainer}>
        <Paragraph className={s.Container}>{value}</Paragraph>
        
        <span onClick={handleClick} className={s.Info}>
          <Info />
        </span>
      </div>

      <List {...component} />

      <Overlay {...component} text={true} show={displayOverlay} setDisplayOverlay={setDisplayOverlay} />

    </div>
  );
};

export default withConsumer(InfoParagraphWithList);
