import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { withConsumer } from "../../appContext";
import s from "./Home.module.scss";

import ProminentParagraph from "../../components/ProminentParagraph";
import Paragraph from "../../components/Paragraph";
import Button from "../../components/Button";
import Link from "../../components/Link";
import Heading from "../../components/Heading";
import Subheading from "../../components/Subheading";
import StartList from "../../components/StartList";
import Image from "../../components/Image";
import { isDesktop } from "../../helpers/render";
import card from "../../media/images/card.jpg";
import { useTranslation } from "react-i18next";
import SwipeHandler from "../../helpers/swipe.handler";
import { links } from "../../helpers/constants";

const Home = (props) => {
  const { t } = useTranslation();
  const [expanded, setIsOpen] = useState(false);
  const [isCondition, setIsCondition] = useState(false);
  const [allowedToHandleScroll, setAllowedToHandleScroll] = useState(true);

  useEffect(() => {
    // Check for /#show-condtions or /#show-info
    if (!window.location.href.includes("#")) return;
    const param = window.location.href.split("#")[1];
    if (!param) return;
    if (param === "show-conditions")
      setTimeout(() => {
        setExpanded();
      }, 750);
    else if (param === "show-info")
      setTimeout(() => {
        setExtention();
      }, 750);
  }, []);

  useEffect(() => {
    function listener(e) {
      const isOpen = e.state?.isOpen || false;
      setIsOpen(isOpen);
    }

    listener({ state: window.history.state });
    window.addEventListener("popstate", listener);
    return () => window.removeEventListener("popstate", listener);
  });
  useEffect(() => {
    function handleClose() {
      // if (!expanded) return;
      // const { setFirstStepNumber } = props;
      // setFirstStepNumber(1);
      // setIsOpen(false);
      // setIsCondition(false);
      // window.history.pushState(
      //   { isOpen: false },
      //   document.title,
      //   document.location.href
      // );
    }
    function handleOpen() {
      if (expanded) return;
      setExtention();
    }
    function listener(e) {
      if (!isDesktop(window.innerWidth) || !allowedToHandleScroll) return;

      if (
        window.innerHeight + window.scrollY >=
          document.getElementById("root").offsetHeight &&
        e.deltaY > 1
      ) {
        handleOpen(); // User scrolled to the bottom
      } else if (window.scrollY === 0 && e.deltaY < -1) {
        handleClose(); // User scrolled to the top
      }
      // Only handle scrolls every 0.5s
      setAllowedToHandleScroll(false);
      setTimeout(() => {
        setAllowedToHandleScroll(true);
      }, 500);
    }
    window.addEventListener("wheel", listener);
    const swipeListener = new SwipeHandler();
    swipeListener.register(handleClose, handleOpen);
    return () => {
      window.removeEventListener("wheel", listener);
      swipeListener.unregister();
    };
  });
  const setExpanded = () => {
    const { setFirstStepNumber } = props;
    setFirstStepNumber(1);
    setIsOpen(true);
    setIsCondition(true);
    window.history.pushState(
      { isOpen: true },
      document.title,
      document.location.href
    );
  };
  const setExtention = () => {
    setIsOpen(true);
    setIsCondition(false);
    window.history.pushState(
      { isOpen: true },
      document.title,
      document.location.href
    );
  };
  const setCollapsed = () => {
    const { setFirstStepNumber } = props;
    setFirstStepNumber(0);
    setIsOpen(false);
  };
  const handleStart = () => {
    props.updateStep(1);
    props.history.push(`/${props.language?.toLowerCase()}/manor`);
  };

  const list = [
    t("home.swiss-licht"),
    t("home.adult"),
    t("home.bank-postal"),
    t("home.unique")
  ];
  const extList = [
    t("home.no-annual-fee"),
    t("home.free-travel"),
    t("home.promo"),
    t("home.worldwide"),
    t("home.score-double"),
    t("home.many-more")
  ];
  return (
    <section
      className={`${s.Container} ${expanded ? s.Expaned : ""} ${
        props.currentStep ? s.Scroll : ""
      }`}
    >
      <div className={s.Heading}>
        <Image src={card} />
        <Heading>
          {t("home.with")}
          <strong>{t("home.manor")}</strong> {t("home.right-choice")}
        </Heading>
      </div>
      <div className={`${s.Main}`}>
        <div
          className={`${s.Intro} ${props.language === "IT" ? s.Italian : ""}`}
        >
          <ProminentParagraph
            desktopColor={false}
            smallerP={
              ["FR", "IT"].includes(props.language) && window.innerHeight < 650
            }
          >
            {t("home.apply-for-credit-card")}
            {!isDesktop(props.windowWidth) && (
              <>
                <br />
                <br />
              </>
            )}{" "}
            {t("home.hold")}
            <strong>{t("home.your-id")}</strong> {t("home.ready")}
          </ProminentParagraph>
          <Paragraph />
          <Button big full={props.language === "IT"} onClick={setExpanded}>
            {t("home.lets-go")}
          </Button>
          <div className={s.Ext} onClick={setExtention}>
            {t("home.info")}
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z'
                fill='white'
              />
            </svg>
          </div>
        </div>
        {isCondition ? (
          <div className={s.ConfWrapper} key={1}>
            <div className={s.Confirmation}>
              <Subheading centered black marginBottom>
                {t("home.conditions")}
              </Subheading>
              <StartList list={list} />
              <Button big onClick={handleStart} inverted>
                {t("home.good-to-go")}
              </Button>
              <Link noUnderline onClick={() => window.history.back()} disabled>
                {t("home.conditions-not-met")}
              </Link>
            </div>
          </div>
        ) : (
          <div className={s.ConfWrapper} key={2}>
            <div className={s.Confirmation} style={{ paddingBottom: 30 }}>
              <Subheading centered black>
                {t("home.manor")}
              </Subheading>
              <StartList list={extList} />
              <Button big onClick={setIsCondition} inverted>
                {t("home.lets-go")}
              </Button>
            </div>
            <div className={s.Link}>
              <p>
                <a target={"blank"} href={links[0].link[props.language]}>
                  {t("home.links.info")}
                </a>
              </p>
              <p>
                {links.slice(1).map((link, index) => (
                  <>
                    <a
                      href={link.link[props.language]}
                      key={index}
                      target='_blank'
                    >
                      {link.label[props.language]}
                    </a>
                    {index < links.slice(1).length - 1 && (
                      <span className={s.LinkSeparator}>/</span>
                    )}
                  </>
                ))}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withRouter(withConsumer(Home));
