import React from "react";
import List from "../List";
import Paragraph from "../Paragraph";
import s from "./ParagraphWithList.module.scss";

const ParagraphWithList = (component) => {
  const { value } = component;
  return (
    <div className={`${s.Container}`}>
      <span className={s.ParaContainer}>
        <Paragraph className={s.Container}>{value}</Paragraph>
      </span>
      <List {...component} />
    </div>
  );
};

export default ParagraphWithList;
