import React, { createContext } from "react";

const appContext = createContext({
  currentStep: 0,
  updateStep: (step) => {},
  resetState: () => {},
  updateWindowWidth: () => {},
  windowWidth: 0,
  language: "DE",
  setFormInputValue: () => {},
  answers: {},
  stepQuestions: [],
  getQuestionsForSection: () => [],
  nextStepDisabled: true,
  submitError: "",
  submitAttempts: 0
});

export const AppContextProvider = appContext.Provider;
export const AppContextConsumer = appContext.Consumer;

export const withConsumer = (Component) => {
  return (props) => (
    <AppContextConsumer>
      {(context) => <Component {...context} {...props} />}
    </AppContextConsumer>
  );
};
