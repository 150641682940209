import React from "react";
import s from "./ListItem.module.scss";

const ListItem = ({ children, id }) => (
  <li className={s.Container} key={id}>
    {children}
  </li>
);

export default ListItem;
