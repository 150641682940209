import IBANValidator from "iban";
import validEmailsJSON from "./validDomains.json";
import { differenceInYears, parse } from "date-fns";

export const getAge = (birthdate) => {
  birthdate = parse(birthdate, "dd/MM/yyyy", new Date());
  return differenceInYears(new Date(), birthdate);
};
export const validateZipCode = (
  selectCountryField,
  zipCodeValue,
  questions
) => {
  if (!selectCountryField?.value) return true;
  const stepQuestion = questions.find((question) =>
    question.question_form.find((field) => field.id === selectCountryField.id)
  );
  const countrySelectFieldOptions = stepQuestion.question_form.find(
    (field) => field.id === selectCountryField.id
  )?.options;
  const selectedCountry = countrySelectFieldOptions.find(
    (option) => option.value === selectCountryField.value
  );
  if (!selectedCountry) return false;
  const { maxZipCodeLength, minZipCodeLength } = selectedCountry;
  if (!maxZipCodeLength || !minZipCodeLength) return true;

  return (
    zipCodeValue.length >= minZipCodeLength &&
    zipCodeValue.length <= maxZipCodeLength
  );
};
export const validateBirthdate = ([day, month, year]) => {
  const validDay = Number(day) >= 1 && Number(day) <= 31;
  const validMonth = Number(month) >= 1 && Number(month) <= 12;
  const validYear = Number(year) >= 1902;
  if (!validYear || !validDay || !validMonth) return false;

  const age = getAge(`${day}/${month}/${year}`);
  return age >= 18;
};

export const validateCalendar = ([day, month, year]) => {
  const validDay = Number(day) >= 1 && Number(day) <= 31;
  const validMonth = Number(month) >= 1 && Number(month) <= 12;
  const validYear =
    Number(year) <= new Date().getFullYear() &&
    Number(year) >= new Date().getFullYear() - 100;
  const pastDate =
    new Date(`${year}/${month}/${day}`).getTime() < new Date().getTime();
  return validDay && validMonth && validYear && pastDate;
};

// Only show the insurance when the country is CH or LI and max age is 64
export const insuranceRequired = (answers) => {
  const today = new Date();
  const birthday = new Date(
    answers["s3q3"].value?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
  );
  let age = today.getFullYear() - birthday.getFullYear();
  if (
    today.getMonth() < birthday.getMonth() ||
    (today.getMonth() === birthday.getMonth() &&
      today.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return answers["s2q1"]?.abbreviation?.toUpperCase() === "CH" && age < 64;
};
export const validateField = (input, questions, answers, dateLength = 10) => {
  const fieldAnswer = answers[input.id];

  if (input.id === "s9q1" && !insuranceRequired(answers)) return true;
  if (
    input.id?.includes("s7q") &&
    !["employed", "apprentice", "Angestellt", "Lernende/r"].includes(
      answers.s6q2?.value
    )
  )
    return true;
  if (input.hasOwnProperty("required") && !input.required) {
    if (
      !fieldAnswer.value ||
      fieldAnswer.value === "//" ||
      (typeof fieldAnswer.value === "string" && fieldAnswer.value.trim() === "")
    ) {
      return true;
    }
  }

  if (input?.id?.includes("s8q")) {
    return true;
  }

  if (fieldAnswer) {
    // Validate for empty fields
    if (
      ["s3q4", "s3q5"].includes(input.id) &&
      answers["s3q1"].value &&
      ["ch", "li"].includes(answers["s3q1"].value.toLowerCase())
    ) {
      return true;
    }
    if (
      !fieldAnswer.value ||
      fieldAnswer.value === "//" ||
      (typeof fieldAnswer.value === "string" && fieldAnswer.value.trim() === "")
    ) {
      return false;
    }

    // Validate birth date is empty
    if (fieldAnswer.type === "Calendar") {
      if (fieldAnswer.value && fieldAnswer.value.length < dateLength) {
        return true;
      }
    }
    if (fieldAnswer.formatting === "name" && fieldAnswer.value) {
      // letter of all languages, but not numbers accepted
      return /^[^\d~!@#$|%^&*()_+=}{\]\[",:;><,?/.`]*$/.test(
        fieldAnswer.value.trim()
      );
    }

    // Validate birthdate field
    if (
      fieldAnswer?.type === "Calendar" &&
      fieldAnswer?.value?.length >= dateLength
    ) {
      if (fieldAnswer?.validation === "Birthdate") {
        return validateBirthdate(fieldAnswer.value.split("/"));
      }
      return validateCalendar(fieldAnswer.value.split("/"));
    }

    // Validate zip code
    if (fieldAnswer?.validation === "ZipCode" && fieldAnswer.value) {
      let basedOnAnswer;
      if (fieldAnswer.id.includes("s2q")) basedOnAnswer = "s2q1";
      else if (fieldAnswer.id.includes("s8q")) {
        if (fieldAnswer.id.includes("b")) {
          basedOnAnswer = "s8q4b";
        } else {
          basedOnAnswer = "s8q4";
        }
      } else if (fieldAnswer.id.includes("s7q")) {
        return fieldAnswer.value.length === 4;
      }

      let validateZipBasedOn = answers[basedOnAnswer];
      return validateZipCode(validateZipBasedOn, fieldAnswer.value, questions);
    }

    // Validate phone number field
    if (fieldAnswer?.validation === "Cellphone" && fieldAnswer.value) {
      return fieldAnswer.value.length >= 8;
    }

    // Validate IBAN field
    if (fieldAnswer?.validation === "IBAN") {
      if (fieldAnswer.value.length >= 2) {
        if (
          !fieldAnswer.value.startsWith("CH") &&
          !fieldAnswer.value.startsWith("LI")
        )
          return false;
      }
      return IBANValidator.isValid(fieldAnswer.value);
    }

    // Validate E-mail
    if (fieldAnswer?.validation === "Email" && fieldAnswer.value) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailRegex.test(fieldAnswer.value);
    }

    // Make images required
    if (fieldAnswer?.type === "ImageUpload") {
      return !!fieldAnswer?.value;
    }
  }
  return true;
};

export const validateEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailRegex.test(email)) return false;

  const domain = email.split("@")[1];
  return !validEmailsJSON.domains.includes(domain);
};

export const getInvalidCharsRegex = (id) => {
  switch (id) {
    case "first_name":
    case "last_name":
    case "s8q2":
    case "s8q3":
    case "s8q2b":
    case "s8q3b":
    case "s8q2c":
    case "s8q3c":
      return /^[^~!@#$|&%^*<>()_+=}{\]\[,:;,.?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷"\d`]*$/;
    case "s2q2":
    case "s8q5":
    case "s8q5b":
    case "s8q5c":
      return /^[^~!@#$|&%^*<>()_+=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷"`]*$/;
    case "s8q2":
    case "s8q3":
    case "s8q2b":
    case "s8q3b":
    case "s8q2c":
    case "s8q3c":
      return /^[^~!@#$|&%^*<>()_+=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷"\d`]*$/;
    case "s2q5":
    case "s7q4":
    case "s8q8":
    case "s8q8b":
    case "s8q8c":
      return /^[^~!@#$|&%^*<>()_+=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷"`]*$/;
    case "s7q1":
      return /^[^~!@#$|%^*()_+=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷\d`]*$/;
    case "s7q2":
      return /^[^~!@#$|%^*()_=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷`]*$/;
    default:
      return /^[^~!@#$|&%^*<>()_+=}{\]\[,:;,?/£¿€®™×©¥¦❡§𝆩\\¢°¡¬¶÷"`]*$/;
  }
};
