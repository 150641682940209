import countriesFullDE from "./countries/countries-full-de.json";
import countriesFullFR from "./countries/countries-full-fr.json";
import countriesFullIT from "./countries/countries-full-it.json";

const getFormInputs = (questions) => {
  let allQuestions = {};

  questions.map((question) => {
    question.question_form.map((formElement) => {
      let {
        type,
        id,
        label,
        value,
        file,
        validation,
        abbreviation,
        formatting,
        nationality_iso
      } = formElement;
      if (/^((s2q[2-5])|(s7q[4-5])|(s8q[5-8]))$/.test(id)) value = "";
      switch (type) {
        case "Label":
          break;
        case "Row":
          formElement.items.map(
            ({
              id,
              label,
              value,
              file,
              validation,
              abbreviation,
              nationality_iso
            }) =>
              (allQuestions[id] = {
                id,
                label,
                value,
                type,
                file,
                validation,
                abbreviation,
                formatting,
                nationality_iso
              })
          );
          break;
        case "Radio":
        case "RadioWithText":
        case "TextInput":
        case "Select":
        case "Checkbox":
        case "Calendar":
        case "Cellphone":
        case "ImageUpload":
          allQuestions[id] = {
            id,
            label,
            value,
            type,
            file,
            validation,
            abbreviation,
            formatting,
            nationality_iso
          };
          break;
        default:
          break;
      }

      return formElement;
    });

    return question;
  });
  return allQuestions;
};

// const setFormInputValue = (inputId, value, formInputs) =>
//   (formInputs[inputId].value = value);

const getCountryNameByAlpha = (alpha, lang) => {
  switch (lang) {
    case "DE":
      return countriesFullDE.find((country) => country.alpha2 === alpha).value;
    case "FR":
      return countriesFullFR.find((country) => country.alpha2 === alpha).value;
    case "IT":
      return countriesFullIT.find((country) => country.alpha2 === alpha).value;
    default:
      return "-";
  }
};

export { getFormInputs, getCountryNameByAlpha };
