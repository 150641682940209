import React from "react";
import { withConsumer } from "../../appContext";
import { withRouter } from "react-router-dom";
import s from "./Footer.module.scss";
import Logo from "../Logo";
import { useTranslation } from "react-i18next";
import { links } from "../../helpers/constants";

const Footer = (props) => {
  const { t } = useTranslation();
  const navigateHome = () => {
    const { updateStep, resetState, history, language } = props;
    resetState();
    history.push(`/${language?.toLowerCase()}`);
    updateStep(0);
  };

  return (
    <footer className={s.Container} id='footer'>
      <div className={s.Content}>
        <div className={s.Data}>
          <div>
            <Logo onClick={navigateHome} />
          </div>
          <div className={s.Contact}>
            <address>{t("home.contact.contact-us")}:</address>
            <span>
              {t("home.contact.phone")}:{" "}
              <a href='tel:+41448055858'>+41 44 805 58 58</a>
            </span>
            <span>
              {t("home.contact.email")}:{" "}
              <a href='mailto:manor@viseca.ch'>manor@viseca.ch</a>
            </span>
          </div>
        </div>
        <nav className={s.Links}>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.link[props.language]} target='_blank'>
                {link.label[props.language]}
              </a>
            </li>
          ))}
        </nav>
      </div>
    </footer>
  );
};

export default withRouter(withConsumer(Footer));
