import React, { Component } from "react";
import { withConsumer } from "../../appContext";
import s from "./Step.module.scss";
import { renderComponent ,isDesktop} from "../../helpers/render";


const renderSingleQuestion = (
  stepQuestions,
  answers,
  handleChange,
  currentStep,
  manual
) => {
  if (!stepQuestions[0]) return null;
  const { question, question_id, question_form } = stepQuestions[0];
  if (question_id === '0' && !manual) return null;
  const stepsWithoutHeadingMargin = [ 5, 6, 11, 12];
  const component = {
    value: question,
    type: "Heading",
    id: question_id,
    key: question_id,
    marginBottom: !stepsWithoutHeadingMargin.includes(currentStep)
  };

  return (
    <>
      {renderComponent(component)}
      {question_form.map((component, idx) => {
        const componentWithValue = { ...component };
        componentWithValue.key = idx;
        if (answers[component.id]) {
          componentWithValue.value = answers[component.id].value;
        }

        if (componentWithValue.type === "Row") {
          componentWithValue.items.map((component) => {
            component.value = answers[component.id].value;
            component.key = component.id;
            component["handleChange"] = handleChange;
            return <React.Fragment key={idx}>{component}</React.Fragment>;
          });
        }

        componentWithValue["handleChange"] = handleChange;

        return <React.Fragment key={idx}>{renderComponent(componentWithValue)}</React.Fragment>;
      })}
    </>
  );
};

const renderMultiQuestion = (
  stepQuestions,
  answers,
  handleChange,
  currentStep,
  manual
) => {
  return (
    <>
      {stepQuestions.map((question, i) =>
        <React.Fragment key={i}>
          {renderSingleQuestion([question], answers, handleChange, currentStep, manual)}
          </React.Fragment>
      )}
    </>
  );
};

class Step extends Component {

  render() {
    const {
      answers,
      setFormInputValue,
      getQuestionsForSection,
      currentStep,
      manual
    } = this.props;
    const stepQuestions = getQuestionsForSection();
    return (
      <>
        <form onSubmit={(e) => e.preventDefault()}>
          {stepQuestions.length < 2 &&
            renderSingleQuestion(
              stepQuestions,
              answers,
              setFormInputValue,
              currentStep,
              manual,
            )}
          {stepQuestions.length > 1 &&
            renderMultiQuestion(
              stepQuestions,
              answers,
              setFormInputValue,
              currentStep,
              manual
            )}
        </form>
      </>
    );
  }
}

export default withConsumer(Step);
