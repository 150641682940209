import React from "react";
import s from "./Subheading.module.scss";

const Subheading = ({
  children,
  inverted,
  black,
  centered = false,
  id,
  marginBottom = false
}) => {
  return (
    <h3
      id={`heading-${id}`}
      className={`${s.Container} ${inverted ? s.Inverted : ""} ${
        marginBottom ? s.MarginBottom : ""
      } ${centered ? s.Centered : ""} ${black ? s.Black : ""}`}
    >
      {children}
    </h3>
  );
};
export default Subheading;
