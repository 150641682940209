export const APP_DEFAULT_STATE = {
  currentStep: 0
};

export const LANGUAGES = [
  { label: "DE", value: "DE" },
  // { label: "EN", value: "EN" },
  { label: "FR", value: "FR" },
  { label: "IT", value: "IT" }
];

// change this to flush localstorage of users after each deploy
export const LOCAL_STORAGE_APP_STATE_VERSION = "v1.1.3";

export const LOCAL_STORAGE_APP_STATE_VERSION_KEY = "viseca-app-state-key";
export const LOCAL_STORAGE_APP_STATE_KEY = "viseca-app-state";
