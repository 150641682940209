import React from "react";
import s from "./Row.module.scss";

import { renderComponent } from "../../helpers/render";

const Row = (component) => {
  const { items, id } = component;

  return (
    <div className={s.Container} key={id}>
      {items.map((item) => {
        return renderComponent(item);
      })}
    </div>
  );
};
export default Row;
