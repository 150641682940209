import React from "react";
import { Pencil } from "../Icon";
import { withConsumer } from "../../appContext";
import { getCountryNameByAlpha } from "../../helpers/questions";
import s from "./Summary.module.scss";
import { useTranslation } from "react-i18next";
import { insuranceRequired } from "../../helpers/validations";
import { isDesktop } from "../../helpers/render";

const Summary = (props) => {
  const {
    value,
    list,
    answers,
    inputs,
    steps,
    desktop_steps,
    heading,
    currentStep,
    setEditingSteps,
    editingSteps,
    editing,
    windowWidth,
    language
  } = props;
  const { t, i18n } = useTranslation();
  const makeIcon = () => (
    <span onClick={() => handleEdit()}>
      <Pencil className={s.Icon} />
    </span>
  );

  // Sets editing state to true, and the defines the editings steps from the ones provided in the questions, and redirects to the first step or the array
  const handleEdit = () => {
    if (steps && !isDesktop(windowWidth)) {
      setEditingSteps("editing", true);
      setEditingSteps("editingSteps", steps);
      setEditingSteps(
        "currentStep",
        steps[0] === 9 && !insuranceRequired(answers) ? 10 : steps[0]
      );
    } else {
      setEditingSteps("editing", true);
      setEditingSteps("editingSteps", desktop_steps);
      setEditingSteps("currentStep", desktop_steps[0]);

      setTimeout(() => {
        window.scroll({
          behavior: "smooth",
          top:
            findPos(document.getElementById(`heading-${heading}`)) -
            window.innerHeight / 3
        });
      }, 200);
    }
  };

  function findPos(obj) {
    var curtop = 0;
    if (obj?.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
    }
    return [curtop];
  }

  const renderAnswers = (item) => {
    const id = answers[item[1]].id;
    const value = answers[item[1]].value;
    if (id === "s11q1" || id === "s11q2") {
      return <img data-recording-sensitive className="smartlook-hide" src={value ? value : null} alt='preview' width='100' />;
    }
    if (id === "s1q1") {
      return t(`summary.sex.${value}`) || value;
    }
    if (id === "s3q2") {
      return t(`summary.civil-status.${value}`) || value;
    }
    if (id === "s5q1") {
      return t(`summary.house.${value}`) || value;
    }
    if (id === "s6q2") {
      return t(`summary.job-status.${value}`) || value;
    }
    if (answers[item[1]].id === "s6q3") {
      return t(`summary.yes`);
    }
    if (answers[item[1]].type === "Calendar" && value) {
      return value.replace(/\//g, ".");
    }
    if (answers[item[1]].id === "s3q1" || answers[item[1]].id === "s5q1") {
      let countryName = getCountryNameByAlpha(value, language);
      return countryName[0].toUpperCase() + countryName.substring(1);
    }
    if (answers[item[1]].id === "s4q4") {
      return t(`summary.languages.${value}`) || value;
    }
    if (answers[item[1]].id === "s8q1") {
      if (value === "ausschliesslich mir gehören.")
        return t(`summary.belongs-to.me`);
      else return t(`summary.belongs-to.other`);
    }
    if (value === "ja") {
      return t(`summary.yes`);
    }

    if (value === "nein") return t(`summary.no`);

    return answers[item[1]].value;
  };

  return (
    <div className={s.Container}>
      <div className={s.TitleContainer}>
        <h3 className={s.Title}>{value}</h3>
        {makeIcon()}
      </div>
      <hr className={s.Hr} />
      <table className={s.Content}>
        <tbody>
          {list.map((item, i) => {
            if (item[1]) {
              if (
                typeof item[1] === "string" &&
                item[1].includes("person-title")
              ) {
                if (
                  item[1].includes("1") &&
                  answers["s8q1"]?.value === "ausschliesslich mir gehören."
                ) {
                  return null;
                } else if (item[1].includes("2") && !answers["s8q1b"]?.value) {
                  return null;
                }
              } else {
                if (answers[item[1]]?.id.indexOf("s8q") === 0) {
                  if (
                    answers["s8q1"].value === "ausschliesslich mir gehören." &&
                    answers[item[1]]?.id !== "s8q1"
                  ) {
                    return null;
                  } else if (
                    answers[item[1]]?.id !== "s8q1b" &&
                    answers[item[1]]?.id.indexOf("b") !== -1
                  ) {
                    if (!answers["s8q1b"]?.value) {
                      return null;
                    }
                  } else if (
                    answers[item[1]]?.id !== "s8q1c" &&
                    answers[item[1]]?.id.indexOf("c") !== -1
                  ) {
                    if (!answers["s8q1c"]?.value) {
                      return null;
                    }
                  }
                }

                if (!answers[item[1]]?.value) {
                  return null;
                }
                if (
                  answers["s3q1"].value &&
                  answers["s3q1"].value.toLowerCase() === "ch" &&
                  ["s3q4", "s3q5"].includes(answers[item[1]]?.id)
                ) {
                  return null;
                }

                if (
                  answers[item[1]]?.id.indexOf("s7q") === 0 &&
                  !["employed", "apprentice"].includes(answers["s6q2"].value)
                ) {
                  return null;
                }
                if (
                  answers[item[1]]?.id.indexOf("s9q") === 0 &&
                  !insuranceRequired(answers)
                ) {
                  return null;
                }
                if (answers[item[1]]?.id === "s4q3") {
                  if (
                    !answers.s4q3.value ||
                    !answers.s4q3.value.includes("-") ||
                    !answers.s4q3.value.split("-")[1].length
                  )
                    return null;
                }
              }
            }
            return (
              <tr
                className={`${s.Field} ${item.length > 2 ? s[item[2]] : null}`}
                key={i}
              >
                <td className={s.ItemTitle}>{item[0]}</td>

                <td className={s.Answer} data-recording-sensitive>
                  {answers[item[1]] && !item[1].includes("title")
                    ? renderAnswers(item)
                    : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withConsumer(Summary);
