import React from "react";
import s from "./StepProgress.module.scss";

const StepProgress = ({ currentStep, totalSteps, isSticky }) => {
  let steps = [];
  const stepWidth = 100 / totalSteps + "%";

  // let dynamicCurrentStep;
  //
  // if (currentStep > 6) {
  //   if (["employed", "apprentice", "Angestellt", "Lernende/r"].includes(answers.s6q2?.value)) {
  //     dynamicCurrentStep = currentStep;
  //   } else {
  //     dynamicCurrentStep = currentStep - 1;
  //   }
  // } else {
  let dynamicCurrentStep = currentStep;
  // }

  for (let i = 0; i < totalSteps; i++) {
    steps.push(
      <div
        key={i}
        className={`${s.Step} ${dynamicCurrentStep > i ? s.Filled : s.Empty}`}
        style={{ width: stepWidth }}
      />
    );

    if (i < totalSteps - 1) {
      steps.push(
        <div
          key={`${i}-spacer`}
          className={`${s.Spacer} ${currentStep - 1 > i ? s.Filled : s.Empty}`}
        />
      );
    }
  }
  return (
    <div className={`${s.StepProgress} ${isSticky ? s.isSticky : ""}`}>
      {dynamicCurrentStep < 10 ? "0" + dynamicCurrentStep : dynamicCurrentStep}
      <div className={s.Steps}>{steps}</div>
    </div>
  );
};

export default StepProgress;
